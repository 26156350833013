export default{
	state:{
		userInfo:{},
		isUser:false //获取会员信息状态
	},
	getters:{
		
	},
	mutations:{
		//保存会员信息
		saveUserInfo(state,data){
			state.userInfo = data;
			state.isUser = true;
			
			localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
		},
		//初始化会员信息
		initUserInfo(state,data){
			if (localStorage.getItem("userInfo")) {
			   state.userInfo = JSON.parse(localStorage.getItem("userInfo"));
			}
		},
		//退出会员信息
		clearUserInfo(state,data){
			state.isUser = false;
			state.userInfo = {};
			localStorage.removeItem("userInfo");
		}
	},
	actions:{
		
	}
}