<template>
	<div style="height: 100%;display: flex;flex-direction: column;">
		<el-card style="flex: 1; display: flex;flex-direction: column;height: 100%;">
			<div class="status">
				<span class="icon"></span>
				<span style="font-size: 18rem;color: #666666;">订单列表</span>
			</div>
			<!-- 列表 -->
			<div class="formList" style="height: 94%;overflow-y: auto;">
				<!-- 筛选区域 -->
				<div style="margin: 0rem 0;">
					<topNav :navOptions.sync="screenOptions" :navCallBack="navCallBack"></topNav>
				</div>

				<!-- 列表展示 -->
				<template v-if="isEdit">
					<baseList :listData="listData.list" :colList="colList">
						<!-- 优惠券 -->
						<template v-slot:customCom='customScope'>

							<el-popover placement="bottom" width="400" trigger="click">

								<el-table :data="childCouponList">
									<el-table-column property="id" label="ID" align="center"></el-table-column>
									<el-table-column property="store_id" label="商铺ID" align="center"></el-table-column>
									<el-table-column property="name" label="名称" align="center"></el-table-column>
									<el-table-column label="类型" align="center">
										<template slot-scope="scope">
											<el-tag type="danger" size="mini" v-if="scope.row.type==1">立减</el-tag>
											<el-tag type="warning" size="mini" v-else>折扣</el-tag>
										</template>
									</el-table-column>
								</el-table>

								<el-button size="mini" type="warning" circle class="el-icon-s-ticket" slot="reference"
									@click="showCoupon(customScope.row)"></el-button>

							</el-popover>

						</template>

						<!-- 备注 -->
						<template v-slot:customRemark="customScope">
							<el-button type="info" size="mini" class="el-icon-edit-outline"
								@click="addRemark(customScope.row)">备注</el-button>
						</template>

						<!-- 操作 -->
						<template v-slot:actionBtn="customScope">
							<el-button :disabled="customScope.row.status == 1" type="primary" size="mini"
								class="el-icon-s-promotion" @click="deliverGoods(customScope.row)">发货</el-button>
						</template>

					</baseList>
					<pagination :limit="queryData.limit" :page="queryData.page" :count="listData.count"></pagination>
				</template>

			</div>

		</el-card>

		<!-- 备注 -->
		<el-dialog title="备注" :visible.sync="isRemark" width="30%">

			<label>
				<el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="editData.remake">
				</el-input>
			</label>

			<span slot="footer" class="dialog-footer">
				<el-button @click="isRemark = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="confirm" size="mini">确 定</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	import baseList from "@/components/baseList/index.vue"
	import pagination from "@/components/pagination/index.vue"
	import topNav from '@/components/topNav/index.vue'

	export default {
		name: "index_shopOrders",
		menuName: "订单列表",
		data() {
			return {
				apiName: "Shop",
				isEdit: true,
				queryData: {
					page: 1,
					limit: 10,
					mobile: "",
					title: "",
					type: "",
					status: "-1"
				},
				colList: [{
						lable: 'ID',
						key: 'id',
						type: 'text',
						width: 80
					},
					{
						lable: '商户id',
						key: 'store_id',
						type: 'text',
					},
					{
						lable: '用户ID',
						key: 'user_id',
						type: 'text',
						width: 120
					},
					{
						lable: '商品ID',
						key: 'good_id',
						type: 'text',
					},
					{
						lable: '手机号',
						key: 'mobile',
						type: 'text',
						width: 140
					},
					{
						lable: '标题',
						key: 'title',
						type: 'text',
						width: 120
					},
					{
						lable: '图片',
						key: 'pic',
						type: 'image',
						width: 120,
						isShow: false
					},
					{
						lable: '消耗积点数量',
						key: 'sum',
						type: 'text',
						width: 120,
						isShow: false
					},
					{
						lable: '购买数量',
						key: 'buy_count',
						type: 'text',
						width: 120,
						isShow: false
					},
					{
						lable: '商品类型',
						key: 'type',
						type: 'status',
						width: 120,
						options: [{
								title: "优惠券",
								key: '1'
							},
							{
								title: "实物",
								key: '2'
							}
						]
					},
					{
						lable: '优惠券',
						key: 'coupon_ids',
						type: 'customCom',
						width: 120
					},
					{
						lable: '购买时间',
						key: 'create_time',
						type: 'time',
						width: 120
					},
					{
						lable: '订单状态',
						key: 'status',
						type: 'status',
						width: 120,
						options: [{
								title: "待发货",
								key: '0'
							},
							{
								title: "已发货",
								key: '1'
							}
						]
					},
					{
						lable: '备注',
						key: 'remake',
						type: 'customRemark',
						width: 120
					},
					{
						lable: '发货时间',
						key: 'ok_time',
						type: 'time',
						width: 120
					},
					{
						lable: '支付时间',
						key: 'pay_time',
						type: 'time',
						width: 120
					},
					{
						lable: '操作',
						key: 'action',
						type: 'actionBtn',
						fixed: 'right',
						width: 120
					}
				],
				listData: {
					list: [],
					count: 0
				},
				childCouponList: [],
				couponList: [],
				isRemark: false,
				editData: {
					id: "",
					remake: ""
				},
				screenOptions: [{
						key: 'mobile',
						value: '',
						lable: "手机号",
						isLike: true,
						type: 'txt'
					},
					{
						key: 'type',
						value: '',
						lable: "商品类型",
						type: 'status',
						options: [{
								name: "全部",
								key: ''
							},
							{
								name: "优惠券",
								key: '1'
							},
							{
								name: "实物",
								key: '2'
							}
						]
					},{
						key: 'status',
						value: '-1',
						lable: "订单状态",
						type: 'status',
						options: [{
								name: "全部",
								key: '-1'
							},
							{
								name: "待发货",
								key: '0'
							},
							{
								name: "已发货",
								key: '1'
							}
						]
					},
					{
						key: 'title',
						value: '',
						lable: "标题",
						isLike: true,
						type: 'txt'
					}
				]
			}
		},
		components: {
			baseList,
			pagination,
			topNav
		},
		created() {
			//获取优惠券列表
			this.getCouponList();

			this.initData();
		},
		computed: {

		},
		methods: {
			//筛选区域回调
			navCallBack(dataList) {
				console.log("dataList",dataList);
				
				dataList.forEach(item=>{
					this.queryData[item.key] = item.value;
				})
				
				console.log("this.queryData",this.queryData);
				
				this.initData();
			},
			//发货
			deliverGoods(val) {
				this.$confirm('是否已确认订单信息并发货?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {

					this.$api.apiInterface(this.apiName, "okOrder").okOrder({
						id: val.id
					}, res => {
						if (res.code == 0) {
							this.$message({
								message: res.msg,
								type: 'success'
							});
							this.initData();
							return;
						}
						this.$message.error(res.msg);
					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			//获取优惠券列表
			getCouponList() {
				this.$api.getCoupon({
					page: 1,
					limit: 100
				}, (res) => {
					console.log("res", res);
					if (res.code == 0) {
						this.couponList = res.data.list;
					}
				})
			},
			showCoupon(val) {
				this.childCouponList = [];
				var ids = JSON.parse(val.value);

				this.couponList.forEach(item => {
					ids.forEach(id => {

						if (item.id == id) {
							this.childCouponList.push(item);
						}
					})

				})
			},
			//备注
			addRemark(val) {
				console.log("val", val);
				this.editData.id = val.id;
				this.editData.remake = val.value;
				this.isRemark = true;
			},
			confirm() {

				this.$api.apiInterface(this.apiName, "updateRemake").updateRemake(this.editData, res => {
					this.isRemark = false;
					if (res.code == 0) {
						this.$message({
							message: res.msg,
							type: 'success'
						});
						this.initData();
						return;
					}
					this.$message.error(res.msg);
				})

			},
			initData() {
				this.$api.apiInterface(this.apiName, "getGoodsOrder").getGoodsOrder(this.queryData, res => {
					console.log("res", res.data);
					if (res.code == 0) {
						this.listData.list = res.data.list;
						this.listData.count = Number(res.data.count);
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">
	/deep/ .is-leaf {
		background: rgba(141, 212, 97, .35) !important;
	}

	.status {
		margin-bottom: 15rem;

		.icon {
			display: inline-block;
			width: 13rem;
			height: 13rem;
			background: #8DD461;
			border-radius: 50%;
			margin-right: 10rem;
		}
	}
</style>
