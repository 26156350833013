<template>
	<div class="coupon">
		<!-- <div class="topNav">
			<img src="../../static/addIcon.png" alt="">
			<span>添加场次</span>
		</div> -->
		<el-card style="flex: 1;">
			<div class="status">
				<span class="icon"></span>
				<span style="font-size: 18rem;color: #666666;">资金</span>
			</div>
			<baseList></baseList>

			<pagination :limit="5" :page="1" :count="10"></pagination>
		</el-card>
	</div>
</template>

<script>
	import baseList from "@/components/baseList/index.vue"
	import pagination from "@/components/pagination/index.vue"

	export default {
		name:"member_capital",
		data() {
			return {
				//表头数据
				colList: [],
				//操作接口参数
				formData: [],
				//后台接口数据渲染结构
				listData: [{
					id: 1,
					name: "测试",
					use_key: "无门槛",
					start_time: "1669449316",
					end_time: "1669449316"
				}]
			}
		},
		components: {
			baseList,
			pagination
		}
	}
</script>

<style scoped lang="less">
	.coupon{
		flex: 1;
		padding: 0rem 0 0 30rem;
		display: flex;
	}
	
	/deep/ .is-leaf {
		background: rgba(134, 215, 205, .15) !important;
	}
	
	.topNav{
		display: flex;
		font-size: 16rem;
		color: #409EFF;
		align-items: center;
		margin-bottom: 20rem;
		margin-left: 25rem;
		cursor: pointer;
		user-select: none;
		img{
			width: 26rem;
			height: 26rem;
			margin-right: 12rem;
		}
	}
	
	.status {
		margin-bottom: 15rem;
	
		.icon {
			display: inline-block;
			width: 13rem;
			height: 13rem;
			background: #86D7CD;
			border-radius: 50%;
			margin-right: 10rem;
		}
	}
</style>
