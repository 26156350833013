<template>
	<div>
		<div class="topNav">
			<div style="display: flex;align-items: center;" @click="toAuth">
				<img class="topImg" src="../../static/addIcon.png" alt="">
				<span>增加授权</span>
			</div>
		</div>
		<el-card style="flex: 1; display: flex;flex-direction: column;height: 100%;">
			<div class="status">
				<span class="icon"></span>
				<span style="font-size: 18rem;color: #666666;">授权列表</span>
			</div>
			<!-- 列表 -->
			<div class="formList" style="height: 94%;overflow-y: auto;">

				<!-- 列表展示 -->
				<baseList :listData="listData" :colList="colList"></baseList>
				<pagination :limit="queryData.limit" :page="queryData.page" :count="count"></pagination>

			</div>

		</el-card>

		<!-- 授权确认 -->

		<el-dialog title="提示" :visible.sync="isDialog" width="50%">

			<span>是否已经完成授权?</span>

			<span slot="footer" class="dialog-footer">
				<el-button size="mini" @click="isDialog = false">取 消</el-button>
				<el-button size="mini" type="primary" @click="getAuthList()">确 定</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	import baseList from "@/components/baseList/index.vue"
	import pagination from "@/components/pagination/index.vue"

	export default {
		name: 'index_authShopStore',
		menuName: "授权列表",
		data() {
			return {
				apiName: "DianPing",
				//后台接口数据
				listData: [],
				queryData: {
					page: 1,
					limit: 7
				},
				colList: [
					{
						lable: 'ID',
						key: 'id',
						type: 'text',
						width: 80
					},
					{
						lable: '商户id',
						key: 'store_id',
						type: 'text',
						width: 80
					},
					{
						lable: '门店id',
						key: 'open_shop_uuid',
						type: 'text'
					},
					{
						lable: '门店名称',
						key: 'shopname',
						type: 'text',
					},
					{
						lable: '分点名称',
						key: 'branchname',
						type: 'text',
						width: 80
					},
					{
						lable: '门店地址',
						key: 'shopaddress',
						type: 'text',
					},
					{
						lable: '所在城市',
						key: 'cityname',
						type: 'text',
						width: 80
					},
					{
						lable: '授权状态',
						key: 'is_error',
						type: 'status',
						width: 80,
						options:[
							{title:"失败",key:"1"},
							{title:"成功",key:"0"}
						]
					}
				],
				count: 0,
				isDialog: false
			}
		},
		created() {
			this.getAuthList();
		},
		components: {
			baseList,
			pagination
		},
		methods: {
			toAuth() {
				this.$api.apiInterface(this.apiName, 'getAuthUrl').getAuthUrl({}, res => {
					this.isDialog = true;

					if (res.code == 0) {
						console.log("res", res);

						var authURL = decodeURIComponent(res.data);

						console.log("authURL", authURL);
						window.open(authURL);

					}

				})
			},
			getAuthList() {
				this.$api.apiInterface(this.apiName, 'shopList').shopList(this.queryData, res => {
					this.isDialog = false;
					if (res.code == 0) {
						console.log("res", res);
						this.listData = res.data.list;
						this.count = Number(res.data.count);
					}

				})
			}
		}
	}
</script>

<style scoped lang="less">
	/deep/ .is-leaf {
		background: rgba(141, 212, 97, .35) !important;
	}

	.topNav {
		display: flex;
		font-size: 16rem;
		color: #409EFF;
		align-items: center;
		margin-bottom: 20rem;
		margin-left: 25rem;
		cursor: pointer;
		user-select: none;

		img {
			width: 26rem;
			height: 26rem;
			margin-right: 12rem;
		}
	}

	.status {
		margin-bottom: 15rem;

		.icon {
			display: inline-block;
			width: 13rem;
			height: 13rem;
			background: #8DD461;
			border-radius: 50%;
			margin-right: 10rem;
		}
	}
</style>
