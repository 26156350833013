<template>
	<div>
		<el-card>
			<div class="status">
				<span class="icon"></span>
				<span style="font-size: 18rem;color: #666666;">资金</span>
			</div>
			<!-- 列表 -->
			<div v-if="isEdit">
				<!-- 筛选区域 -->
				<div style="margin: 30rem 0;">
					<topNav :navOptions.sync="screenOptions" :navCallBack="navCallBack"></topNav>
				</div>
				<baseList :listData="listData.list" :colList="colList"></baseList>
				<pagination :callback="pageSizeFun" :limit="queryData.limit" :page="queryData.page" :count="listData.count"></pagination>
			</div>
			
		</el-card>
	</div>
</template>

<script>
	import baseList from "@/components/baseList/index.vue"
	import pagination from "@/components/pagination/index.vue"
	import topNav from '@/components/topNav/index.vue'

	export default {
		name:"index_storeCapital",
		menuName:"资金列表",
		data() {
			return {
				//----接口名称----
				apiName: "Userbalance",
				//表头数据
				colList: [{
						lable: 'ID',
						key: 'id',
						type: 'text',
						width: 120
					},
					{
						lable: '类型',
						key: 'type',
						type: 'status',
						options: [{
								title: "管理员充值",
								key: "1"
							},
							{
								title: "付呗充值",
								key: "2"
							},
							{
								title: "会员卡消费",
								key: "10"
							}
						]
					},
					{
						lable: '变动金额',
						key: 'money',
						type: 'text',
					},
					{
						lable: '变动前余额',
						key: 'smoney',
						type: 'text',
					},
					{
						lable: '用户变动后金额',
						key: 'tmoney',
						type: 'text',
						width:120
					},
					{
						lable: '优惠券ID',
						key: 'coupon_id',
						type: 'text',
					},
					{
						lable: '创建时间',
						key: 'create_time',
						type: 'time'
					}
				],
				//操作接口参数
				screenOptions: [
					{
						key: 'type',
						value: '',
						lable: "资金类型",
						type: 'status',
						options: [{
								name: "全部",
								key: -1
							}
						]
					}
				],
				//后台接口数据渲染结构
				listData: {
					count:0,
					list:[]
				},
				isEdit:true,
				queryData:{
					page:1,
					limit:10,
					type:-1
				}
			}
		},
		components: {
			baseList,
			pagination,
			topNav
		},
		created() {
			//获取列表
			this.initData();
			
			//获取资金类型类型列表
			this.getTypeList();
		},
		inject:["layout"],
		methods:{
			//分页回调
			pageSizeFun(type, e) {
				console.log(type,e);
				if (type == 'handlePage') {

					this.queryData.page = e;
					
					this.initData();
				}
			},
			//获取列表
			initData(){
				this.$api.getList(this.apiName,this.queryData,res=>{
					// console.log("---",res);
					if(res.code == 0){
						this.layout.loading = false;
						this.listData.count = Number(res.data.count);
						this.listData.list = res.data.list;
					}
				})
			},
			//获取资金类型类型列表
			getTypeList(){
				this.$api.apiInterface(this.apiName,"typeList").typeList({},res=>{
					console.log("----",res);
					if(res.code == 0){
						var typeList = res.data;
						
						this.screenOptions.forEach(item=>{
							if(item.lable == '资金类型'){
								typeList.forEach(typeItem=>{
									var obj = {
										key:typeItem.id,
										name:typeItem.title
									}
									item.options.push(obj);
								})
								
							}
						})
					}
				})
			},
			listHandle(){
				this.isEdit = false;
			},
			navCallBack(dataList){
				
				dataList.forEach(item=>{
					this.queryData[item.key] = item.value;
				})
				
				this.queryData.type = this.queryData.type == "" ? -1 :  this.queryData.type;
				
				this.initData();
			}
		}
		
	}
</script>

<style scoped lang="less">
	/deep/ .is-leaf {
		background:rgba(141, 212, 97, .35) !important;
	}
	
	.status {
		margin-bottom: 15rem;
	
		.icon {
			display: inline-block;
			width: 13rem;
			height: 13rem;
			background: #8DD461;
			border-radius: 50%;
			margin-right: 10rem;
		}
	}
	
	.topNav{
		display: flex;
		font-size: 16rem;
		color: #409EFF;
		align-items: center;
		margin-bottom: 20rem;
		margin-left: 25rem;
		cursor: pointer;
		user-select: none;
		img{
			width: 26rem;
			height: 26rem;
			margin-right: 12rem;
		}
	}
	
	
</style>
