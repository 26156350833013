/**
 * 自带函数库
 */
import config from '@/config/config'

var fullscreen = false;

export default {
	/**
	 * 远程图片处理
	 * @param url
	 * @returns {string|*}
	 */
	imgIn(url) {
		if (url == null) return;
		if (url.indexOf('https://') != -1 || url.indexOf('http://') != -1) {
			return url;
		} else {
			return config.imageURL + url;
		}
	},
	/**
	 * 时间戳转时间
	 * @param timestamp
	 * @param format
	 * @returns {string}
	 */
	date(format = 'Y-m-d H:i:s', timestamp = this.time()) {
		let date = new Date(timestamp * 1000); // 时间戳对应的utc格式的日期，date是实例化对象
		let Y = date.getFullYear(); // 年
		let m = date.getMonth() + 1; // 月
		let d = date.getDate(); // 日
		let H = date.getHours(); // 时
		let i = date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes(); // 分
		let s = date.getSeconds() >= 10 ? date.getSeconds() : "0" + date.getSeconds(); // 秒
		format = format.replace(/Y/, Y);
		format = format.replace(/m/, m);
		format = format.replace(/d/, d);
		format = format.replace(/H/, H);
		format = format.replace(/i/, i);
		format = format.replace(/s/, s);
		return format;
	},
	// 时间年月日时分秒
	formatTime(val) {
		var date = new Date(val * 1000);
		var Y = date.getFullYear()
		var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
		var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
		var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
		var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
		var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
		return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
	},
	// 时间年月日
	formatYear(val) {
		var date = new Date(val * 1000);
		var Y = date.getFullYear()
		var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
		var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
		
		return Y + '-' + M + '-' + D 
	},
	/**
	 * 获取当前时间戳/秒
	 * @returns {string}
	 */
	time(is_second = true){
		if(is_second){
			return (new Date().getTime()/1000).toFixed(0)
		}else{
			return new Date().getTime()
		}
	},
	// 全屏方法
	handleFullScreen() {
		let element = document.documentElement;
		if (fullscreen) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		} else {
			if (element.requestFullscreen) {
				element.requestFullscreen();
			} else if (element.webkitRequestFullScreen) {
				element.webkitRequestFullScreen();
			} else if (element.mozRequestFullScreen) {
				element.mozRequestFullScreen();
			} else if (element.msRequestFullscreen) {
				// IE11
				element.msRequestFullscreen();
			}
		}
		fullscreen = !fullscreen;
	}
}
