import axios from 'axios';
import config from '@/config/config.js'
import Qs from 'qs'
import Vue from 'vue'

import VueRouter from 'vue-router'

const router = new VueRouter();

const that = new Vue();

export default {
	async request(options) {

		options.baseURL = config.baseURL
		if (options.method.toUpperCase() === 'GET') {
			options.params = options.data
		} else {
			options.data = Qs.stringify(options.data)
		}
		options.headers = options.headers !== undefined ? options.headers : {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
		options.headers.token = localStorage.getItem('token');
		options.headers.lang = localStorage.getItem('lang');
		options.method = options.method !== undefined ? options.method : 'GET'

		if (options.url == '/Recharge/searchBalance' || options.url == '/Recharge/focus') {
			options.timeout = 600000;
		} else {
			options.timeout = 15000;
		}

		return new Promise((resolve, reject) => {
			axios(options)
				.then((response) => {
					let data = response.data;
					if (data.code == 0 && data.msg == '登录状态失效，请重新登录') {
						localStorage.setItem('token', '')
						// window.location.replace("/")
					}else if(data.code == 1 && data.msg == '无访问权限'){
						
						var index = window.location.href.indexOf("#/");
						var path = window.location.href.slice(index+1);
						
						if(path == "/sessionsList/index" || path == "/auth/noAuth"){
							window.location.href = "#/auth/noAuth";
						}else{
							window.location.href = "#/auth/noAuth2";
						}
						
					}
					
					//201账号未登录
					if (data.code == 201) {
						
						localStorage.removeItem('active');
						localStorage.removeItem('token');
						
						window.location.replace("/");

					}
					
					resolve(data);
				}).catch((error) => {
					console.log("接口请求异常：", error);
					resolve(error);
				});
		});
	},
	async getImg(url) {
		let uu = url
		if (url.indexOf('://') == -1) {
			uu = config.imgDomain + url
		}
		return new Promise((resolve, reject) => {
			axios({
				method: 'get',
				url: uu,
			}).then(function(response) {
				resolve(response.data);
			}).catch(function(error) {
				console.log(error)
			})
		});
	},

	//图片上传
	async upload(data) {
		return new Promise((resolve, reject) => {
			let token = localStorage.getItem("token");
			
			let imgObj = {
				code:"",
				percent: 0,
				imgUrl: ""
			}

			axios({
				method: 'post',
				headers: {
					"Content-Type": "multipart/form-data",
					token
				},
				url: config.baseURL + '/store/Upload/addFiles', //自定义上传url
				data,
				onUploadProgress: progressEvent => {
					let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
					// console.log("--------",progressEvent);
					imgObj.percent = percent;

					resolve(imgObj);
				}
			}).then((res) => {
				if (res.data.code == 0) {
					let imgUrl = config.imageURL + res.data.data.url;
					
					imgObj.code = res.data.code;

					imgObj.imgUrl = imgUrl;

					resolve(imgObj);
				} else {
					reject(res);
				}
			}).catch((err) => {
				// console.log(err);
				reject(err);
			})

		});
	},
	
	//文件上传
	async uploadFile(data) {
		return new Promise((resolve, reject) => {
			let token = localStorage.getItem("token");
	
			axios({
				method: 'post',
				headers: {
					"Content-Type": "multipart/form-data",
					token
				},
				url: config.baseURL + '/admin/Upload/upload', //自定义上传url
				data
			}).then((res) => {
				if (res.data.code == 0) {
					// console.log("res.data",res.data);
	                var data = res.data;
					resolve(data);
				} else {
					reject(res);
				}
			}).catch((err) => {
				// console.log(err);
				reject(err);
			})
	
		});
	}

}
