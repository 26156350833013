<template>
	<div>
		<!-- 文本 -->
		<template v-if="item.type=='text' || item.type=='textarea' || item.type=='radio' || item.type=='checkBox'">
			<template v-if="typeof item.value=='object'">
				<div class="txt">{{item.value[item.attr]}}</div>
			</template>
			<template v-else>
				<div class="txt">{{item.value}}</div>
			</template>
		</template>
		
		<!-- 时间 -->
		<template v-else-if="item.type=='time'">
			<span>{{item.value | formatTime}}</span>
		</template>
		
		<!-- 图片 -->
		<template v-else-if="item.type=='image'">
			<el-image style="width: 50px; height: 50px" :src="item.value"
				:preview-src-list="[item.value]">
			</el-image>
		</template>
		
		<!-- 状态 -->
		<template v-else-if="item.type=='status'">
			
			<span v-for="(optionsItem,optionsIndex) in item.options" :key="optionsItem.key" size="mini" 
			v-if="optionsItem.key == item.value">{{optionsItem.title}}</span>


		</template>
		
		<template v-else>
			<slot :name="item.type" :row="item"></slot>
		</template>
	</div>
</template>

<script>
	export default{
		data() {
			return{
				
			}
		},
		computed:{
			// statusType(){
			// }
		},
		props:{
			item:Object,
			index:Number
		},
		filters: {
			formatTime(val) {
				var date = new Date(val * 1000);
				
				var year = date.getFullYear();
				
				
				var month = (date.getMonth() + 1 < 10) ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1;
				
				var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
				
				var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
				
				var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
				
				var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
				
				return year + '-' + month + '-' + day + ' ' + h + ':' + m + ':' + s;
			}
		},
		methods:{
			statusType(optionsItem){
				return optionsItem.value ==  optionsItem.key && optionsItem.value!=0 ? 'success' : 'danger';
			}
		}
	}
</script>

<style scoped lang="less">
	.txt{
		//超出两行省略号
		display: -webkit-box;
		overflow: hidden;
		-webkit-box-orient: vertical;
		line-clamp: 2;
		-webkit-line-clamp: 2; //显示几行
	}
</style>