const tabList = JSON.parse(localStorage.getItem("tabList"));
const breadRoute = localStorage.getItem("breadRoute") ? JSON.parse(localStorage.getItem("breadRoute")) : {
	pRoute: {},
	childRoute: []
};

const MENU = localStorage.getItem("menu") && JSON.parse(localStorage.getItem("menu"));
const activeIndex = localStorage.getItem("topHeaderIndex");

export default {
	state: {
		routePage: tabList ? tabList : [], //标签页
		breadRoute, //面包屑
		tabIndex: 0
	},
	getters: {

	},
	mutations: {
		//添加路由页面
		addRoutePage(state, val) {

			let index = state.routePage.findIndex(item => {
				return item.path == val.path;
			})

			//获取当前展示的标签页下标
			state.tabIndex = index;
			//缓存当前标签页
			localStorage.setItem("tabIndex", state.tabIndex);

			if (index == -1) {

				state.routePage.push(val);
				localStorage.setItem("tabList", JSON.stringify(state.routePage));

			}

			// console.log("state.routePage",state.routePage);

		},
		//删除标签路由页面
		deleteRoutePage(state, val) {
			var index = state.routePage.findIndex(item => {
				return item.path == val.path;
			})

			state.routePage.splice(index, 1);

			localStorage.setItem("tabList", JSON.stringify(state.routePage));
		},
		//设置面包屑
		setBreadRoute(state, val) {

			if (val.pid == 0) {
				state.breadRoute.pRoute = val;
				console.log("val",val);
				
				state.breadRoute.childRoute = [];
				
				state.breadRoute.childRoute.push(val.children[0]);
				return;
			}

			let index = state.breadRoute.childRoute.findIndex(item => {
				return item.path == val.path;
			})

			if (index == -1 && val.pid == state.breadRoute.pRoute.id) {
				state.breadRoute.childRoute = [];

				state.breadRoute.childRoute.push(val);
				
			}else if(MENU && MENU[activeIndex] && MENU[activeIndex].children){

				MENU[activeIndex].children.forEach(pItem => {

					if (pItem.id == val.pid) {

						state.breadRoute.childRoute = [];

						let arr = [];
						arr.push(val);

						pItem.children = arr;

						state.breadRoute.childRoute.push(pItem);

					}

				})

			}
			
			localStorage.setItem("breadRoute", JSON.stringify(state.breadRoute));

		}

	},
	actions: {
       
	}
}
