import $api from "@/request/api.js"

export default{
	state:{
		menuList:[] // 菜单列表
	},
	getters:{
		
	},
	mutations:{
		//获取菜单
		getMenuList(state,params){
			
			if (localStorage.getItem("menuList")) {
				state.menuList = JSON.parse(localStorage.getItem("menuList"));
			}
		},
		//保存菜单
		saveMenu(state,params){
			// console.log("params",params);
			var menuList = params;
			
			state.menuList = params;
			//存储菜单
			localStorage.setItem("menuList",JSON.stringify(menuList));
		}
	},
	actions:{
		//获取路由配置
		getRoutesList({commit},callback){
			
			var routesList = [];
			
			var menu = [];
			
			if (localStorage.getItem("routesArr")) {
				routesList = JSON.parse(localStorage.getItem("routesArr"));
			}
			
			$api.getMenuList({}, res => {
				console.log("res", res);
				if (res.code == 0) {
					var menuList = res.data;
					
					menu = menuList.filter(item=>item.type == 1);
					
					// console.log("menu",menu);
					//保存菜单
					commit("saveMenu",menu);
					
					callback();
				}
			})
			
		}
	}
}

function formatRoute(menus,routes){
	var list = [];
	
	menus.forEach(item=>{
		routes.forEach(routeItem=>{
			if(item.path == routeItem.path && item.type == 1){
				routeItem.title = item.title;
				list.push(routeItem);
			}
		})
	})
	
	return list;
}