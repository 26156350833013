import $request from "./request.js";
import axios from 'axios';

import page from './page/page.js'

export default {
	...page,
	/**
	 * 用户登录
	 */
	login(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/login/login',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	/**
	 * 基础设置
	 */
	getShopList(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/Home/getInfo',
			method: 'get',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	/**
	 * 保存基础设置
	 */
	setShopList(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/store/saveInfo',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	
	/**
	 * 优惠卷保存与添加
	 */
	setCoupon(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/coupon/edit',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	
	/**
	 * 优惠卷列表
	 */
	getCoupon(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/coupon/getList',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	
	/**
	 * 删除指定优惠卷
	 */
	delCoupon(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/coupon/del',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	
	/**
	 * 剧本列表
	 */
	getBookList(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/books/getList',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	
	/**
	 * 会员列表
	 */
	getUserList(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/user/getList',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	
	
	
	/**
	 * 发送优惠卷
	 */
	setCouponSend(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/coupon/send',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	
	/**
	 * 优惠券发送状态
	 */
	getCouponSending(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/coupon/sendStatus',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	
	
	
	/**
	 * 图片上传
	 */
	CommonUpload(data = {}, ret = () => {}) {
		$request.upload(data).then(res => {
			ret(res)
		})
	},
	/**
	 * 文件上传
	 */
	fileUpload(data = {}, ret = () => {}) {
		console.log("data",data);
		$request.uploadFile(data).then(res => {
			ret(res)
		})
	},
	
	//图片组上传图片
	addImgFiles(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/Upload/addFiles',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	//添加图片组
	saveImgGroup(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/Upload/saveGroup',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	//获取图片组
	getImgGroup(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/Upload/getGroup',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	//删除图片组
	delImgGroup(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/Upload/delGroup',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	//获取图片库列表
	getImgList(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/Upload/getFiles',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	//修改图片名称
	updateImgName(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/Upload/changeFiles',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	//图片删除
	delFiles(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/Upload/delFiles',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	//--------------------------
	//获取角色列表
	getRoleSet(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/StoreManageGroup/getList',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	//角色组添加
	getRoleList(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/StoreManageGroup/saveGroup',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	//获取员工列表
	getAdminList(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/StoreManage/getList',
			method: 'post',
			data: data
		}).then(res => {
			// console.log(res);
			ret(res)
		})
	},
	//添加修改员工列表
	setAdminList(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/StoreManage/saveUser',
			method: 'post',
			data: data
		}).then(res => {
			// console.log(res);
			ret(res);
		})
	},
	//删除员工
	delAdminUser(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/StoreManage/del',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	//删除角色
	delGroup(data = {}, ret = () => {}) {
		$request.request({
			url: '/store/StoreManageGroup/del',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	//获取菜单列表
	getMenuList(data = {}, ret = () => {}){
		$request.request({
			url: '/store/Home/getStoreMenu',
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	}
}
