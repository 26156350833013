<template>
	<div class="login">
		<div class="loginBox">
			<el-card>
				<div class="form">
					<h2>门店管理系统</h2>
					<div class="formList">
						<div class="input">
							<span class="el-icon-s-custom"></span>
							<span class="placeTxt"
								:style="'top:'+(inputIndex != 0 && form.username=='' ? 50 : 0)+'%;'">请输入账号</span>
							<input type="text" @focus="focusHandle(0)" v-model="form.username">
						</div>
						<div class="input">
							<span class="el-icon-lock"></span>
							<span class="placeTxt"
								:style="'top:'+(inputIndex != 1 && form.password=='' ? 50 : 0)+'%;'">请输入密码</span>
							<input type="password" @focus="focusHandle(1)" v-model="form.password">
						</div>
						<!-- ripple -->
						<div class="loginBtn " @click="login($event)">
							登录
							<!-- <span class="ripple-div" :style="`top: ${rippleXY.top};left: ${rippleXY.left};`"></span> -->
						</div>
					</div>
				</div>
				<div class="formBg"></div>
			</el-card>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations,
		mapActions
	} from 'vuex'

	export default {
		name: "login",
		data() {
			return {
				rippleXY: {
					top: 0,
					left: 0
				},
				form: {
					username: "",
					password: ""
				},
				isLogin: false,
				inputIndex: null
			}
		},
		created() {
			
		},
		methods: {
			...mapMutations(["setToken"]),
			...mapActions(["getRoutesList"]),
			focusHandle(index) {
				this.inputIndex = index;
			},
			login(e) {
				this.$api.login(this.form, (res) => {
					if (res.code == 0) {
						
						localStorage.setItem('token',res.data.token);
						
						this.$message({
							message: '恭喜你，登录成功',
							type: 'success'
						});
						
						this.getRoutesList(()=>{
							this.setToken(res.data.token);
						});

						this.$api.getShopList({}, (res) => {
							if (res.code == 0) {
								
								localStorage.setItem('shopInfo', JSON.stringify(res.data))
								
							} else {
								this.$message.error(res.msg);
							}
						})


					} else {
						this.$message.error(res.msg);
					}
				})

				// 重新计算水波纹的中心位置
				this.rippleXY.top = `${e.offsetY - 376 / 2}rem`;
				this.rippleXY.left = `${e.offsetX - 376 / 2}rem`;

			}
		}
	}
</script>

<style scoped lang="less">
	.login {
		width: 100%;
		min-height: 100vh;
		height: auto;
		background: url("../../static/loginBg.png") no-repeat;
		background-size: cover;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	/deep/.el-card__body {
		display: flex;
		padding: 0rem;
	}

	.loginBox {
		width: 1200rem;

		.form {
			width: 50%;
			height: 640rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 100rem;
			box-sizing: border-box;

			h2 {
				margin: 40rem 0;
			}

			.input {
				width: 376rem;
				height: 46rem;
				border: 2rem solid #dcdfe6;
				color: #d1d1d1;
				border-radius: 5rem;
				box-sizing: border-box;
				font-size: 18rem;
				display: flex;
				align-items: center;
				padding-left: 10rem;
				margin: 25rem 0;
				position: relative;

				.placeTxt {
					position: absolute;
					transform: translateY(-50%);
					left: 40rem;
					font-size: 15rem;
					pointer-events: none;
					transition: all .2s;
					background: #fff;
				}

				input {
					border-radius: 5rem;
					width: 100%;
					height: 100%;
					padding-left: 15rem;
					box-sizing: border-box;
				}
			}

			.loginBtn {
				width: 376rem;
				height: 46rem;
				background: #1869ff;
				border-radius: 5rem;
				color: #fff;
				text-align: center;
				line-height: 46rem;
				cursor: pointer;
				user-select: none;
				position: relative;
				overflow: hidden;

				/* 水波纹样式-开始 */
				.ripple-div {
					display: inline-block;
					width: 376rem;
					height: 376rem;
					position: absolute;
					// transform-origin: top;
					z-index: 10;
					border-radius: 50%;
					background: hsla(0, 0%, 100%, 0.3);
					transform: scale(0);
					pointer-events: none;
					animation: rippleAnima 1s linear;
				}
			}
		}

		.formBg {
			width: 50%;
			height: 640rem;
			background: url("../../static/loginBanner.png") no-repeat;
			background-size: 100% 100%;
		}
	}

	@keyframes rippleAnima {
		to {
			opacity: 0;
			-ms-transform: scale(3);
			-moz-transform: scale(3);
			-o-transform: scale(3);
			-webkit-transform: scale(3);
			transform: scale(3);
		}
	}
</style>
