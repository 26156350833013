<template>
	<el-dialog title="图片" :visible.sync="is_show" width="70%">
	<div style="height: 100%;border-left: 1px solid #dee2e6;
		border-right: 1px solid #dee2e6;border-bottom: 1px solid #dee2e6;">
		<!-- 操作区 -->
		<div class="topControlArea">
			<div class="left">
				<el-input v-model="queryData.keyword" size="mini" placeholder="请输入内容"></el-input>

				<el-button style="margin-left: 10px;" size="mini" type="primary" icon="el-icon-search" @click="search"></el-button>
			</div>

			<div class="right">
				<el-button size="mini" type="info" @click="uploadImg">上传图片</el-button>
				<el-button size="mini" type="warning" @click="addImgList">创建图片册</el-button>
				<el-button size="mini" type="danger" @click="batchHandle">{{delTxt}}</el-button>
				<template v-if="batchCon">
					<el-button size="mini" type="success" @click="sureClick">确定</el-button>
				</template>
			</div>
		</div>

		<div class="imgLibrary">
			<el-aside width="200px;">
				<el-menu default-active="2" class="el-menu-vertical-demo" style="min-height: 636rem;height: auto;">
					<el-menu-item :index="index.toString()" v-for="(item,index) in groupList" :key="index"
						@click="changeGroup(item)">
						<div>{{item.catename}}</div>

						<div class="conBtn">
							<span>></span>
							<div class="editArea">
								<span @click.stop="handleGroup('del',item)">删除</span>
								<span @click.stop="handleGroup('edit',item)">编辑</span>
							</div>
						</div>

					</el-menu-item>
				</el-menu>
			</el-aside>
			<el-main style="">
				<div ref="drag" style="height: 100%;position: relative;">

					<div class="bgTxt" v-show="isBgTxt">
						将文件拖动到此处上传
					</div>

					<div class="imgList">

						<div class="imgItem" v-for="(item,index) in imgList" :key="item.id"
							@click="handleSelect(item,index)" @dragstart="dragStart(item, index)"
							@dragover.prevent="dragOver(index)" @dragend="dragEnd()" draggable
							:class="{ 'is-dragover': index === newIndex }">

							<div class="img">
								<span class="imgName">{{item.name}}</span>
								<el-image style="width: 100px; height: 100px" :preview-src-list="[$F.imgIn(item.pic)]" :src="$F.imgIn(item.pic)" fit="cover">
								</el-image>
							</div>

							<div class="control">
								<el-button size="mini" icon="el-icon-edit" @click="editImg(item)"></el-button>
								<el-button size="mini" icon="el-icon-check" @click="submit(item)"></el-button>
								<el-button size="mini" icon="el-icon-delete" @click="delImg(item)"></el-button>
							</div>

							<div class="corner" v-if="item.isCheck && batchCon">
								{{item.corner}}
							</div>
						</div>

						<!-- 图片上传 -->
						<el-upload style="margin-bottom: 10px;" list-type="picture-card" :http-request="my_upload"
							:action="$config.baseURL + '/User/upload'" multiple :show-file-list="false"
							:on-error="handleAvatarError" :before-upload="beforeAvatarUpload">
							<i class="el-icon-plus"></i>
						</el-upload>

					</div>

				</div>

				<!-- 分页 -->
				<div
					style="border-top: 1px solid #EBEEF5;margin-top: auto;display: flex;align-items: center;justify-content: space-around;width: 100%;padding-bottom: 20px;">
					<el-pagination style="text-align: center;margin-top: 20px;" :current-page="queryData.page" :total="count"
						:page-size="queryData.limit" :pager-count="5" :page-sizes="pagesSize"
						layout="prev, pager, next, jumper, ->, sizes, total" @current-change="handlePageChange"
						@size-change="handleSizeChange" />
				</div>
			</el-main>
		</div>
		
		<el-dialog  :modal = 'false' title="上传图片" :visible.sync="isUpload" width="50%">
			
		    <div style="display: flex; justify-content: center;align-items: center;flex-direction: column;">
				<el-select v-model="queryData.group_id" placeholder="请选择要上传的图片组">
				    <el-option
				      v-for="(item,index) in groupList"
				      :key="item.id"
				      :label="item.catename"
				      :value="item.id">
				    </el-option>
				  </el-select>
				
				<el-upload
				  class="upload-demo"
				  drag
				  :http-request="my_upload"
				  :action="$config.baseURL + '/User/upload'"
				  multiple
				  :show-file-list="false"
				  style="margin-right: 190px;margin-top: 20px;">
				  <i class="el-icon-upload"></i>
				  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
				  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件</div>
				</el-upload>
			</div>
		
		</el-dialog>

		<el-dialog :modal = 'false' title="修改图片名称" :visible.sync="isEditImgName" width="30%">

			<el-input size="mini" v-model="editImgData.name" placeholder="请输入内容"></el-input>

			<span slot="footer" class="dialog-footer">
				<el-button size="mini" @click="isEditImgName = false">取 消</el-button>
				<el-button size="mini" type="primary" @click="editImgName">确 定</el-button>
			</span>
		</el-dialog>
		
		<!-- 图片组 -->
		<el-dialog :modal = 'false' title="图片集" :visible.sync="isDialogShow" width="30%">
		
		    <label>
				<span style="display: block; margin-bottom: 10rem;">图片集名称：</span>
				<el-input size="mini" v-model="imgGroupData.catename" placeholder="请输入名称"></el-input>
			</label>
			
			<label>
				<span style="display: block; margin-bottom: 10rem;margin-top: 10rem;">排序：</span>
				<el-input size="mini" v-model="imgGroupData.ord" placeholder="排序"></el-input>
			</label>
			
		
			<span slot="footer" class="dialog-footer">
				<el-button size="mini" @click="isDialogShow = false">取 消</el-button>
				<el-button size="mini" type="primary" @click="submitHandle">确 定</el-button>
			</span>
		</el-dialog>
		
	</div>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			toImage:{
				type:Function,
				default:()=>{}
			}
		},
		data() {
			return {
				is_show:false,
				options: {
					token: localStorage.getItem("admintoken")
				},
				optionsObj: {
					image: ""
				},
				//图片列表
				imgList: [],
				//图片组
				groupList: [],
				selectList: [],
				oldIndex: "",
				oldData: "",
				newIndex: "",
				count: 0,
				editGroupId: '',
				imgGroupData:{
					catename:"",
					ord:""
				},
				groupId: '0',
				queryData: {
					group_id: '0',
					page: 1,
					limit: 20,
					keyword: ''
				},
				editImgData: {
					name: "",
					id: 0
				},
				isEditImgName: false,
				batchCon: false,
				uploadProgress: 0,
				file: null,
				isBgTxt: false,
				delTxt: "批量删除",
				isUpload:false,
				isDialogShow:false
			}
		},
		//表单页数据
		provide() {
			return {
				formData: this.formData
			}
		},
		computed: {
            pagesSize() {
            	var pagesArr = [3, 5, 10, 20, 30]
            	pagesArr.forEach((item, index) => {
            		if (item == this.queryData.limit) {
            			pagesArr.splice(index, 1, item)
            		}
            	})
            
            	return pagesArr
            }
		},
		components: {
		},
		created() {
			//获取图片组
		},
		async mounted() {
			// 给容器绑定相关的拖拽事件
			// this.bindEvents()
		},
		methods: {
			show(){
				this.initData();
				this.is_show = true
			},
			hide(){
				this.is_show = false
			},
			search(){
				this.getImgList();
			},
			handleSizeChange(pageSize) {
				this.queryData.limit = pageSize;
				this.getImgList();
			},
			handlePageChange(page) {
				this.queryData.page = page;
				this.getImgList();
			},
			sureClick() {
				this.$confirm('此操作将永久删除选中数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {

					this.selectList.forEach(item => {
						var id = item.id;

						this.$api.delFiles({
							id
						}, res => {
							if (res.code == 0) {
								this.$message.success('删除成功');
								this.initBatchData();
								this.getImgList();
								return;
							}
							this.$message.warning(res.msg);
						})
					})


				}).catch(() => {

					this.$message({
						type: 'info',
						message: '已取消删除'
					});

				});
			},
			initBatchData() {
				this.selectList = [];

				this.imgList.forEach((item, index) => {
					item.corner = 0;
					item.isCheck = false;
				})
			},
			//批量操作
			batchHandle() {

				this.initBatchData();
				this.batchCon = !this.batchCon;
				this.delTxt = this.batchCon ? '关闭批量删除' : '批量删除';
			},
			//删除图片
			delImg(val) {
				var id = val.id;

				this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {

					this.$api.delFiles({
						id
					}, res => {
						if (res.code == 0) {
							this.$message.success('删除成功');
							this.initBatchData();
							this.getImgList();
							return;
						}
						this.$message.warning(res.msg);
					})

				}).catch(() => {

					this.$message({
						type: 'info',
						message: '已取消删除'
					});

				});


			},
			//确定选择的图片
			submit(val){
				
				this.toImage(val.pic)
				// this.$emit('changeImg',val.pic)
			},
			//编辑图片
			editImg(val) {
				this.isEditImgName = true;
				this.editImgData = val;
			},
			//编辑图片名称
			editImgName() {
				this.$api.updateImgName(this.editImgData, res => {
					this.isEditImgName = false;
					if (res.code == 0) {
						this.$message.success('修改成功');
						this.getImgList();
						return;
					}
					this.$message.warning(res.msg);
				})
			},
			//操作分组
			handleGroup(type, val) {
				var id = val.id;

				if (type == 'del') {

					if (this.groupList.length == 1) {
						this.$message.warning("最多需保留一个图片组");
						return;
					}

					this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {

						this.$api.delImgGroup({
							id
						}, res => {
							if (res.code == 0) {
								this.$message.success('删除成功');
								this.initData();
								return;
							}
							this.$message.warning(res.msg);
						})

					}).catch(() => {

						this.$message({
							type: 'info',
							message: '已取消删除'
						});

					});


				} else {
					var groupData = val;

					this.editGroupId = val.id;

					this.imgGroupData = val;

					this.isDialogShow = true;

				}
			},
			//获取图片列表
			getImgList() {

				this.$api.getImgList(this.queryData, res => {
					if (res.code == 0) {
						var imgList = [].concat(res.data.list);
						
						this.count = Number(res.data.count);

						this.imgList = imgList.map(item => {
							item.corner = 0;
							item.isCheck = false;
							return item;
						})

					}
				})
			},
			//提交表单
			submitHandle() {

				var formData = {}

				if (this.editGroupId != '') {
					this.imgGroupData.id = this.editGroupId;
				}

				this.$api.saveImgGroup(this.imgGroupData, res => {
					this.isDialogShow = false;

					if (res.code == 0) {
						this.$message.success('创建成功');
						this.initData();
						return;
					}
					this.$message.warning(res.msg);
				})

			},
			//获取图片组
			initData() {
				this.$api.getImgGroup({}, res => {
					if (res.code == 0) {
						
						this.groupList = res.data;
						
						if(res.data.length == 0){
							this.queryData.group_id = ''
						}else{
							this.queryData.group_id = this.groupList[0].id
						}

						//获取图片列表
						this.getImgList();
					}
				})
			},
			//添加图片组
			addImgList() {
				this.editGroupId = '';

				this.isDialogShow = true;
			},
			//翻页
			handlePageChange(e) {
				this.queryData.page = e;
				this.initData();
			},
			//每页展示数据
			handleSizeChange(e) {
				this.queryData.limit = e;
				this.initData();
			},
			dragStart(val, i) {
				this.oldIndex = i;
				this.oldData = val;
			},
			dragOver(i) {
				this.newIndex = i;
			},
			dragEnd() {
				let newItems = [...this.imgList];
				// 删除老的节点
				newItems.splice(this.oldIndex, 1);
				// 在列表中目标位置增加新的节点
				newItems.splice(this.newIndex, 0, this.oldData);
				this.imgList = [...newItems];
				this.newIndex = "";
			},
			//排序
			handleSelect(item) {

				if (!this.batchCon) return;

				item.isCheck = !item.isCheck;

				if (item.isCheck) {
					this.selectList.push(item);
					item.corner = this.selectList.length;
				} else {

					var index = this.selectList.findIndex(v => v.id == item.id);

					for (var i = index; i < this.selectList.length; i++) {
						this.selectList[i].corner--;
					}

					this.selectList.splice(index, 1);

					item.corner = 0;
				}

			},
			beforeAvatarUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
					return isLt2M;
				} else {
					this.optionsObj.image = file;
				}
			},
			handleAvatarError(error) {
				console.log(error);
			},
			//切换图片组
			changeGroup(val) {
				this.groupId = val.id;
				this.queryData.group_id = this.groupId;

				this.getImgList();
			},
			uploadImg(){
				
				this.queryData.group_id = this.groupList[0].id;
				this.isUpload = true;
			},
			my_upload(uploader) {
                
				if(this.queryData.group_id == ""){
					this.$message.warning("请先选择图片组");
					return;
				}
				
				let form = new FormData();

				if (uploader.file) {
					form.append('file', uploader.file);
				} else {
					form.append('file', uploader);
				}

				form.append('group_id', this.queryData.group_id);
				this.$api.CommonUpload(form, res => {
                    
					if(res.code == 0){
						this.$message.success('上传成功');
						this.isUpload = false;
						setTimeout(()=>{
							this.getImgList();
						},500)
					}else{
						this.$message.warning('上传失败');
					}
					

				})

			},
			//图片拖拽上传
			bindEvents() {
				this.uploadProgress = 0;

				const drag = this.$refs.drag
				// 被拖动的对象进入目标容器
				drag.addEventListener('dragover', e => {
					this.isBgTxt = true;
					console.log("this.isBgTxt", this.isBgTxt);
					e.preventDefault()
					drag.style.borderColor = 'red'
				})
				// 被拖动的对象离开目标容器
				drag.addEventListener('dragleave', e => {
					this.isBgTxt = false;
					e.preventDefault()
					drag.style.borderColor = '#eee'
				})
				// 被拖动的对象进入目标容器，释放鼠标键
				drag.addEventListener('drop', e => {
					this.isBgTxt = false;

					e.preventDefault()
					drag.style.borderColor = '#eee';
					const fileList = e.dataTransfer.files;

					this.my_upload(fileList[0])

				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.bgTxt {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 40px;
		color: #dee2e6;
	}

	.drag-message-input {
		position: absolute;
		left: -100px;
		top: -100px;
		z-index: -1;
		display: none;
	}

	/deep/ .el-upload {
		width: 150px;
		height: 150px;
	}

	.drag-move {
		transition: transform 0.3s;
	}

	.list {
		display: flex;
		flex-wrap: wrap;
	}

	.imgLibrary {
		width: 100%;
		display: flex;
		height: 90%;
		background: #fff;
	}

	.el-aside {
		// background-color: #D3DCE6;
		color: #333;
		// text-align: center;
		width: 180px;
		height: 100%;
		border-right: 1px solid #dee2e6;
	}

	.el-main {
		display: flex;
		flex-direction: column;
		// flex: 1;
		// height: 100%;
		padding: 0px;
	}

	.topControlArea {

		width: 100%;
		display: flex;
		justify-content: space-between;
		background: #fff;
		padding: 15px 10px;
		box-sizing: border-box;
		border-top: 1px solid #dee2e6;
		border-bottom: 1px solid #dee2e6;

		.left {
			display: flex;
		}
	}

	/deep/ .el-menu {
		border-right: none !important;
	}

	/deep/ .el-menu-item {
		border-bottom: 1px solid #dee2e6;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.el-menu-item {
		line-height: 0;
	}

	.conBtn {
		height: 20px;
		width: 20px;
		border: 1px solid #dee2e6;
		padding: 5px 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
		position: relative;

		.editArea {
			width: 50px;
			height: 80px;
			display: none;
			flex-direction: column;
			position: absolute;
			bottom: -86px;
			right: 0px;
			background: #fff;
			color: #333;
			z-index: 99;
			text-align: center;
			border-radius: 5px;
			box-shadow: 1px 1px 5px 1px #dee2e6;
			padding: 0 5px;

			span {
				display: inline-block;
				height: 40px;
				line-height: 40px;
			}
		}

		.editArea::after {
			content: "";
			display: inline-block;
			width: 0;
			height: 0;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 7px solid #fff;
			position: absolute;
			right: 5px;
			top: -7px;
		}
	}

	.conBtn:hover .editArea {
		display: flex;
	}

	.imgList {
		display: flex;
		// align-items: center;
		flex-wrap: wrap;
		padding: 20px 20px;
		box-sizing: border-box;

		.imgItem {
			width: 150px;
			height: 150px;
			border: 1px solid #dee2e6;
			border-radius: 5px;
			overflow: hidden;
			cursor: pointer;
			margin-right: 15px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			margin-bottom: 10px;

			.img {
				width: 100%;
				height: 110px;
				display: flex;
				align-items: center;
				justify-content: center;
				overflow: hidden;
				position: relative;

				.imgName {
					width: 100%;
					background: rgba(0, 0, 0, .7);
					text-align: center;
					color: #fff;
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 99;
				}

				img {
					width: 100%;
					height: 100%;
				}
			}

			.corner {
				width: 20px;
				height: 20px;
				position: absolute;
				right: 0;
				top: 0;
				background: red;
				border-radius: 5px;
				// padding: 10px 10px;
				text-align: center;
				color: #fff;
			}
		}

		.is-dragover::before {
			content: "";
			display: inline-block;
			position: absolute;
			bottom: 0px;
			left: 0px;
			width: 150px;
			height: 5px;
			background-color: #0c6bc9;
		}

		.is-dragover::after {
			content: "";
			display: inline-block;
			position: absolute;
			bottom: -12px;
			left: -6px;
			border: 3px solid #0c6bc9;
			border-radius: 50%;
			width: 6px;
			height: 6px;
			background-color: #fff;
		}

		.control {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 40px;
			border-top: 1px solid #ccc;

			.el-button {
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
</style>
