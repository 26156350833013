const UA = window.navigator.userAgent.toLowerCase();

export default {
	data: {
		width: 0,
		mwidth: 0,
		userAgent:""
	},
	init() {
		
		this.data.userAgent = /iphone|ipad|ipod|ios/.test(UA);
		
		if(this.data.userAgent){
			
			this.initAgentRem();
			
		}else{
			
			this.initRem();
			
			window.addEventListener("resize",this.resizeHandle.bind(this));
			
		}
		
	},
	initAgentRem(width = 1915){
		
		this.data.width = width;
		let that = this
		this.setRem();
	},
	resizeHandle(){
		
		let w = window.innerWidth;
		if(window.innerWidth > 1225){
			this.initRem();
			var that = this;
		}else if(window.innerWidth < 1225){
			this.initRem(1915);
		}
	},
	initRem(width = 1980) {
		
		this.data.width = width;
		let that = this;
		this.setRem();
		
		// window.onresize = function() {
		// 	that.setRem();
		// }
		
	},
	setRem() {
		let w = window.innerWidth;
		
		document.querySelector('html').style.fontSize = (w / this.data.width) + 'px';
	}
}
