<template>
	<div>
		<el-form-item :label="title">
			<div class="image_pre_box">
				<el-image v-if="image" class="elimg"
				    :src="image" 
				    :preview-src-list="[image]">
				  </el-image>
				<i v-else  @click="openUpbox" class="el-icon-plus avatar-uploader-icon"></i>
			</div>
			<el-button v-if="image" size="small" @click="openUpbox" type="warning">替换图片</el-button>
		</el-form-item>
		<imgLibrary ref="imgLibrary" :toImage="changeImg"></imgLibrary>
	</div>
</template>

<script>
	import imgLibrary from './imgLibrary.vue';
	export default { 
		props: {
			title: {
				type: String,
				default: '未设置标题'
			},
			image:{
				type: String,
				default: ''
			},
			toRes:{
				type:Function,
				default:()=>{}
			}
		},
		components:{ 
			imgLibrary
		},
		data() {
			return {
			}
		},
		methods:{
			openUpbox(){
				this.$refs.imgLibrary.show()
			},
			changeImg(url){
				this.toRes(url)
				this.$refs.imgLibrary.hide()
			},
		}
	}
</script>

<style scoped>
	.image_pre_box {
		display: block;
		width: 180rem;
		height: 180rem;
		background-color: #fbfdff;
		border: 1px dashed #c0ccda;
		border-radius: 6px;
		box-sizing: border-box;
		cursor: pointer;
		position: relative;
	}

	.avatar-uploader-icon {
		display: block;
		width: 180rem;
		height: 180rem;
		font-size: 28rem;
		color: #8c939d;
		text-align: center;
		line-height: 180rem;
		display: block;
	}
	.elimg{
		width: 180rem;
		height: 180rem;
	}
</style>
