<template>
	<div class="page">
		<!-- 会员信息 -->
		<div class="memberInfo">
			<!-- 未登录 -->
			<template v-if="!isLogin">
				<div class="img" @click="scanCode">
					<img src="../../static/code.png" alt="">
				</div>
				<p style="color: #828282;font-size: 18rem;">点击扫码登录</p>
				<div class="search">
					<p>输入手机号查询：</p>
					<div class="input">
						<input type="text" v-model="keyWord" placeholder="请输入手机号" />
						<div class="searchBtn">搜索</div>

						<div class="result" v-if="phoneList.length > 0">
							<ul>
								<li v-for="(item,index) in phoneList" :key="index" @click="liHandle(item)">
									<span>{{item.mobile}}</span>
									<img src="../../static/arrowIcon.png" alt="">
								</li>
							</ul>
						</div>
					</div>
				</div>
			</template>

			<!-- 已登录 -->
			<template v-else>
				<div class="memberAvatar">
					<img :src="userInfo.avatar || require('../../static/home_huli.png')" alt="">
					<span>{{userInfo.nikename}}</span>
				</div>
				<div class="info">
					<ul>
<!-- 						<li>
							<span class="title">余额：</span>
							<span class="txt">{{userInfo.nikename}}</span>
						</li> -->
						<li>
							<span class="title">积点：</span>
							<span class="txt">{{userInfo.integral}}</span>
						</li>
						<li>
							<span class="title">手机号：</span>
							<span class="txt">{{userInfo.mobile}}</span>
						</li>
						<li>
							<span class="title">性别：</span>
							<span class="txt">{{userInfo.sex == '1' ? '男' : '女'}}</span>
						</li>
						<li>
							<span class="title">生日：</span>
							<span style="font-size: 18rem;" v-if="userInfo.birthday==0">未设置</span>
							<span class="txt" v-else>{{userInfo.birthday | formatTime}}</span>
						</li>
						<!-- <li>
							<span class="title">累计充值：</span>
							<span class="txt">{{userInfo.nikename}}</span>
						</li> -->
						<li style="flex-direction: column;align-items: flex-start;">
							<span class="title">最后一次登录时间：</span>
							<span style="display: block;" class="txt">{{userInfo.last_time | formatTime}}</span>
						</li>
					</ul>
				</div>

				<div class="recharge" @click="cardBtn">余额</div>
				
				<div class="recharge" style="margin-top: 20rem;" @click="conDetails">消费明细</div>
				
				<div class="recharge" style="margin-top: 20rem;" @click="getScoreList">积分消费明细</div>
				
				<div class="recharge" style="margin-top: 20rem;" @click="isScore=true">积分充值</div>

				<!-- 退出登录 -->
				<div class="loginOut" @click="loginOut">退出登录</div>
			</template>

		</div>
		<!-- 操作区 -->
		<div class="control">

			<!-- 操作内容 -->
			<div class="controlCon">
				<!-- 返回按钮 -->
				<!-- <transition name="el-zoom-in-top"> -->
				<router-view></router-view>
				<!-- </transition> -->
			</div>
			
		</div>

		<!-- 小白盒扫码 -->
		<scanBox ref="scanBox" :wxToRes="memberLogin"></scanBox>
		
        <!-- 积分充值 -->
        <el-dialog title="积分充值" :visible.sync="isScore" width="30%">
        	<el-form ref="form" :model="scoreQuery" label-width="140rem" size='small'>
        
        		<el-form-item label="充值积分:">
        			<el-input style="width: 250rem;" type='number' v-model="scoreQuery.money" placeholder="请输入充值积分">
        			</el-input>
        		</el-form-item>
        
        		<el-form-item label="充值说明:">
        			<el-input :rows='5' type="textarea" v-model="scoreQuery.msg" placeholder='请输入充值说明'></el-input>
        		</el-form-item>
        
        	</el-form>
        
        	<span slot="footer" class="dialog-footer">
        		<el-button @click="isScore = false" size='small'>取 消</el-button>
        		<el-button type="primary" @click="scoreUpSub" size='small'>确 定</el-button>
        	</span>
        </el-dialog>

		<!-- 会员卡列表 -->
		<el-dialog title="会员卡" :visible.sync="card" width="60%">
			<el-table :data="cardList" size='mini' height="400rem">
				<el-table-column property="id" label="id" align='center' width='80'></el-table-column>
				<el-table-column width="150" property="card_name" label="会员卡名称" align='center'>
				</el-table-column>

				<el-table-column width="200" label="会员卡卡号" align='center'>
					<template slot-scope="scope">
						<span>{{scope.row.card_no ? scope.row.card_no : '暂未开通'}}</span>
					</template>
				</el-table-column>

				<el-table-column label="余额" align='center'>
					<template slot-scope="scope">
						{{scope.row.money ? scope.row.money : '0.00'}}
					</template>
				</el-table-column>

				<el-table-column label="类型" align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">立减卡</span>
						<span v-else>打折卡</span>
					</template>
				</el-table-column>
				<el-table-column label="折扣" align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">{{scope.row.scale}}元</span>
						<span v-else>{{scope.row.scale}}折</span>
					</template>
				</el-table-column>
				<el-table-column label="开通时间" align='center' width='150'>
					<template slot-scope="scope">
						<span>{{scope.row.get_time ? $func.formatTime(scope.row.get_time) :'暂未开通' }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align='center' fixed="right">
					<template slot-scope="scope">

						<el-button v-if="scope.row.card_no" circle type="primary" icon="el-icon-s-finance" size='mini'
							@click="upCard(scope.row)">
						</el-button>


						<el-popconfirm title="确定开通此会员卡吗？" v-else @confirm='openCard(scope.row.id)'>
							<el-button slot="reference" circle type="primary" icon="el-icon-s-claim" size='mini'>
							</el-button>
						</el-popconfirm>

						<!-- <el-button 
							@click="upCard(scope.row)">
						</el-button> -->

					</template>
				</el-table-column>

			</el-table>
		</el-dialog>

		<!-- 会员卡充值 -->
		<el-dialog title="会员卡充值" :visible.sync="upCardStatus" width="40%">
			<el-form ref="form" :model="cardItem" label-width="240rem" size='small'>

				<el-form-item label="会员卡名称:">
					{{cardItem.card_name}}
				</el-form-item>

				<el-form-item label="会员卡卡号:">
					{{cardItem.card_no}}
				</el-form-item>

				<el-form-item label="充值金额:">
					<el-input style="width: 250rem;" type='number' v-model="cardInfo.money" placeholder="请输入充值金额">
					</el-input>
					<div class="tip">
						提示：此卡充值金额最低为{{cardItem.min_renew_price}}元！
					</div>
				</el-form-item>

				<el-form-item label="充值说明:">
					<el-input :rows='5' type="textarea" v-model="cardInfo.msg" placeholder='请输入充值说明'></el-input>
				</el-form-item>

			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="upCardStatus = false" size='small'>取 消</el-button>
				<el-button type="primary" :loading="loading" @click="upSubmit" size='small'>确 定</el-button>
			</span>
		</el-dialog>
		
		<!-- 获取会员信息状态 -->
		<div class="memberStatus" v-if="isMemberStatus">
			<span class="el-icon-loading"></span>
			<span class="">正在获取会员信息...</span>
		</div>
		
		<!-- 消费明细/积分明细 -->
		<el-dialog :title="listTil" :visible.sync="isConDetailList" width="85%">

			<div style="margin-top: 20rem;">
				<baseList :colList.sync="colList" :listData.sync="listData"></baseList>
			</div>
		
		</el-dialog>
		
	</div>
</template>

<script>
	import {mapState,mapMutations} from 'vuex'
	
	import baseList from "@/components/baseList/index.vue"
	import pagination from "@/components/pagination/index.vue"
	import scanBox from "@/components/scanBox"
	
	export default {
		name: "member",
		data() {
			return {
				apiName: "Member",
				keyWord: "",
				isDialog: false,
				status: 1,
				isFoucus: false,
				isDebouce: true,
				timer: null,
				phoneList: [],
				loading: false,
				controlList: [{
						title: "充值",
						desc: "RECHARGE",
						path: "/sessionsList/index"
					},
					{
						title: "扣款",
						desc: "DEDUCTION",
						path: "/deduction/index"
					},
					{
						title: "券包",
						desc: "COUPON BAG",
						path: "/couponbag/index"
					},
					{
						title: "资金",
						desc: "CAPITAL",
						path: "/capital/index"
					}
				],
				activeIndex: 0,
				isShow: false,
				isLogin: false,
				cardList: [],
				card: false,
				userID: 0,
				upCardStatus: false,
				cardItem: {},
				cardInfo: {
					user_id: '',
					money: '',
					msg: '',
					card_no: ''
				},
				isMemberStatus:false,
				loginCode:"",
				queryData:{
					user_id:"",
					page:1,
					limit:10
				},
				isConDetailList:false,
				conDetailList:[],
				colList:[],
				scoreColList:[
					{
						lable: 'ID',
						key: 'id',
						type: 'text',
						width: 80
					},
					{
						lable: '类型',
						key: 'type_name',
						type: 'text'
					},
					{
						lable: '变动积分',
						key: 'money',
						type: 'text'
					},
					{
						lable: '变动前积分',
						key: 'smoney',
						type: 'text',
					},
					{
						lable: '用户变动后积分',
						key: 'tmoney',
						type: 'text'
					},
					{
						lable: '创建时间',
						key: 'create_time',
						type: 'time'
					},
					{
						lable: '变动备注',
						key: 'msg',
						type: 'text',
						width: 80
					}
				],
				listData:[],
				listTil:"消费明细",
				scoreQuery:{
					user_id:"",
					money:"",
					msg:""
				},
				isScore:false,
				conDetailcolList:[
					{
						lable: 'ID',
						key: 'id',
						type: 'text',
						width: 80
					},
					{
						lable: '类型',
						key: 'type',
						type: 'status',
						options: [{
								title: "管理员充值",
								key: "1"
							},
							{
								title: "付呗充值",
								key: "2"
							},
							{
								title: "会员卡消费",
								key: "10"
							}
						]
					},
					{
						lable: '变动金额',
						key: 'money',
						type: 'text',
						width: 80
					},
					{
						lable: '变动前余额',
						key: 'smoney',
						type: 'text',
					},
					{
						lable: '用户变动后金额',
						key: 'tmoney',
						type: 'text'
					},
					{
						lable: '创建时间',
						key: 'create_time',
						type: 'time'
					},
					{
						lable: '变动备注',
						key: 'msg',
						type: 'text',
						width: 80
					},
					{
						lable: '会员卡卡号',
						key: 'card_no',
						type: 'text'
					}
				]
			}
		},
		components: {
			baseList,
			pagination,
			scanBox
		},
		filters: {
			formatTime(val) {
				var date = new Date(val * 1000);

				var year = date.getFullYear();


				var month = (date.getMonth() + 1 < 10) ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1;

				var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
				
				var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
				
				var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
				
				var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();

				return year + '-' + month + '-' + day + ' ' + h + ':' + m + ':' + s;
			}
		},
		watch: {
			keyWord(newVal, oldVal) {
				// console.log("newVal",newVal);
				this.debounce(this.phoneSearch, 1000);
			}
		},
		created() {
			
			console.log("this",this);

			if (localStorage.getItem("userInfo")) {
				this.initUserInfo();
				// this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
				this.isLogin = true;
			}

			this.$nextTick(() => {
				this.isShow = true;
			})

		},
		computed: {
			...mapState({
				userInfo:state=>state.user.userInfo
			}),
			comList() {
				var list = ['recharge', 'deduction', 'couponBag', 'capital'];
				var component = "";
				list.forEach((item, index) => {
					if (this.activeIndex == index) {
						component = item;
					}
				})
				return component;
			}
		},
		methods: {
			...mapMutations(["saveUserInfo","initUserInfo","clearUserInfo"]),
			liHandle(item) {
				this.isMemberStatus = true;
				
				//会员登录
				this.$api.apiInterface("Member", "idLogin").idLogin({
					user_id: item.user_id
				}, res => {
					setTimeout(()=>{
					   this.isMemberStatus = false;
					},1000)
					
					if (res.code == 0) {
						this.$message({
							type: "success",
							message: '登录成功'
						})
			
						var userInfo = res.data;
			            
						this.saveUserInfo(userInfo);
			            
						this.isLogin = true;
						
						return;
					}
					this.$message({
						type: "warning",
						message: res.msg
					})
				})
				
			},
			//开通会员卡
			openCard(val){
				
				this.$api.apiInterface("user", 'openCard').openCard({
					user_id:this.userInfo.id,
					card_id:val
				}, res => {
					this.loading = false
					if (res.code == 0) {
						
						this.$message.success({
							message: '恭喜你，开通成功',
							type: 'success'
						});
						
						setTimeout(()=>{
							
							this.$confirm('会员卡开通成功，需要重新登录会员?', '提示', {
							    confirmButtonText: '确定',
								showCancelButton:false,
							    type: 'success'
							}).then(() => {
							    this.loginOut();
							})
							
						},500)
						
						this.card = false;
					} else {
						this.$message.error(res.msg);
					}
				})
				
			},
			scanCode(){
				this.$refs.scanBox.show();
			},
			//小白盒扫码登录
			memberLogin(code) {
			
				this.$api.apiInterface(this.apiName, "scanLogin").scanLogin({
					code
				}, res => {
					this.$refs.scanBox.hide();
					
					if (res.code == 0) {
						this.$message({
							type: "success",
							message: '登录成功'
						})
								
						var userInfo = res.data;
					    
						this.saveUserInfo(userInfo);
					    
						this.isLogin = true;
						
						return;
					}
					this.$message({
						type: "warning",
						message: res.msg
					})
			
				})
			
			},
			//防抖
			debounce(fn, wait) {
				if (this.timer !== null) {
					clearTimeout(this.timer)
				}
				this.timer = setTimeout(fn, wait);
			},
			//手机号查询
			phoneSearch() {

				this.$api.apiInterface(this.apiName, "searchUser").searchUser({
					mobile: this.keyWord
				}, res => {
					console.log("-----", res);
					if (res.code == 0) {
						this.phoneList = res.data;
					}
				})
				
			},
			scoreUpSub(){
				this.scoreQuery.user_id = this.userInfo.id;
				
				this.$api.apiInterface('User', 'rechargeIntegra').rechargeIntegra(this.scoreQuery, res => {
					this.loading = false
					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，充值成功',
							type: 'success'
						});
						
						setTimeout(()=>{
							
							this.$confirm('充值成功，需要重新登录会员?', '提示', {
							    confirmButtonText: '确定',
								showCancelButton:false,
							    type: 'success'
							}).then(() => {
							    this.loginOut();
							})
							
						},500)
						
						this.isScore = false;
						
					} else {
						this.$message.error(res.msg);
					}
				})
				
			},
			//充值
			upSubmit() {

				if (+this.cardInfo.money < +this.cardItem.min_renew_price) {
					this.$message.error('未达到此卡最低充值金额，请重新充值');
					this.cardInfo.money = ''
					return
				}

				this.loading = true

				this.cardInfo.user_id = this.userID
				this.cardInfo.card_no = this.cardItem.card_no


				this.$api.apiInterface('user', 'recharge').recharge(this.cardInfo, res => {
					this.loading = false
					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，充值成功',
							type: 'success'
						});
						
						setTimeout(()=>{
							
							this.$confirm('充值成功，需要重新登录会员?', '提示', {
							    confirmButtonText: '确定',
								showCancelButton:false,
							    type: 'success'
							}).then(() => {
							    this.loginOut();
							})
							
						},500)
						
						this.card = false;
						this.upCardStatus = false;
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			//充值会员卡弹窗
			upCard(val) {
				this.upCardStatus = true
				this.cardItem = val
				this.cardInfo = {
					user_id: '',
					money: '',
					msg: '',
					card_no: ''
				}
			},
			//积分明细
			getScoreList(){
				this.listTil="积分明细";
				this.colList = this.scoreColList;
				
				this.queryData.user_id = this.userInfo.id;
				this.isConDetailList = true;
				
				this.$api.apiInterface('User', 'integralLog').integralLog(this.queryData, res => {
					
					if (res.code == 0) {
						
						console.log("res",res);
						this.listData = res.data;
			
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			//消费明细
			conDetails(){
				this.listTil="消费明细";
				this.colList = this.conDetailcolList;
				
				this.queryData.user_id = this.userInfo.id;
				this.isConDetailList = true;
				
				this.$api.apiInterface('User', 'balanceLog').balanceLog(this.queryData, res => {
					
					if (res.code == 0) {
						
						console.log("res",res);
						this.listData = res.data;

					} else {
						this.$message.error(res.msg);
					}
				})
			},
			//会员卡
			cardBtn() {
				console.log("this.userInfo",this.userInfo);
				
				this.cardList = this.userInfo.card_list;

				this.userID = this.userInfo.user_id;
				this.card = true;

				this.cardList = this.userInfo.card_list;
			},
			topNavHandle(item, index) {

				this.activeIndex = index;

				this.$router.push({
					path: item.path
				})

			},
			loginOut() {
				
				this.clearUserInfo();

				this.isLogin = false;

				// this.$router.push({
				// 	path:"/memberLogin/index"
				// })
			}
		}
	}
</script>

<style scoped lang="less">
	.closeBtn{
		font-size: 40rem;
		position: absolute;
		top:0;
		right: 20rem;
		cursor: pointer;
		z-index: 999;
	}
	
	.loginOut {
		width: 200rem;
		height: 50rem;
		line-height: 50rem;
		font-size: 22rem;
		background: #FFE300;
		text-align: center;
		margin: 0 auto;
		border-radius: 10rem;
		cursor: pointer;
		user-select: none;
		position: absolute;
		bottom: 15rem;
		left: 50%;
		transform: translateX(-50%);
	}

	.recharge {
		width: 200rem;
		height: 50rem;
		line-height: 50rem;
		font-size: 22rem;
		background: #f60;
		text-align: center;
		margin: 0 auto;
		border-radius: 10rem;
		cursor: pointer;
		user-select: none;
		color: #fff;
	}

	.page {
		width: 1855rem;
		padding: 30rem 60rem 20rem 34rem;
		box-sizing: border-box;
		display: flex;

		.memberInfo {
			width: 362rem;
			height: 99.6%;
			background: #fff;
			border-radius: 10rem;
			padding-bottom: 45rem;
			position: relative;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

			.memberAvatar {
				display: flex;
				flex-direction: column;
				align-items: center;

				img {
					width: 60rem;
					height: 60rem;
					border-radius: 50%;
					margin: 31rem 0 18rem;
				}

				span {
					font-size: 20rem;
				}
			}

			.info {
				margin-top: 40rem;
				padding: 0 42rem;

				ul {
					li {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 26rem;

						.title {
							font-size: 16rem;
							color: #828282;
						}

						.txt {
							font-size: 18rem;
							color: #282828;
						}
					}
				}
			}

		}

		.controlArea {
			margin-bottom: 34rem;
			margin-left: 30rem;

			ul {
				display: flex;
				// justify-content: space-between;

				li {
					width: 300rem;
					height: 90rem;
					border-radius: 10rem;
					background: #fff;
					font-size: 22rem;
					color: #fff;
					padding-left: 70rem;
					padding-top: 18rem;
					padding-right: 28rem;
					box-sizing: border-box;
					cursor: pointer;
					user-select: none;
					position: relative;
					display: flex;
					justify-content: space-between;
					transition: all .3s;
					// transform-origin: center;
					margin-right: 24rem;

					.go {
						width: 43rem;
						height: 21rem;
						background: rgba(255, 255, 255, .5);
						border-radius: 15rem;
						text-align: center;
						line-height: 21rem;
						font-size: 14rem;
						color: #fff;
						margin-top: 10rem;
						position: absolute;
						top: 12rem;
						right: 20rem;
					}
				}
			}

			ul>li:nth-child(1) {
				background: url("../../static/tabBg2.png") no-repeat;
				background-size: cover;
			}

			ul>li:nth-child(2) {
				background: url("../../static/tabBg1.png") no-repeat;
				background-size: cover;
			}

			ul>li:nth-child(3) {
				background: url("../../static/tabBg3.png") no-repeat;
				background-size: cover;
			}

			ul>li:nth-child(4) {
				background: url("../../static/tabBg4.png") no-repeat;
				background-size: cover;
			}
		}

		.control {
			flex: 1;
			display: flex;
			flex-direction: column;
		}

		.controlCon {
			width: 1438rem;
			height: 100%;
			// padding: 0rem 0rem 0rem 30rem;
		}
	}


	.img {
		width: 72rem;
		height: 72rem;
		background: #282828;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 31rem 0 17rem;
		cursor: pointer;

		img {
			width: 38rem;
			height: 38rem;
		}
	}

	.search {
		margin-top: 36rem;

		p {
			color: #828282;
			font-size: 18rem;
			margin-bottom: 17rem;
		}

		.input {
			display: flex;
			position: relative;

			input {
				width: 194rem;
				height: 43rem;
				border: 1rem solid #c9c9c9;
				border-radius: 5rem;
				box-sizing: border-box;
				padding-left: 10rem;
			}

			.searchBtn {
				width: 82rem;
				height: 43rem;
				background: #FFE300;
				color: #282828;
				text-align: center;
				line-height: 43rem;
				font-size: 18rem;
				border-radius: 5rem;
				cursor: pointer;
				user-select: none;
				margin-left: 17rem;
			}

			.result {
				width: 194rem;
				// height: 150rem;
				background: #F7F7F7;
				position: absolute;
				top: 50rem;
				left: 0;
				border-radius: 5rem;
				padding-top: 20rem;
				box-sizing: border-box;

				ul {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				li {
					color: #828282;
					font-size: 20rem;
					cursor: pointer;
					margin-bottom: 10rem;

					img {
						width: 13rem;
						height: 16rem;
						margin-left: 16rem;
					}
				}
			}
		}
	}
	
	.memberStatus{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, .5);
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 48rem;
	}
	
	.dialog {
		width: 1000rem;
		height: 800rem;
		background: #fff;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-40%, -50%);
		z-index: 100;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10rem;
		padding: 25rem 25rem;
		box-sizing: border-box;
		box-sizing: 25rem 25rem 25rem 25rem #333;
	
		.boxInput {
			display: inline-block;
			width: 100%;
			height: 100%;
			border: 5rem solid #333;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 10rem;
			color: #fff;
			opacity:0;
		}
	
		.scanLogin {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 99;
			transform: translate(-50%, -50%);
			pointer-events: none;
			overflow: initial;
	
			p {
				width: 250rem;
				font-size: 32rem;
				color: #282828;
				text-align: center;
				line-height: 50rem;
			}
		}
	}
	
	.mask {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, .5);
		z-index: 99;
	}
</style>
