import config from '../config/config'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Page404 from '../views/404'
import noAuth from '../views/auth/noAuth'
import noAuth2 from '../views/auth/noAuth2'

Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

export const routes = []

//子路由
const childRoute = [];

// 自动读取views文件夹下的所有vue文件
const files = require.context('../views', true, /\.vue$/);
files.keys().map((item) => {

	let path = item.slice(1).replace(".vue", "").toLowerCase();
	const comp = files(item).default;

	var name = comp.name;

	//路由name
	// if(name && name.indexOf('_')!=-1){
	// 	name = name.slice(name.indexOf('_')+1);
	// }

	var routesItem = {
		path,
		name,
		component: comp,
		children: [],
		meta: {
			title: comp.menuName ? comp.menuName : comp.name
		},
		title: comp.menuName ? comp.menuName : comp.name,
		redirect: ""
	}


	if (comp.name == 'index' || comp.name == 'login' || comp.name == 'layout' || comp.name == 'member') {

		if (comp.name == 'member') {
			routesItem.redirect = "/sessionsList/index";
		}

		routes.push(routesItem);

	} else if (comp.name) {

		childRoute.push(routesItem);

	}

});


//添加子路由配置
routes.forEach(arrItem => {
	
	if(arrItem.name == 'member'){
		arrItem.children.push({
			path: '/auth/noAuth',
			name: 'noAuth',
			component: noAuth,
		});
	}else if(arrItem.name == 'index'){
		arrItem.children.push({
			path: '/auth/noAuth2',
			name: 'noAuth2',
			component: noAuth2,
		});
	}
	
	childRoute.forEach(childItem => {

		if (childItem.name.indexOf(arrItem.name) != -1) {

			arrItem.children.push(childItem);

		}
		// else{

		// 	arrItem.children.forEach(childItem=>{

		// 		//上级路由name
		// 		let name = childItem.name.slice(childItem.name.indexOf('_')+1);

		// 		if(comp.name.indexOf(name) != -1){

		// 			childItem.children.push(routesItem);
		// 		}

		// 	})

		// }
	})

})

// 匹配 / 路由
routes.unshift({
	path: '/',
	name: 'home',
	redirect: '/index/index',
})
// 当匹配不到路由时，匹配一个404页面
routes.push({
	path: '*',
	name: 'Error',
	component: Page404,
})

//缓存路由配置
localStorage.setItem("routesArr", JSON.stringify(routes));

const router = new VueRouter({
	mode: config.router.routerMode,
	scrollBehavior: () => ({
		y: 0 // 始终滚动到顶部
	}),
	routes: routes
})

//路由守卫
// router.beforeEach((to, from, next) => {

// 	if (to.path == '/login/login') {
// 		if (localStorage.getItem('token')) {
// 			next('/index/index');
// 		}
// 		next();
// 	} else {
// 		if (!localStorage.getItem('token')) {
// 			next('/login/login');
// 		}
// 		next();
// 	}

// })

export default router
