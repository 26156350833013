import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import RouterPage from './modules/routerPage.js'
import token from './modules/token.js'
import PerManage from './modules/perManage.js'
import user from './modules/user.js'

export default new Vuex.Store({
	modules: {
		RouterPage,
		token,
		PerManage,
		user
	}
})
