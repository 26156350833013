<template>
	<div>
		<div class="topNav">
			<div style="display: flex;align-items: center;" @click="roomBtn(1)">
				<img src="../../static/addIcon.png" alt="">
				<span>添加房间</span>
			</div>
		</div>
		<el-card>
			<div class="status">
				<span class="icon"></span>
				<span style="font-size: 18rem;color: #666666;">房间</span>
			</div>
			
			<el-table :data="roomList" style="width: 100%" v-loading = 'load'>
				<el-table-column prop="id" sortable label="房间号" align='center'>
				</el-table-column>
				<el-table-column prop="room_name"  label="房间名称" align='center'>
				</el-table-column>
				<el-table-column prop="update_time"  label="创建时间" align='center'>
				</el-table-column>
				<el-table-column label="操作" align='center' fixed="right" width='100'>
					<template slot-scope="scope">
				
						<el-button circle type="primary" icon="el-icon-edit" size='mini' @click="roomBtn(2,scope.row)"
							style="margin-right: 10rem;">
						</el-button>
				
						<!-- <el-popconfirm confirm-button-text='确定' cancel-button-text='不用了' icon="el-icon-info"
							icon-color="red" :title="`确定删除${scope.row.title}商品？`" @confirm='del(scope.row.id)'>
							<el-button type="danger" slot="reference" circle icon="el-icon-delete" size='mini'>
							</el-button>
						</el-popconfirm> -->
					</template>
				</el-table-column>
			</el-table>
			
			<div style="text-align: center;">
				<el-pagination @current-change="handleCurrentChange" :page-size="10"
					layout="prev, pager, next,jumper,->,total" :total="+roomTotal">
				</el-pagination>
			</div>

		</el-card>
	
		<!-- 添加，修改-->
		<el-dialog :title="title" :visible.sync="roomType" width="40%">
			<el-form ref="form" :model="form" label-width="120px" size='mini'>
				<el-form-item label="房间名称:">
					<el-input class="inputW"  v-model="form.room_name" placeholder="请输入房间名称"></el-input>
				</el-form-item>
			</el-form>
			
			<span slot="footer" class="dialog-footer">
				<el-button @click="roomType = false" size='small'>取 消</el-button>
				<el-button type="primary" :loading="loading" @click="submit" size='small'>确 定</el-button>
			</span>
		</el-dialog>
		
		
	</div>
</template>

<script>
	export default {
		name: "index_room",
		menuName: "房间列表",
		data() {
			return {
				apiName:'Room',
				page:1,
				load: false,
				loading:false,
				roomList: [],
				roomTotal:'',
				title:'',
				roomType:false,
				form:{},
				
				
			}
		},
		inject: ["layout"],
		created() {
			this.getRoomList()
		},
		methods: {
			//获取房间列表
			getRoomList(){
				this.load = true
				this.$api.getList(this.apiName, {page:this.page,limit:10}, (res) => {
					this.load = false
					if (res.code == 0) {
						this.roomTotal = res.data.count
						this.roomList = res.data.list
						
						this.roomList.forEach(item => {
							item.update_time = this.$func.formatTime(item.update_time)
							return item
						})
						
						
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			handleCurrentChange(val){
				this.page = val
				this.getRoomList()
			},
			
			//修改，添加房间
			roomBtn(val,item){
				this.roomType = true
				if(val == 1){
					this.title = '新增房间'
					this.form = {}
				}else {
					this.form.id = JSON.parse(JSON.stringify(item.id))
					this.form.room_name = JSON.parse(JSON.stringify(item.room_name))
					this.title = '修改房间'
				}
			},
			submit(){
				console.log(this.form);
				this.load = true
				this.$api.listEdit(this.apiName, this.form, (res) => {
					this.load = false
					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，操作成功',
							type: 'success'
						});
						this.roomType = false
						this.getRoomList()
					} else {
						this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.topNav {
		display: flex;
		font-size: 16rem;
		color: #409EFF;
		align-items: center;
		margin-bottom: 20rem;
		margin-left: 25rem;
		cursor: pointer;
		user-select: none;

		img {
			width: 26rem;
			height: 26rem;
			margin-right: 12rem;
		}
	}
	
	.bookImg{
		width: 110rem;
		height: 148rem;
	}
	.DMImg{
		width: 50rem;
		height: 50rem;
	}
	
	.inputW {
		width: 300rem;
	}
	
	.liFlex {
		display: flex;
	
		justify-content: space-between;
	}
	.status {
		margin-bottom: 15rem;

		.icon {
			display: inline-block;
			width: 13rem;
			height: 13rem;
			background: #FFE300;
			border-radius: 50%;
			margin-right: 10rem;
		}
	}
	
	.tip {
		font-size: 12px;
		color: #C0C4CC;
	
		margin-left: 30rem;
	}
</style>
