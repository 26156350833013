<template>
	<div>
		
		<template v-if="imgObj">
			
			<template v-if="imgObj.imgUrl!='' && imgObj.percent == 100">
				<el-image style="width: 120px; height: 120px" :src="imgObj.imgUrl" :preview-src-list="[imgObj.imgUrl]">
				</el-image>
			</template>
			
			<template v-else>
				<el-progress type="circle" :percentage="imgObj.percent"></el-progress>
			</template>
			
		</template>
		
		<template v-else>
			<el-upload list-type="picture-card" :http-request="my_upload"
				:action="$config.baseURL + '/User/upload'" :show-file-list="false"
				:on-error="handleAvatarError"
				:before-upload="beforeAvatarUpload">
				
				<i  class="el-icon-plus avatar-uploader-icon"></i>
				
			</el-upload>
		</template>

	</div>
</template>

<script>
	export default {
		props: {
			initData: String,
			keys: String,
			uploadImg: Function
		},
		data() {
			return {
				options: {
					token: localStorage.getItem("token")
				},
				optionsObj: {
					image: ""
				},
				imgObj:"",
				percent:0
			}
		},
		watch: {
			initData(newVal, oldVal) {
				// console.log(newVal);
				this.imgUrl = newVal;
			}
		},
		created() {

			this.imgUrl = this.initData;

		},
		methods: {
			handleAvatarError(res) {
				
				console.log(res);
			},
			beforeAvatarUpload(file) {
				// console.log(file);
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
					return isLt2M;
				} else {
					this.optionsObj.image = file;
				}
			},
			my_upload(uploader) {
				console.log("-------",uploader);
			
				let form = new FormData();
				form.append('file', uploader.file);
				this.$api.CommonUpload(form, res => {
			        
					// console.log("result",res);
					
					this.imgObj = res;
					
					console.log("res", this.imgObj);
			
				})
				
			}
		}
	}
</script>

<style scoped lang="less">
	/deep/ .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	
	/deep/ .el-upload--picture-card{
		width: 120px !important;
		height: 120px !important;
		line-height: 120px !important;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
		border-radius: 6px;
		margin: 0 auto;
	}

	.upImg {
		display: none;
	}

	.avatar-uploader .el-upload {
		border: 1px solid #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}
	
	.avatar-uploader-icon {
		font-size: 24px;
		color: #8c939d;
		width: 120px;
		height: 120px;
		line-height: 120px;
		text-align: center;
	}

	.avatar {
		width: 80px;
		height: 80px;
		display: block;
	}
</style>
