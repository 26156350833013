import $request from "../request.js";
import axios from 'axios';

export default{
	//列表
	getList(apiName,data = {}, ret = () => {}) {
		$request.request({
			url: `/store/${apiName}/getList`,
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	//列表删除
	listDel(apiName,data = {}, ret = () => {}) {
		$request.request({
			url: `/store/${apiName}/del`,
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	//列表编辑
	listEdit(apiName,data = {}, ret = () => {}) {
		$request.request({
			url: `/store/${apiName}/edit`,
			method: 'post',
			data: data
		}).then(res => {
			ret(res)
		})
	},
	//自定义接口(特殊接口)
	apiInterface(apiName,interName){
		var interObj = {}
		
		interObj[interName] = function(data = {}, ret = () => {}){
			$request.request({
				url: `/store/${apiName}/${interName}`,
				method: 'post',
				data: data
			}).then(res => {
				ret(res)
			})
		}
		
		return interObj;
	}
}