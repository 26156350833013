<template>
	<div style="margin-left: 30rem;height: 94%;">

		<div class="topNav">
			<div style="display: flex;align-items: center;" @click="SessionBtn(0)">
				<img src="../../static/addIcon.png" alt="">
				<span>添加场次</span>
			</div>
		</div>
		<el-card style="height: 100%;position: relative;">
			<div style="display:flex;align-items: center;justify-content: space-between;">
				<div>
					<el-button v-if="!isForm" type="warning" size="mini" class="el-icon-arrow-left" @click="backPage">返回
					</el-button>
					
					<div class="status" style="margin-top: 20rem;">
						<span class="icon"></span>
						<span style="font-size: 18rem;color: #666666;">场次</span>
					</div>
				</div>
				
				<span v-if="!isForm" class="refresh el-icon-refresh" @click="refresh"></span>
			</div>

			<template v-if="isForm">

				<el-table :data="sessionList" style="width: 100%" v-loading='load'>
					<el-table-column prop="id" sortable label="id" align='center'>
					</el-table-column>

					<el-table-column label="剧本名称" align='center' width="130">
						<template slot-scope="scope">
							<span>{{scope.row.book_info.title}}</span>
						</template>
					</el-table-column>

					<!-- <el-table-column  label="剧本图" align='center' width="130">
					<template slot-scope="scope">
						<img class="bookImg" :src="scope.row.book_info.headPic" alt="">
					</template>
				</el-table-column> -->

					<el-table-column label="剧本单价" align='center'>
						<template slot-scope="scope">
							{{scope.row.play_money}}元
						</template>
					</el-table-column>

					<el-table-column label="DM" align='center'>
						<template slot-scope="scope">
							<el-popover placement="bottom" width="500" trigger="click">
								<el-table :data="scope.row.dms" style="width: 100%" size='mini'>
									<el-table-column prop="user_id" label="id" align='center'>
									</el-table-column>
									<el-table-column label="职业" align='center'>
										<template slot-scope="scope">
											<span v-if="scope.row.type == 1">主D</span>
											<span v-if="scope.row.type == 2">演绎</span>
										</template>
									</el-table-column>
									<el-table-column prop="nikename" label="DM昵称" align='center'>
									</el-table-column>
									<el-table-column label="DM头像" align='center'>
										<template slot-scope="scope">
											<img class="DMImg" :src="scope.row.avatar" alt="">
										</template>
									</el-table-column>
									<el-table-column prop="mobile" label="DM手机号" align='center'>
									</el-table-column>

								</el-table>


								<el-button slot="reference" style="background-color: #FFE300 ;border: none;" circle
									size='mini' icon='el-icon-user'></el-button>
							</el-popover>
						</template>

					</el-table-column>

					<el-table-column label="剧本人数" width="100" align='center'>
						<template slot-scope="scope">
							{{Math.trunc(scope.row.play_member)}}人
						</template>
					</el-table-column>

					<el-table-column prop="room_info.room_name" label="房间号" width="100" align='center'>
						<template slot-scope="scope">
							<span v-if="scope.row.room_info">{{scope.row.room_info.room_name}}</span>
							<span v-else>无</span>
						</template>
					</el-table-column>

					<!-- <el-table-column prop="pay_fubei" label="付呗支付" width="100" align='center'>
					</el-table-column>

					<el-table-column prop="pay_balance" label="会员支付" width="100" align='center'>
					</el-table-column>

					<el-table-column prop="total_pay" label="总计收款" width="100" align='center'>
					</el-table-column> -->

					<el-table-column label="备注" align='center'>
						<template slot-scope="scope">
							<el-popover placement="bottom" width="400rem" trigger="click">
								<div style="width: 400rem;">
									{{scope.row.remake}}
								</div>
								<el-button slot="reference" style="background-color: #FFE300 ;border: none;" circle
									size='mini' icon='el-icon-document'></el-button>
							</el-popover>

						</template>
					</el-table-column>
					<el-table-column prop="start_time" label="开场时间" width="180" align='center'>
					</el-table-column>
					<el-table-column prop="end_time" label="散场时间" width="180" align='center'>
						<template slot-scope="scope">
							<span v-if="scope.row.end_time">{{scope.row.end_time}}</span>
							<span v-else>无</span>
						</template>
					</el-table-column>
					<el-table-column label="场次状态" align='center' width="110">
						<template slot-scope="scope">
							<span v-if="scope.row.status == 0">未完成</span>
							<span v-else-if="scope.row.status">已完成</span>
							<span v-else>已确认</span>
							
							<!-- <el-select @change="statusHandle(scope.row)" size="mini" v-model="scope.row.status"
								placeholder="场次状态">
								<el-option v-for="item in statusList" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select> -->
						</template>
					</el-table-column>
					<el-table-column label="操作" align='center' fixed="right" width='200'>
						<template slot-scope="scope">

							<el-button circle type="primary" icon="el-icon-edit" size='mini'
								@click="SessionBtn(1,scope.row)" style="margin-right: 10rem;">
							</el-button>

							<el-button circle type="warning" icon="el-icon-view" size='mini'
								@click="checkSession(scope.row)" style="margin-right: 10rem;">
							</el-button>

							<!-- <el-popconfirm confirm-button-text='确定' cancel-button-text='不用了' icon="el-icon-info"
							icon-color="red" :title="`确定删除${scope.row.title}商品？`" @confirm='del(scope.row.id)'>
							<el-button type="danger" slot="reference" circle icon="el-icon-delete" size='mini'>
							</el-button>
						</el-popconfirm> -->

						</template>
					</el-table-column>
				</el-table>

				<div style="text-align: center;position: absolute;bottom: 20rem;left: 50%;transform: translateX(-50%);">
					<el-pagination @current-change="handleCurrentChange" :page-size="5"
						layout="prev, pager, next,jumper,->,total" :total="+pageCount">
					</el-pagination>
				</div>

			</template>

			<!-- 查看场次 -->
			<sessionsForm ref="sessionsForms" :formData.sync="formData" v-else></sessionsForm>

		</el-card>

		<!-- 添加。修改 -->
		<el-dialog :title="title" :visible.sync="sessionAction" width="60%">
			<el-form ref="form" :model="sessionForm" label-width="120px" size='mini'>
				<ul>
					<li class="liFlex">

						<el-form-item label="场次剧本:">
							<el-button style="margin-right: 30rem;background-color: #FFE300 ;border: none;color: black;"
								@click="getBook"> 选择剧本
							</el-button>

							<el-tag size="small" v-if="sessionForm.book_id" closable :disable-transitions="false"
								@close="handleBookClose()">
								{{bookName}}
							</el-tag>
						</el-form-item>

					</li>

					<li class="liFlex">
						<el-form-item label="房间号:">

							<el-select v-model="sessionForm.room_id" placeholder="请选择房间号" class="inputW">
								<el-option v-for="item in options" :key="item.id" :label="item.room_name"
									:value="item.id">
								</el-option>
							</el-select>

						</el-form-item>

					</li>

					<li class="liFlex">
						<el-form-item label="开场时间:">

							<el-date-picker class="inputW" v-model="start_time" type="datetime" placeholder="选择开场时间">
							</el-date-picker>

						</el-form-item>

					</li>


					<li class="liFlex">
						<el-form-item label="DM:">
							<template v-if="dmInfo.length > 0">

								<div style="display: flex;">
									<div class="dm_else" v-for="(item,index) in dmInfo" :key="index"
										@click="setDm(item,index)">
										<img v-if="item.avatar" style="width: 50rem;height: 50rem;border-radius: 50%;"
											:src="item.avatar" alt="">
										<span v-else class="avatar">+</span>
										<span
											style="color: #333;font-size: 18rem;">{{item.type == 1 ? (item.name || "主DM") : item.name }}<span
												v-if="item.money!=''">({{item.money}})</span></span>
									</div>
								</div>
								
							</template>

							<div class="dm_else" v-else @click="setDm('DM')">
								<img v-if="dmInfo.avatar" style="width: 50rem;height: 50rem;border-radius: 50%;"
									:src="dmInfo.avatar" alt="">
								<span v-else class="avatar">+</span>
								<span
									style="color: #333;font-size: 18rem;">{{!dmInfo.hasOwnProperty('length') && dmInfo.id!='' ? dmInfo.name : '主DM'}}</span>
							</div>

						</el-form-item>

					</li>

					<li class="liFlex">
						<el-form-item label="剧本单价:">
							<el-input class="inputW" type='number' v-model="sessionForm.play_money"
								placeholder="请输入剧本单价"></el-input>
						</el-form-item>

						<el-form-item label="剧本人数:">
							<el-input class="inputW" type='number' v-model="sessionForm.play_member"
								placeholder="请输入剧本人数"></el-input>
						</el-form-item>
					</li>

					<li class="liFlex">
						<el-form-item label="备注:">
							<el-input style="width: 800rem;" :rows=3 type="textarea" v-model="sessionForm.remake"
								placeholder="请输入备注"></el-input>
						</el-form-item>

					</li>
					<li class="liFlex" v-if="!shopInfo.store_id">
						<el-form-item label="场次状态:">
							<el-radio-group v-model="sessionForm.status">
								<el-radio :label='0'> 未完成</el-radio>
								<el-radio :label="1"> 已完成</el-radio>
								<el-radio :label="2"> 已确认</el-radio>
							</el-radio-group>
						</el-form-item>
					</li>

				</ul>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="sessionAction = false" size='small'>取 消</el-button>
				<el-button type="primary" :loading="loading" @click="submit" size='small'>确 定</el-button>
			</span>
		</el-dialog>


		<!-- 选择剧本-->
		<el-dialog title="选择剧本" :visible.sync="bookAdd" width="50%">
			<div style="margin-bottom: 30rem;display: flex;align-items: center;justify-content: space-between;">
				<div>
					<el-input style="width: 200rem;" v-model="book_title" placeholder="请填写剧本名称" size='mini'></el-input>
				</div>

				<div>
					发售类型：
					<el-select style="width: 120rem;" v-model="book_status" placeholder="请选择发售类型" size='mini'>
						<el-option label="全部" value=""></el-option>
						<el-option label="城限" value="城限"></el-option>
						<el-option label="盒装" value="盒装"></el-option>
						<el-option label="独家" value="独家"></el-option>
					</el-select>

				</div>

				<div>
					<el-button style="background-color: #FFE300 ;border:1rem solid #FFE300 ;color: black;"
						icon='el-icon-search' size='mini' @click="getBookList"></el-button>
					<el-button style="background-color: #FFE300 ;border:1rem solid #FFE300 ;color: black;"
						icon='el-icon-refresh' size='mini' @click="getBook"></el-button>
				</div>
			</div>
			<el-table v-loading="load" ref="multipleTable" :data="bookList" tooltip-effect="dark" style="width: 100%"
				size='mini'>
				<el-table-column prop="book_id" sortable label="剧本id"></el-table-column>
				<el-table-column prop="title" label="剧本名称"></el-table-column>
				<el-table-column label="剧本图片">
					<template slot-scope="scope">
						<img class="bookImg" :src="scope.row.headPic" alt="">
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" class="el-icon-position"
							@click="handleSelectionChange(scope.row)">选择剧本</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @current-change="bookhandleCurrentChange" :page-size="4" layout="prev, pager, next, ->,total"
				:total="+bookTotal">
			</el-pagination>
		</el-dialog>

		<!-- 选择dm-->
		<el-dialog title="选择DM" :visible.sync="DMtype" width="60%">
			<el-table v-loading="load" ref="multipleTable" :data="userList" tooltip-effect="dark" style="width: 100%"
				size='mini'>
				<el-table-column prop="user_id" label="id" align='center'>
				</el-table-column>
				<el-table-column label="昵称" align='center' width='120'>
					<template slot-scope="scope">
						<span v-if="scope.row.nikename">{{scope.row.nickname}}</span>
						<span v-else>暂未设置</span>
					</template>
				</el-table-column>
				<el-table-column label="头像" align='center'>
					<template slot-scope="scope">
						<img v-if="scope.row.avatar" class="DMImg" :src="scope.row.avatar" alt="头像">
						<span v-else>暂未设置</span>
					</template>
				</el-table-column>
				<el-table-column prop="mobile" label="手机号" align='center' width='120'>
				</el-table-column>
				<el-table-column label="性别" align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.sex == 1">公子</span>
						<span v-else>仙子</span>
					</template>
				</el-table-column>

				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" class="el-icon-position"
							@click="handleSelectionDMChange(scope.row)">选择DM</el-button>
					</template>
				</el-table-column>

				<!-- <el-table-column label="DM状态" align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.is_dm == 1">胜任</span>
						<span v-else>无为</span>
					</template>
				</el-table-column> -->

			</el-table>
			<div style="text-align: center;">
				<el-pagination @current-change="userhandleCurrentChange" :page-size="8"
					layout="prev, pager, next,->, total" :total="+userTotal">
				</el-pagination>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import sessionsForm from "@/components/sessionsForm"

	export default {
		name: "member_sessionsList",
		menuName: "场次列表",
		data() {
			return {
				apiName: 'Theatre',
				setDmVal: {},
				load: false,
				form: {
					limit: 11,
					page: 1
				},
				pageCount: '',
				sessionList: [],

				//添加，修改
				title: '',
				sessionAction: false,
				sessionForm: {
					book_id: '', //剧本ID
					start_time: '', //开场时间
					end_time: '', //结束时间
					dm_ids: [], //选择DM    type = 1主D 2演绎
					status: 0, //状态 0未完成 1已完成
					play_money: '', //剧本单价
					play_member: '', //实际剧本人数
					remake: '', //备注
					room_id: '0' //0则不记录 采用select
				},
				bookAdd: false,
				book_title: '',
				book_status: '',
				bookName: '',
				bookList: [],
				bookTotal: '',

				start_time: '', //开场时间
				end_time: '', //结束时间
				loading: false,

				options: [],
				DMtype: false,
				userForm: {
					mobile: '',
					is_dm: 1,
					page: 1,
					limit: 8
				},
				userTotal: '',
				userList: [],
				DMList: [],
				type: '',
				dmInfo: [],
				statusList: [{
						label: "未完成",
						value: '0'
					},
					{
						label: "已完成",
						value: '1'
					},
					{
						label: "已确认",
						value: '2'
					}
				],
				isForm: true,
				formData: {},
				shopInfo:{}
			}
		},
		inject: ["layout"],
		created() {
            
			if(localStorage.getItem("shopInfo")){
				this.shopInfo = JSON.parse(localStorage.getItem("shopInfo"));
			}
			
			this.getSession();
			
			this.getRoom();

		},
		provide: {
			"sessions": this
		},
		components: {
			sessionsForm
		},
		methods: {
			refresh(){
				this.$nextTick(()=>{
					this.$refs.sessionsForms.initData();
					this.$refs.sessionsForms.isLoadForm = true;
				})
			},
			//查看场次
			checkSession(val) {

				this.formData = val;
				this.isForm = false;

			},
			backPage() {
				this.isForm = true;
			},
			statusHandle(item) {
				this.DMList = [];

				this.sessionForm = JSON.parse(JSON.stringify(item))
				this.sessionForm.status = Boolean(this.sessionForm.status)
				
				this.bookName = this.sessionForm.book_info.title
				this.start_time = this.sessionForm.start_time

				this.dmInfo = this.sessionForm.dms;

				this.dmInfo.forEach(item => {

					item.name = item.uname;
					item.money = item.umoney;

					this.DMList.push({
						user_id: item.user_id,
						type: item.type,
						uname: item.type == 1 ? "主D" : item.name,
						umoney: item.money
					})
				})

				delete this.sessionForm.book_info
				delete this.sessionForm.create_time
				delete this.sessionForm.dms
				delete this.sessionForm.total_pay
				delete this.sessionForm.end_time
				delete this.sessionForm.update_time
				delete this.sessionForm.store_id
				delete this.sessionForm.pay_balance
				delete this.sessionForm.is_del
				delete this.sessionForm.pay_fubei

				this.submit();

			},
			//获取房间列表
			getRoom() {
				this.$api.getList('Room', {}, (res) => {

					if (res.code == 0) {
						this.options = res.data.list
						this.options.unshift({
							id: '0',
							room_name: '暂无'
						})
					} else {
						// this.$message.error(res.msg);
					}
				})
			},

			//场次列表
			getSession() {
				this.load = true
				this.$api.getList(this.apiName, this.form, (res) => {
					this.layout.loading = false;
					this.load = false
					if (res.code == 0) {
						this.sessionList = res.data.list;
						this.pageCount = res.data.count;
						

						this.sessionList.forEach(item => {
							item.create_time = this.$func.formatTime(item.create_time)
							item.update_time = this.$func.formatTime(item.update_time)
							item.start_time = this.$func.formatTime(item.start_time)
							if (item.end_time) {
								item.end_time = this.$func.formatTime(item.end_time)
							}
							return item
						})


					} else {
						// this.$message.error(res.msg);
					}
				})
			},
			//分页
			handleCurrentChange(val) {
				this.form.page = val

				this.getSession()
			},
			//添加，修改场次
			SessionBtn(val, item) {
				// console.log("item", item);

				this.changeType = val;

				this.sessionAction = true

				this.DMList = [];

				if (val == 1) {

					this.title = '修改场次'
					this.sessionForm = JSON.parse(JSON.stringify(item))

					this.sessionForm.status = +(this.sessionForm.status)
					this.bookName = this.sessionForm.book_info.title
					this.start_time = this.sessionForm.start_time

					this.dmInfo = this.sessionForm.dms;

					this.dmInfo.forEach(item => {

						item.name = item.uname;
						item.money = item.umoney;
						item.avatar = item.avatar || require('../../static/home_huli.png')

						this.DMList.push({
							user_id: item.user_id,
							type: item.type,
							uname: item.type == 1 ? "主D" : item.name,
							umoney: item.money
						})
					})

					delete this.sessionForm.book_info
					delete this.sessionForm.create_time
					delete this.sessionForm.dms
					delete this.sessionForm.total_pay
					delete this.sessionForm.end_time
					delete this.sessionForm.update_time
					delete this.sessionForm.store_id
					delete this.sessionForm.pay_balance
					delete this.sessionForm.is_del
					delete this.sessionForm.pay_fubei

				} else {

					this.title = '添加场次'
					this.sessionForm = {
						book_id: '', //剧本ID
						start_time: '', //开场时间
						end_time: '', //结束时间
						dm_ids: [], //选择DM    type = 1主D 2演绎
						status: 0, //状态 0未完成 1已完成
						play_money: '', //剧本单价
						play_member: '', //实际剧本人数
						remake: '', //备注
						room_id: '0' //0则不记录 采用select
					}
					this.dmInfo = [];

					this.start_time = '';
					this.DMList = [];
					this.bookName = '';
				}
			},
			//剧本列表按钮
			getBook() {
				this.book_status = ''
				this.book_title = ''
				this.bookAdd = true
				this.getBookList()
			},
			getBookList() {
				this.load = true
				this.$api.getBookList({
					page: this.bookpage,
					limit: 4,
					title: this.book_title,
					specification: this.book_status
				}, (res) => {
					this.load = false
					if (res.code == 0) {
						this.bookList = res.data.list
						this.bookTotal = res.data.count
					}
				})
			},
			//book分页
			bookhandleCurrentChange(val) {
				this.load = true
				this.$api.getBookList({
					page: val,
					limit: 4
				}, (res) => {
					this.load = false
					if (res.code == 0) {
						this.bookList = res.data.list
					}
				})
			},
			//保存book选择
			handleSelectionChange(val) {

				this.DMList = [];

				this.bookName = val.title;
				this.sessionForm.book_id = val.book_id;
				this.dmInfo = val.dm_info == "" || !val.dm_info ? [] : JSON.parse(val.dm_info);
				this.sessionForm.play_money = val.price;
				this.sessionForm.play_member = val.maxPeopleNum;

				if (this.dmInfo.length > 0) {
					this.dmInfo.forEach(item => {
						this.DMList.push({
							user_id: item.id,
							type: item.type,
							uname: item.type == 1 ? "主D" : item.name,
							umoney: item.money
						})
					})
				}

				if (this.sessionForm.book_id) {
					this.bookAdd = false
				}
			},
			handleBookClose() {
				this.sessionForm.book_id = ''
				this.bookName = ''
			},
			//添加操作
			addSubmit() {
				this.$api.apiInterface(this.apiName, 'addTheatre').addTheatre(this.sessionForm, res => {
					this.loading = false
					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，操作成功',
							type: 'success'
						});

						this.getSession()

						this.sessionAction = false

					} else {
						this.$message.error(res.msg);
					}

				})
			},
			//保存操作 
			submit() {
				// this.loading = true


				if (!this.start_time) {
					this.loading = false
					return this.$message.error('开场时间暂未设置，请先完善!');
				}

				if (this.sessionForm.status == null) {
					this.loading = false
					return this.$message.error('场次状态暂未设置，请先完善!');
				}


				this.sessionForm.start_time = Math.ceil(new Date(this.start_time).getTime() / 1000)

				if (this.DMList) {

					this.sessionForm.dm_ids = JSON.parse(JSON.stringify(this.DMList))

					this.sessionForm.dm_ids.forEach(item => {

						delete item.avatar
						delete item.nikename
						delete item.mobile
						return item
					})

					this.sessionForm.dm_ids = JSON.stringify(this.sessionForm.dm_ids)
				}

				if (this.changeType == 0) {
					this.addSubmit();
					return;
				}

				this.$api.apiInterface(this.apiName, 'saveInfo').saveInfo(this.sessionForm, res => {
					this.loading = false
					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，操作成功',
							type: 'success'
						});

						this.getSession()

						this.sessionAction = false

					} else {
						this.$message.error(res.msg);
					}

				})
			},

			//dm弹窗
			getDM() {
				this.DMtype = true
				this.getUserList()
			},
			//获取用户列表
			getUserList() {
				this.load = true
				this.$api.getList('user', this.userForm, (res) => {
					this.load = false
					if (res.code == 0) {
						this.userList = res.data.list
						this.userTotal = res.data.count


					} else {
						this.$message.error(res.msg);
					}
				})
			},
			//设置DM
			setDm(val, index) {
				// console.log(val);
				this.DMtype = true

				if (val == 'DM') {
					this.setDmVal = {
						id: '',
						money: "",
						name: "",
						type: '1'
					}

					this.dmInfo = {
						id: '',
						money: "",
						name: "",
						type: '1'
					}

					this.DMList = [{
						user_id: '',
						type: "",
						uname: "",
						umoney: '1'
					}]
				} else {
					this.setDmVal = val;
				}

				this.getUserList();

			},
			handleSelectionDMChange(val) {

				// console.log("val", val);
				this.DMtype = false;

				if (!this.dmInfo.hasOwnProperty('length')) {

					this.dmInfo.id = val.user_id;
					this.dmInfo.name = val.nickname;
					this.dmInfo.avatar = val.avatar || require('../../static/home_huli.png');

					this.DMList[0].user_id = val.user_id;
					this.DMList[0].uname = val.nickname;

				} else {

					var index = this.dmInfo.findIndex(dmItem => {
						return dmItem.id == this.setDmVal.id;
					})

					this.dmInfo[index].id = val.user_id;
					this.dmInfo[index].name = val.nickname;
					this.dmInfo[index].avatar = val.avatar || require('../../static/home_huli.png');

					this.DMList[index].user_id = val.user_id;
					this.DMList[index].uname = val.nickname;
				}

			},
			handleClose(index) {
				this.DMList.splice(index, 1);
			},
			//用户分页
			userhandleCurrentChange(val) {
				this.userForm.page = val
				this.getUserList()
			}
		}

	}
</script>

<style scoped lang="less">
	/deep/ .el-card__body {
		height: 100%;
	}
	
	.refresh{
		display: block;
		cursor: pointer;
		font-size: 35rem;
		margin-right: 35rem;
	}

	.topNav {
		display: flex;
		font-size: 16rem;
		color: #409EFF;
		align-items: center;
		margin-bottom: 20rem;
		margin-left: 25rem;
		cursor: pointer;
		user-select: none;

		img {
			width: 26rem;
			height: 26rem;
			margin-right: 12rem;
		}
	}

	.bookImg {
		width: 110rem;
		height: 148rem;
	}

	.DMImg {
		width: 50rem;
		height: 50rem;
	}

	.inputW {
		width: 300rem;
	}

	.liFlex {
		display: flex;
		justify-content: space-between;

		.dm_else {
			display: flex;
			flex-direction: column;
			align-items: center;
			cursor: pointer;
			margin-right: 20rem;
		}

		.avatar {
			display: inline-block;
			width: 50rem;
			height: 50rem;
			border: 2rem dashed #ccc;
			border-radius: 50%;
			line-height: 50rem;
			text-align: center;
			font-size: 26rem;
			color: #ccc;
		}
	}

	.status {
		margin-bottom: 15rem;

		.icon {
			display: inline-block;
			width: 13rem;
			height: 13rem;
			background: #FFE300;
			border-radius: 50%;
			margin-right: 10rem;
		}
	}

	.tip {
		font-size: 12px;
		color: #C0C4CC;

		margin-left: 30rem;
	}
</style>
