<template>
	<div class="memberLogin">

		<div class="login">
			<div class="img" @click="scanCode">
				<img src="../../static/code.png" alt="">
			</div>
			<p style="color: #828282;font-size: 18rem;">点击扫码登录</p>
			<div class="search">
				<p>输入手机号查询：</p>
				<div class="input">
					<input type="text" v-model="keyWord" placeholder="请输入手机号" />
					<div class="searchBtn">搜索</div>

					<div class="result" v-if="phoneList.length > 0">
						<ul>
							<li v-for="(item,index) in phoneList" :key="index" @click="liHandle(item)">
								<span>{{item.mobile}}</span>
								<img src="../../static/arrowIcon.png" alt="">
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="loginInfo">
			登录会员查看信息
		</div>

		<div class="dialog" id="bcid" v-show="isDialog" style="">
			<!-- <div v-if="status == 1">
>>>>>>> fb2a0afd338d0138a9f68ad132e1b26d3f266d28
				<input type="text" class="boxInput" :autofocus="autofocus" @keydown="keyHandle"
					@focus="isFoucus = false" />
				<div class="scanLogin">
					
					<img src="../../static/scanCode.png" alt="">

					<div style="font-size: 32rem;color: #282828;margin-top: 20rem;" v-if="isFoucus">鼠标点击我获得焦点</div>

					<template v-else>
						<p>请将会员码</p>

						<p style="margin-top: 10rem;">放至小白盒扫描</p>
					</template>

				</div>
			</div>

			<div v-else-if="status == 2">
				<div class="success">
					<img src="../../static/success.png" alt="">
					<p style="color: #282828;font-size: 26rem;margin: 58rem 0 62rem;">会员登录成功!</p>
					<div class="loginBtn" @click="submit">OK</div>
				</div>
			</div> -->

			<!-- <qrcode-drop-zone @decode="onDecode">
				<qrcode-stream @decode="onDecode" :torch="torchActive" @init="onInit" :camera="camera" />
			</qrcode-drop-zone> -->
		</div>

		<!-- 遮罩 -->
		<div class="mask" v-if="isDialog" @click="maskHandle"></div>

	</div>
</template>

<script>
	// 扩展API加载完毕后调用onPlusReady回调函数
	document.addEventListener("plusready", onPlusReady, false);
	// 扩展API加载完毕，现在可以正常调用扩展API
	function onPlusReady() {
		var e = document.getElementById("scan");
		e.removeAttribute("disabled");
	}
	var scan = null;

	// 引用vue-qrcode-reader插件
	import {
		QrcodeStream,
		QrcodeDropZone,
		QrcodeCapture
	} from 'vue-qrcode-reader'

	export default {
		name: "memberLogin",
		data() {
			return {
				apiName: "Member",
				keyWord: "",
				isDialog: false,
				status: 1,
				isFoucus: false,
				autofocus: false,
				isDebouce: true,
				timer: null,
				phoneList: [],
				//摄像头扫码配置
				camera: "rear",
				torchActive: false,
				errorMessage: ""
			}
		},
		components: {
			QrcodeStream,
			QrcodeDropZone,
			QrcodeCapture
		},
		watch: {
			autofocus(newVal, oldVal) {
				if (!newVal) {
					this.isFoucus = true;
				}
			},
			keyWord(newVal, oldVal) {
				// console.log("newVal",newVal);
				this.debounce(this.phoneSearch, 1000);
			}
		},
		created() {

			//获取摄像头设备
			var promise = navigator.mediaDevices.getUserMedia({
				video: true
			})
			promise.then((mediaStream) => {
				console.log('mediaStream', mediaStream);
			}).catch(function(err) {
				console.log(err, 'err')
			})


			if (localStorage.getItem("userInfo")) {
				this.$router.push({
					path: "/sessionsList/index?isLogin=true"
				})
			}
		},
		mounted() {
			// this.startRecognize();
		},
		methods: {
			startRecognize() {
				setTimeout(() => {
					scan = new plus.barcode.Barcode('bcid');
					scan.onmarked = onmarked;
					this.startScan

					console.log("scan", scan);
				}, 1000)


			},
			startScan() {
				scan.start();
			},
			maskHandle() {
				this.isFoucus = true;

				this.isDialog = false;
			},
			liHandle(item) {
				
				//会员登录
				this.$api.apiInterface("Member", "idLogin").idLogin({
					user_id: item.user_id
				}, res => {
					if (res.code == 0) {
						this.$message({
							type: "success",
							message: '登录成功'
						})

						var userInfo = res.data;

						localStorage.setItem("userInfo", JSON.stringify(userInfo));

						this.$router.push({
							path: "/sessionsList/index?isLogin=true"
						})
						return;
					}
					this.$message({
						type: "warning",
						message: res.msg
					})
				})
				
			},
			//防抖
			debounce(fn, wait) {
				if (this.timer !== null) {
					clearTimeout(this.timer)
				}
				this.timer = setTimeout(fn, wait);
			},
			//手机号查询
			phoneSearch() {

				this.$api.apiInterface(this.apiName, "searchUser").searchUser({
					mobile: this.keyWord
				}, res => {
					console.log("-----", res);
					if (res.code == 0) {
						this.phoneList = res.data;
					}
				})
			},
			// 扫码结果回调
			onDecode(result) {
				// result, 扫描结果，可以根据自己的需求来实现相应的功能 
				console.log(result)
				this.turnCameraOff()
			},
			// 相机反转
			switchCamera() {
				switch (this.camera) {
					case 'front':
						this.camera = 'rear'
						break
					case 'rear':
						this.camera = 'front'
						break
					default:
						console.log("错误")
				}
			},
			// 关闭相机？？？？？？
			turnCameraOff() {
				this.camera = 'off'
				this.isDialog = false
			},
			// 打开手电筒
			ClickFlash() {
				switch (this.torchActive) {
					case true:
						this.torchActive = false
						break
					case false:
						this.torchActive = true
						break
					default:
						console.log("错误")
				}
			},

			// 检查是否调用摄像头
			async onInit(promise) {
				console.log("promise", promise);

				try {
					await promise
				} catch (error) {
					if (error.name === 'StreamApiNotSupportedError') {} else if (error.name === 'NotAllowedError') {
						this.errorMessage = 'Hey! I need access to your camera'
					} else if (error.name === 'NotFoundError') {
						this.errorMessage = 'Do you even have a camera on your device?'
					} else if (error.name === 'NotSupportedError') {
						this.errorMessage =
							'Seems like this page is served in non-secure context (HTTPS, localhost or file://)'
					} else if (error.name === 'NotReadableError') {
						this.errorMessage =
							"Couldn't access your camera. Is it already in use?"
					} else if (error.name === 'OverconstrainedError') {
						this.errorMessage =
							"Constraints don't match any installed camera. Did you asked for the front camera although there is none?"
					} else {
						this.errorMessage = 'UNKNOWN ERROR: ' + error.message
					}
				}
			},
			scanCode() {

				//打开相机
				this.isDialog = true;

				// camera:: 'rear'--前摄像头，'front'后摄像头，'off'关闭摄像头，会获取最后一帧显示，'auto'开始获取摄像头
				this.camera = 'rear'

				//测试
				this.status = 1;

				this.autofocus = true;
				this.isFoucus = false;

			},
			//小白盒扫码登录
			memberLogin() {

				this.$api.apiInterface(this.apiName, "scanLogin").scanLogin({
					code: "QexxU6HsoxoKtw6ZfBYdbDYyVrMplm3GgZr4PIOphQEIHQQcXRCUS+mocpLgP49/LeLp"
				}, res => {
					console.log("------", res);
					this.isDebouce = true;

					if (res.code == 0) {
						this.$message({
							type: "success",
							message: '登录成功'
						})
						this.status = 2;
						return;
					}

					this.isDialog = false;

					this.$message({
						type: "warning",
						message: res.msg
					})

				})

			},
			keyHandle() {

				// if (!this.isDebouce) {
				// 	this.$message({
				// 		type: "warning",
				// 		message: '正在登录，请勿进行其他操作'
				// 	})
				// 	return;
				// }

				this.isDebouce = false;

				//模拟小白盒登录
				setTimeout(() => {
					this.memberLogin();
				}, 3000)

			},
			submit() {

				this.isDialog = false;

				this.status = 1;

				this.$router.push({
					path: "/member/index?isLogin=true"
				})

			}
		}
	}
</script>

<style scoped lang="less">
	.dialog {
		width: 1000rem;
		height: 800rem;
		background: #fff;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-40%, -50%);
		z-index: 100;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10rem;
		padding: 25rem 25rem;
		box-sizing: border-box;
		box-sizing: 25rem 25rem 25rem 25rem #333;

		.boxInput {
			display: inline-block;
			width: 100%;
			height: 100%;
			border: 5rem solid #333;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 10rem;
			color: #fff;
		}

		.scanLogin {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 99;
			transform: translate(-50%, -50%);
			pointer-events: none;
			overflow: initial;

			p {
				width: 250rem;
				font-size: 32rem;
				color: #282828;
				text-align: center;
				line-height: 50rem;
			}
		}
	}

	.mask {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, .5);
		z-index: 99;
	}

	//测试
	.scanCode {
		width: 300rem;
		height: 300rem;
		position: relative;

		.codeBg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 50rem;
			background-image: linear-gradient(to top, rgba(254, 235, 120, .8) -10%, rgba(254, 235, 120, .1) 90%);

			border-top-left-radius: 50%;
			border-top-right-radius: 50%;

			animation: move 1.5s infinite linear;
		}
	}

	@keyframes move {
		0% {
			transform: translateY(0);
		}

		100% {
			transform: translateY(200rem);
		}
	}

	.memberLogin {
		padding: 30rem 60rem 40rem 34rem;
		display: flex;
		position: relative;
	}

	.login {
		width: 362rem;
		height: 749rem;
		background: #fff;
		border-radius: 10rem;
		display: flex;
		flex-direction: column;
		align-items: center;

		.img {
			width: 72rem;
			height: 72rem;
			background: #282828;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 31rem 0 17rem;
			cursor: pointer;

			img {
				width: 38rem;
				height: 38rem;
			}
		}

		.search {
			margin-top: 36rem;

			p {
				color: #828282;
				font-size: 18rem;
				margin-bottom: 17rem;
			}

			.input {
				display: flex;
				position: relative;

				input {
					width: 194rem;
					height: 43rem;
					border: 1rem solid #c9c9c9;
					border-radius: 5rem;
					box-sizing: border-box;
					padding-left: 10rem;
				}

				.searchBtn {
					width: 82rem;
					height: 43rem;
					background: #FFE300;
					color: #282828;
					text-align: center;
					line-height: 43rem;
					font-size: 18rem;
					border-radius: 5rem;
					cursor: pointer;
					user-select: none;
					margin-left: 17rem;
				}

				.result {
					width: 194rem;
					// height: 150rem;
					background: #F7F7F7;
					position: absolute;
					top: 50rem;
					left: 0;
					border-radius: 5rem;
					padding-top: 20rem;
					box-sizing: border-box;

					ul {
						display: flex;
						flex-direction: column;
						align-items: center;
					}

					li {
						color: #828282;
						font-size: 20rem;
						cursor: pointer;
						margin-bottom: 10rem;

						img {
							width: 13rem;
							height: 16rem;
							margin-left: 16rem;
						}
					}
				}
			}
		}
	}

	.loginInfo {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 30rem;
		color: #828282;
	}

	.success {
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			width: 340rem;
			height: 260rem;
		}

		.loginBtn {
			width: 240rem;
			height: 60rem;
			background: #FFE300;
			border-radius: 5rem;
			text-align: center;
			line-height: 60rem;
			cursor: pointer;
			user-select: none;
			font-size: 26rem;
			color: #282828;
		}
	}
</style>
