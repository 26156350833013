<template>
	<div>
		<!-- 添加 -->
		<!-- <div class="topListArea">
			<div class="left">
				<el-button type="primary" size="mini" @click="listHandle('add')">添加</el-button>
				<el-button type="danger" class="el-icon-delete" size="mini" @click="listHandle('listDelete')">批量删除
				</el-button>
			</div>

			<div class="right">
				
			</div>
		</div> -->

		<el-table border :data="listData" style="width: 100%;margin-bottom: 20rem;" row-key="id"  v-loading="loading">

			<el-table-column  :width="item.width" v-for="(item,index) in colList" :key="item.key" align="center" :fixed="item.fixed">
				
                <!-- 表头 -->
				<template #header>
					<span>{{item.lable}}</span>
				</template>

				<template slot-scope="scope">
					<!-- 单元格展示内容 -->
					<div v-if="item.type !='buttons'">
						
						<listTable :item='{...item,value:scope.row[item.key],id:scope.row.id,status:scope.row.status}'>
							<!-- 自定义组件 -->
							<template v-slot:[item.type]='customScope'>
								<slot :name="item.type" :row="customScope.row"></slot>
							</template>
						</listTable>
					</div>
					
					<!-- 单元格操作区域 -->
					<div style="display: flex;justify-content: center;" v-else>
						
						<div v-for="(btnItem,btnIndex) in item.options" :key="btnIndex">
							<template v-if="btnItem.type!='btn'">
								<span :style="'color:'+(btnItem.color || '#409eff')+';'" @click="listHandle(btnItem.clickType,scope.row)" class="btn" :class="btnItem.icon">
									
									<i>{{btnItem.title}}</i></span>
							</template>
							<template v-else>
								<el-button v-for="(btnItem,btnIndex) in item.options" :key="btnIndex" :type="btnItem.btnType" class="el-icon-edit" size="mini" @click="listHandle(btnItem.clickType,scope.row)">{{btnItem.title}}
								</el-button>
							</template>
						</div>
						
					</div>
				</template>

			</el-table-column>

		</el-table>
	</div>
</template>

<script>
	import listTable from "@/components/listTable/index.vue"

	export default {
		data() {
			return {
               loading:false
			}
		},
		components: {
			listTable
		},
		watch:{
			'layout.loading':{
				handler(newVal,oldVal){
					this.loading = newVal;
				}
			}
		},
		inject:['layout'],
		props:{
			callBack:Function,
			listData:Array, // 表格内容数据
			colList:Array//表头数据
		},
		methods: {
			listHandle(type, data) {
				//按钮回调
				this.callBack(type,data);
			}
		}
	}
</script>

<style scoped lang="less">
	.topListArea {
		display: flex;
		justify-content: space-between;
		border: 1rem solid #EBEEF5;
		border-bottom: none;
		padding: 10rem 10rem;
		// background: #f2f3f5;
	}
	
	.btn{
		display: inline-block;
		margin-right: 15rem;
		cursor: pointer;
		user-select: none;
		i{
			margin-left: 10rem;
			font-style: normal;
		}
	}
</style>
