<template>
	<div class="page" style="box-sizing: border-box;overflow-y: auto;height: 98%;position: relative;">
		<el-dialog title="提示" :visible.sync="isForm" width="80%" @close="formHandle('closeDialog')">

			<div class="setType">
				<ul>
					<li @click="setTpye = '1'" :style="setTpye=='1' ? 'color: #409EFF;' : ''">
						剧本设置
						<span :class="{'activeLi':setTpye=='1'}"></span>
					</li>
					<li @click="setTpye = '2'" :style="setTpye=='2' ? 'color: #409EFF;' : ''">
						DM设置
						<span :class="{'activeLi':setTpye=='2'}"></span>
					</li>
				</ul>
			</div>

			<template v-if="setTpye == '1'">
				<div class="formItem" style="">
					<p class="itemTil" style="margin-bottom: 0rem;">添加剧本：</p>
					<div class="playInfo" v-if="playInfo && playInfo.headPic">
						<div style="position: relative;">
							<img :src="playInfo.headPic" alt="">
							<div class="specification">
								<!-- <el-tag size="mini" :type="saleType">{{playInfo.specification}}</el-tag> -->
							</div>
						</div>
						<div class="info">
							<p class="title">{{playInfo.title}}</p>
							<ul v-if="playInfo.list_type && playInfo.list_type.length>0">
								<li v-for="(listItem,listIndex) in playInfo.list_type" :key="listIndex">{{listItem}}
								</li>
							</ul>
							<p class="li" v-else>暂无分类</p>
							<p class="desc">
								{{playInfo.shortDesc}}
							</p>
						</div>
					</div>

					<div class="btn" @click="selectPlay">选择剧本</div>
				</div>
				<div class="formItem" style="display: block;">
					<p class="itemTil" style="">剧本推荐语(选填)：</p>
					<textarea v-model="formData.recommend" class="textarea" placeholder="请输入推荐语"></textarea>
				</div>
				<div class="formItem" style="">
					<p class="itemTil" style="margin-bottom: 0;">价格信息：</p>
					<div class="price">

						<div class="priceInput">
							<input type="text" v-model="formData.price"/><span>元/人/场</span>
						</div>

						<span style="cursor: pointer;" @click="getPrice">设置店铺价格模板</span>
						<span class="el-icon-arrow-right"></span>
						
					</div>
				</div>

				<div style="display: flex;margin-top: 60rem;">
					<div class="formItem" style="">
						<p class="itemTil" style="margin-bottom: 0rem;">推荐：</p>
						<el-switch v-model="formData.is_recommend">
						</el-switch>
					</div>
					<div class="formItem" style="">
						<p class="itemTil" style="margin-bottom: 0rem;">预告：</p>
						<el-switch v-model="formData.is_preview">
						</el-switch>
					</div>
					<div class="formItem" style="">
						<p class="itemTil" style="margin-bottom: 0rem;">置顶：</p>
						<el-switch v-model="formData.is_top">
						</el-switch>
					</div>
					<div class="formItem" style="">
						<p class="itemTil" style="margin-bottom: 0rem;">上架：</p>
						<el-switch v-model="formData.status">
						</el-switch>
					</div>
				</div>
			</template>

			<template v-else>
				<div style="display: flex;margin-top: 40rem;align-items: center;">
					<p class="itemTil" style="margin-bottom: 0rem;">DM数量：</p>
					<el-radio v-model="formData.dm_number" :label="item.value" v-for="(item,index) in dmNum"
						:key="index">{{item.label}}</el-radio>
				</div>

				<div style="margin-top: 40rem;" v-if="formData.dm_number == '2'">
					<p class="itemTil" style="margin-bottom: 0rem;display: inline-block;">DM设置：</p>

					<div class="btn" style="display: inline-block;" @click="addDm">添加DM+</div>

					<div class="dmCon">
						<div class="DMList" v-for="(item,index) in formData.dm_info" :key="item.id">
							<!-- 删除按钮 -->
							<span class="delBtn" @click="delBtn">+</span>
							<ul style="margin-top: 10rem;">
								<li>
									<p class="itemTil" style="margin-bottom: 0rem;">DM类型：</p>
									<el-radio @input="dmRadioHandle($event,index,item)" v-model="item.type"
										:label="typeItem.value" v-for="(typeItem,typeIndex) in dmType" :key="typeIndex">
										{{typeItem.label}}
									</el-radio>
								</li>

								<li style="margin-top: 10rem;">
									<p class="itemTil" style="margin-bottom: 0rem;">名称：</p>
									<el-input :disabled="item.type==1 ? true : false" style="width: 250rem;" size="mini"
										v-model="item.name" placeholder="名称"></el-input>
								</li>
								<template v-if="item.type == 2">
									<li style="margin-top: 10rem;">
										<p class="itemTil" style="margin-bottom: 0rem;">工资：</p>
										<el-input :disabled="item.type==1 ? true : false" style="width: 250rem;"
											size="mini" v-model="item.money" placeholder="工资"></el-input>
									</li>
								</template>

							</ul>
						</div>
					</div>

				</div>
			</template>

			<!-- <div class="formBtn">
			<el-button size="small" type="primary" @click="formHandle('confirm')">确定</el-button>
			<el-button size="small" type="info" @click="formHandle('cancel')">取消</el-button>
		</div> -->

			<span slot="footer" class="dialog-footer">
				<el-button @click="formHandle('cancel')">取 消</el-button>
				<el-button type="primary" @click="formHandle('confirm')">确 定</el-button>
			</span>


		</el-dialog>

		<!-- 剧本资源库 -->
		<el-dialog title="剧本资源" :visible.sync="isDialog" width="85%" @close="formHandle('close')">

			<!-- 筛选 -->
			<slot name="screenArea"></slot>

			<div style="margin-top: 20rem;">
				<baseList :callBack='formHandle' :colList.sync="colList" :listData.sync="tabListData"></baseList>
			</div>

			<!-- 分页 -->
			<slot name="formPageSize"></slot>

		</el-dialog>

		<!-- 设置价格 -->
		<el-dialog title="价格设置" :visible.sync="isSetPrice" width="20%">

			<label style="margin-bottom: 15rem;display: block;">
				<span style="font-size: 25rem;margin-bottom: 15rem;display: block;">盒装价格：</span>
				<el-input v-model="basicPrice.box_price" type="text" size="small" style="width: 350rem;" placeholder="盒装价格"></el-input>
			</label>

			<label style="margin-bottom: 15rem;display: block;">
				<span style="font-size: 25rem;margin-bottom: 15rem;display: block;">城限价格：</span>
				<el-input v-model="basicPrice.city_price" type="text" size="small" style="width: 350rem;" placeholder="城限价格"></el-input>
			</label>

			<label style="margin-bottom: 15rem;display: block;">
				<span style="font-size: 25rem;margin-bottom: 15rem;display: block;">独家价格：</span>
				<el-input v-model="basicPrice.single_price" type="text" size="small" style="width: 350rem;" placeholder="独家价格"></el-input>
			</label>
			
			<label style="margin-bottom: 15rem;display: block;">
				<span style="font-size: 25rem;margin-bottom: 15rem;display: block;">价格说明：</span>
				<el-input v-model="basicPrice.price_msg" type="textarea" style="width: 350rem;" placeholder="价格说明"></el-input>
			</label>

			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="isSetPrice = false">取 消</el-button>
				<el-button size="small" type="primary" @click="setPrice">确 定</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	import baseList from "@/components/baseList/index.vue"

	export default {
		data() {
			return {
				isDialog: false,
				isSetPrice: false,
				tabListData: [],
				basicPrice:{
					box_price:"",
					city_price:"",
					single_price:"",
					price_msg:""
				},
				//剧本信息
				playInfo: null,
				shopInfo: null,
				isForm: false,
				dmNum: [{
						label: "单人",
						value: "1"
					},
					{
						label: "多人",
						value: "2"
					}
				],
				dmType: [{
						label: "主D",
						value: "1"
					},
					{
						label: "演绎",
						value: "2"
					}
				],
				dmData: {
					type: "1",
					name: "",
					money: ""
				},
				setTpye: '1'
			}
		},
		components: {
			baseList
		},
		props: {
			formData: Object,
			formCallBack: Function,
			listData: Array, // 表格内容数据
			colList: Array, //表头数据
			isEdit: Boolean
		},
		watch: {
			isEdit(newVal) {
				this.isForm = newVal;
			},
			listData(newVal) {
				// console.log("-----",newVal);
				this.tabListData = newVal;
			},
			playInfo(newVal) {

				if (typeof newVal == 'undefined') {
					this.playInfo = newVal;
				}

			},
			formData: {
				deep: true,
				handler(newVal, oldVal) {
					// console.log("newVal",newVal);

					if (newVal.playInfo && !this.isDialog) {

						this.playInfo = newVal.playInfo;

						// this.formData.price = this.playInfo.specification == "城限" ? this.shopInfo.city_price : (
						// 	this
						// 	.playInfo.specification == "独家" ? this.shopInfo.single_price : this.shopInfo
						// 	.box_price);

					} else if (!newVal.playInfo && !this.playInfo) {
						this.playInfo = "";
					}

				}
			}
		},
		computed: {
			//发售类型
			saleType() {
				if (this.playInfo.specification == "城限") {
					return 'danger'
				} else if (this.playInfo.specification == "独家") {
					return 'primary'
				} else {
					return 'warning'
				}
			}
		},
		created() {

            this.getShopInfo();
			this.tabListData = this.listData;

		},
		methods: {
			//删除DM
			delBtn(val, index) {
				if (this.formData.dm_info.length <= 1) {
					this.$message({
						message: '至少需要设置一个DM',
						type: 'warning'
					})
					return;
				}

				this.formData.dm_info.splice(index, 1);
			},
			//添加DM
			addDm() {

				this.formData.dm_info.push({
					id: this.formData.dm_info.length + 1,
					type: "1",
					name: "",
					money: ""
				});

			},
			dmRadioHandle(val, index, item) {
				console.log("item", item);

				if (val == 2) {
					this.formData.dm_info[index].name = `演绎${index+1}`;
				} else {
					this.formData.dm_info[index].name = "";
				}

			},
			getPrice(){
				this.isSetPrice = true;
				
				this.basicPrice = {...this.basicPrice,...this.shopInfo};
			},
			getShopInfo(){
				this.$api.getShopList({}, (res) => {
					if (res.code == 0) {
						this.$nextTick(()=>{
							this.shopInfo = res.data;
                            
							if(this.playInfo && this.playInfo.specification){
								this.formData.price = this.playInfo.specification == "城限" ? this.shopInfo.city_price : (
									this
									.playInfo.specification == "独家" ? this.shopInfo.single_price : this.shopInfo
									.box_price);
							}
							
							
						})
						
						localStorage.setItem('shopInfo', JSON.stringify(res.data));
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			//设置价格
			setPrice() {
                this.$api.apiInterface("store", "saveInfo").saveInfo(this.basicPrice, res => {
                	console.log("------", res);
					this.isSetPrice = false;
                			
                	if (res.code == 0) {
                		this.$message({
                			type: "success",
                			message: '操作成功'
                		})
						
						this.getShopInfo();

                		return;
                	}
                			
                	this.$message({
                		type: "warning",
                		message: res.msg
                	})
                			
                })
			},
			formatType() {

				this.playInfo.list_type = this.playInfo.type.split(',');

				if (this.playInfo.list_type.includes('')) {
					this.playInfo.list_type.splice(0, 1);
				}

			},
			formHandle(type, val) {

				if (type == 'confirm') {

					if (!this.playInfo) {
						this.$message({
							type: 'warning',
							message: '请选择剧本'
						});
						return;
					}
					// console.log("formData,playInfo",this.formData,this.playInfo)

					// this.formData.book_id = this.formData.book_id != '' ? this.formData.book_id : this.playInfo.id;
					if (this.playInfo.hasOwnProperty('id')) {
						this.formData.book_id = this.playInfo.id;
					}

					this.isDialog = false;

				} else if (type == 'cancel') {

					this.isDialog = false;

				} else if (type != 'close' && type != 'closeDialog') {

					this.playInfo = val;

					this.formatType();

					this.formData.price = this.playInfo.specification == "城限" ? this.shopInfo.city_price : (this
						.playInfo
						.specification == "独家" ? this.shopInfo.single_price : this.shopInfo.box_price);

					this.isDialog = false;
				}

				this.setTpye = 1;

				this.formCallBack(type, this.formData);
			},
			//选择剧本
			selectPlay() {
				this.isDialog = true;
			}
		}
	}
</script>

<style scoped lang="less">
	// .page::-webkit-scrollbar{
	// 	display: none;
	// }

	.btn {
		width: 130rem;
		height: 60rem;
		background: #FFE300;
		color: #282828;
		text-align: center;
		line-height: 60rem;
		border-radius: 10rem;
		cursor: pointer;
	}

	.specification {
		position: absolute;
		top: 0;
		left: 0;
	}

	.priceInput {
		// width: 200rem;
		border: 1rem dashed #ccc;
		border-radius: 5rem;
		padding: 15rem 15rem 15rem 0;
		margin-right: 20rem;

		input {
			width: 100rem;
			text-align: center;
		}
	}

	.formItem {
		margin-bottom: 20rem;
		display: flex;
		align-items: center;
		margin-right: 60rem;

		.itemTil {
			font-size: 22rem;
			color: #282828;
			margin-bottom: 10rem;
		}


		.textarea {
			width: 580rem;
			min-height: 150rem;
			background: #F7F7F7;
			padding: 15rem 15rem;
			box-sizing: border-box;
			border-radius: 10rem;
			font-size: 22rem;
		}

		.price {
			color: #ccc;
			display: flex;
			align-items: center;
		}
	}

	.formBtn {
		display: flex;
		justify-content: center;
		// margin-top: 80rem;
		position: absolute;
		bottom: 15rem;
		left: 50%;
		transform: translateX(-50%);
	}

	.playInfo {
		display: flex;
		margin-right: 20rem;

		img {
			width: 160rem;
			height: 200rem;
		}

		.info {
			margin-left: 20rem;

			.title {
				font-size: 24rem;
				color: #282828;
				font-weight: bold;
			}

			ul {
				display: flex;
				margin: 15rem 0;

				li {
					background: #F3F8FE;
					border-radius: 5rem;
					text-align: center;
					padding: 5rem 5rem;
					color: #81BCF5;
					margin-right: 10rem;
				}
			}

			.li {
				width: 100rem;
				background: #F3F8FE;
				border-radius: 5rem;
				text-align: center;
				padding: 5rem 5rem;
				color: #81BCF5;
				margin-right: 10rem;
				margin: 15rem 0;
			}

			.desc {
				width: 200rem;
				display: -webkit-box;
				overflow: hidden;
				-webkit-box-orient: vertical;
				line-clamp: 3;
				-webkit-line-clamp: 3; //显示几行
				color: #B7B8B9;
			}
		}
	}

	.priceList {
		li {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 25rem;

			input {
				width: 150rem;
				height: 60rem;
				background: #F7F7F7;
				border-radius: 10rem;
				text-align: center;
				margin-left: 50rem;
			}
		}
	}

	.DM {
		margin-right: 10rem;
	}

	.dmCon {
		display: flex;
		flex-wrap: wrap;
	}

	.DMList {
		width: 400rem;
		height: 230rem;
		border: 2rem dashed #ccc;
		margin-top: 20rem;
		// margin-left: 105rem;
		border-radius: 5rem;
		padding: 0 15rem 0rem 15rem;
		box-sizing: border-box;
		position: relative;
		margin-right: 30rem;

		ul li {
			display: flex;
			align-items: center;
		}
	}

	.setType {
		margin-bottom: 30rem;

		ul {
			display: flex;

			li {
				flex: 1;
				text-align: center;
				font-size: 28rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				cursor: pointer;

				span {
					display: inline-block;
					width: 50rem;
					margin-top: 15rem;
				}
			}

			.activeLi {
				border-bottom: 5rem solid #409EFF;

			}
		}
	}

	.delBtn {
		display: inline-block;
		font-size: 32rem;
		transform: rotateZ(45deg);
		margin-left: 340rem;
		margin-top: 10rem;
		cursor: pointer;
	}

	/deep/ .el-dialog__body {
		padding-top: 15rem;
	}

	/deep/ .el-dialog {
		margin-top: 15vh !important;
	}
</style>
