<template>
	<div style="width: 100%;height: 100%;">

		<div class="form" style="">

			<div class="formItem" v-for="(item,index) in formList" :key="index">
				<!-- <label> -->
				<p class="formTil" v-if="item.key !='id'">{{item.label}}:</p>

				<!-- 输入框 -->
				<div v-if="item.type=='text' && item.key !='id'">
					<el-input style="width: 100%;" v-model="item.value" size="small"
						:placeholder="item.place ? item.place : '请输入内容'"></el-input>
				</div>

				<!-- 选择框 -->
				<div v-else-if="item.type=='select'">

					<el-select size="small" v-model="item.value" :placeholder="`请选择活动${item.label}`">
						<el-option :label="selItem.label" :value="selItem.value" v-for="(selItem,selIndex) in item.ext"
							:key="selIndex"></el-option>
					</el-select>

				</div>

				<!-- 选择日期/时间框 -->
				<div v-else-if="item.type=='time'">
					<el-date-picker size="small" v-model="item.value" type="datetime" placeholder="选择日期时间">
					</el-date-picker>
				</div>

				<!-- switch开关 -->
				<div v-else-if="item.type =='switch'">
					<el-switch size="small" v-model="item.value"></el-switch>
				</div>

				<!-- 复选框 -->
				<div v-else-if="item.type=='checkBox'">
					<el-checkbox-group size="small" v-model="item.value">
						<el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
						<el-checkbox label="地推活动" name="type"></el-checkbox>
						<el-checkbox label="线下主题活动" name="type"></el-checkbox>
						<el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
					</el-checkbox-group>
				</div>

				<!-- Radio单选框 -->
				<div v-else-if="item.type=='radio' || item.type=='status'">
					<el-radio-group size="small" v-model="item.value">
						<el-radio :label="optionsItem.key" v-for="(optionsItem,optionsIndex) in item.options"
							:key="optionsIndex">{{optionsItem.title}}</el-radio>
					</el-radio-group>
				</div>

				<!-- 文本域 -->
				<div style="margin-top: 15rem;" v-else-if="item.type=='textarea'">
					<el-input style="width: 400rem;" :rows="5" :placeholder="item.place ? item.place : '请输入内容'"
						type="textarea" v-model="item.value"></el-input>
				</div>
				
				<!-- 规则 -->
				<template v-else-if="item.type=='rules'">
					
					<div>
						
						<div style="display: flex;margin-top: 22rem;">
							
							<div class="rules">
								<el-input style="width: 400rem;margin-bottom: 15rem;" size="mini" type="text" placeholder="充值金额" v-model="rules.cz"></el-input>
								<el-input style="width: 400rem;margin-bottom: 15rem;" size="mini" type="text" placeholder="赠送额度" v-model="rules.allsend"></el-input>
								<el-input style="width: 400rem;" :rows="4" size="mini" type="textarea" placeholder="推荐字样" v-model="rules.ts"></el-input>
								<div class="ruleBtn" @click="sureClick('add',item.value)">
									{{clickTxt}} 
								</div>
							</div>
							
							<div class="rulesList" v-if="item.value.length > 0">
								<h3 style="font-size: 26rem;margin-bottom: 5rem;">赠送规则：</h3>
								
								<div v-for="(childItem,childIndex) in item.value" :key="childIndex" style="line-height: 30rem;">
									<span>{{childIndex+1}}.</span>
									<span style="margin-left: 10rem;">充值金额{{childItem.cz}},</span>
									<span>赠送额度{{childItem.allsend}},</span>
									<span>推荐字样"{{childItem.ts}}"。</span>
									<span style="color: #e6a23c;cursor: pointer;" @click="editRules(childItem)">编辑</span>
								</div>
							</div>
							
						</div>
						
					</div>
					
				</template>

				<!-- 自定义表单组件 -->
				<template v-if="item.slotName">
					<slot :name="item.slotName"></slot>
				</template>

			</div>

		</div>

		<div class="formControl">

			<el-button size="small" type="primary" @click="submitHandle">立即创建</el-button>
			<el-button size="small" @click="cancelHandle">取消</el-button>

		</div>
	</div>
</template>

<script>
	

	export default {
		data() {
			return {
				formList:[],
				rules:{
					cz:"",
					allsend:"",
					ts:""
				},
				clickTxt:"添加规则"
			}
		},
		props: {
			formData: Array,
			formCallBack:Function
		},
		created() {
            
			this.formList = [].concat(this.formData);
			
			this.formList.forEach(item=>{
				
				if(item.type == "rules" && typeof item.value == "string"){
					item.value = JSON.parse(item.value);
				}
				
				
			})

		},
		beforeDestroy() {

		},
		methods: {
			// 表单提交
			submitHandle() {
				this.formCallBack('submit',this.formData);			
			},
			// 关闭表单
			cancelHandle() {
				this.formCallBack('cancel');
			},
			sureClick(type,dataList){
				
				if(type == 'add' && this.clickTxt == '添加规则'){
					dataList.push(this.rules);
				}
				
				this.clickTxt = "添加规则";
				
				this.rules = {
					cz:"",
					allsend:"",
					ts:""
				}
				
				// console.log("dataList",dataList);
			},
			//编辑规则
			editRules(data){
				this.clickTxt = "确定";
				
				this.rules = data;
			}
		}
	}
</script>

<style scoped lang="less">
	
	.title {
		font-size: 20rem;
	}

	.formItem {
		margin-bottom: 20rem;
		// width: 560rem;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.formTil {
			display: inline-block;
			min-width: 200rem;
			width: auto;
			text-align: right;
			margin-right: 10rem;
			font-size: 14rem;
		}
	}

	.formControl {
		display: flex;
		justify-content: center;
		margin-top: 130rem;
	}
	
	.rules{
		border: 1rem dashed #ccc;
		display: flex;
		flex-direction: column;
		padding: 10rem 10rem;
		box-sizing: border-box;
		
		.ruleBtn{
			margin-left: auto;
			background: #FFE300;
			padding: 5rem 15rem;
			border-radius: 5rem;
			margin-top: 10rem;
			cursor: pointer;
		}
	}
	
	.rulesList{
		width: 500rem;
		border: 1rem dashed #ccc;
		padding: 10rem 10rem;
		box-sizing: border-box;
		margin-left: 20rem;
	}
	
</style>
