<template>
	<div class="indexPage">
		<!-- 侧边栏 -->
		<div class="aside" :style="'left: '+(isShowAside== true ? '0' : '-160')+'rem;'">

			<el-menu @select="handleSelect" style="width: 100%;" :default-active="`${menuIndex}`"
				class="el-menu-vertical-demo">

				<el-submenu :index="`${index}`" v-for="(item,index) in asideMenu" :key="index">
					<template slot="title">
						<span>{{item.title}}</span>
					</template>

					<el-menu-item :index="`${childIndex}`" v-for="(childItem,childIndex) in item.children"
						:key="childIndex">
						<!-- <span class="el-icon-user" style="font-size: 22rem;margin-top: 4rem;"></span> -->
						<span
							style="display: inline-block;white-space: nowrap;overflow: hidden;
							text-overflow: ellipsis;height: 100%;height: 50rem;line-height: 50rem;text-align: right;margin-left: 20rem;">{{childItem.title}}</span>
					</el-menu-item>

				</el-submenu>

			</el-menu>

			<!-- 侧边栏开关 -->
			<div class="asideBtn" @click="showAside">
				<span class="el-icon-s-fold iconBtn"></span>
			</div>
		</div>

		<div class="page" :style="pageStyle">
			<div class="controlArea" v-show="!isControl">
				<ul style="height: 100%;">
					<!-- :style="'transform:scale('+(activeIndex == index ? '1.1' : '1')+')'" -->
					<li v-for="(item,index) in navList" :key="index" @click="topNavHandle(item,index)"
						:style="'transform:scale('+(activeIndex == index ? '1' : '1')+');'">
						<div>
							<p>{{item.title}}</p>
							<p
								style="font-size: 16rem;margin-top: 5rem;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
								{{item.desc}}
							</p>
						</div>
						<div class="go">GO</div>
					</li>
				</ul>
			</div>

			<!-- 导航内容 -->
			<div v-show="isControl" style="height: 100%;">
				<transition name="el-zoom-in-top">
					<div v-if="isShow" style="height: 99%;position: relative;">
						<!-- <keep-alive> -->
						<router-view></router-view>
						<!-- </keep-alive> -->
					</div>
				</transition>
			</div>

			<!-- 数据概况 -->
			<div style="flex: 9;height: 100%;" v-show="!isControl">
				<div class="memberData">
					<ul>
						<li v-for="(item,index) in consoleList" :key="index">
							<div class="data">
								<h2>{{item.title}}</h2>
								<p>{{item.data}}</p>
							</div>
							<img v-if="index%3==0" src="../../static/dataBg1.png" alt="">
							<img v-else-if="index%3==1" src="../../static/dataBg2.png" alt="">
							<img v-else-if="index%3==2" src="../../static/dataBg3.png" alt="">
						</li>
					</ul>
				</div>
				<!-- <div class="saleData">
					<ul>
						<li class="todayData">
							<div class="title">今日营业额</div>
							<div class="data">
								<div class="number">
									<p style="font-size: 48rem;">826</p>
									<p style="margin-top: 38rem;">
										<span>较昨日</span>
									<div style="margin-top: 10px;">
										<img style="width: 21rem;height: 21rem;" src="../../static/shapeIcon.png"
											alt="">
										<i style="font-size: 19rem;color: #FF8A8A;margin-left: 10rem;">0.86%</i>
									</div>
									</p>
								</div>
								<div class="echarts" id="main" style="width: 230rem;height:350rem;"></div>
							</div>
						</li>
						<li class="monthData">
							<div class="title">本月营业额目标</div>
							<div class="echarts" id="month" style="width: 300rem;height:350rem;"></div>
						</li>
						<li class="dataTrend">
							<div class="title">本月营业额趋势</div>
							<div class="echarts" id="dataTrend" style="width: 650rem;height:430rem;"></div>
						</li>
					</ul>
				</div> -->
			</div>

			<el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
				<span>店铺的基础信息暂未设置，请前往设置</span>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="topNavHandle({path:'/basics/index'})">确 定</el-button>
				</span>
			</el-dialog>
		</div>
	</div>

</template>

<script>
	import * as echarts from 'echarts';
	import {
		mapState,
		mapMutations
	} from "vuex"

	export default {
		name: "index",
		menuName: "剧本管理",
		data() {
			return {
				loading: true, //全局加载状态
				isShow: false,
				isControl: false,
				scale: 1,
				activeIndex: 0,
				dialogVisible: false,
				component: "",
				//侧边栏开关
				isShowAside: false,
				//路由列表
				routesList: [],
				//侧边栏菜单
				asideMenu: [],
				//侧边栏菜单下标
				menuIndex: '0',
				consoleList: []
			}
		},
		provide() {
			return {
				layout: this
			}
		},
		computed: {
			...mapState({
				menuList: state => state.PerManage.menuList
			}),
			//顶部导航列表
			navList() {

				if (localStorage.getItem("shopInfo")) {
					var shopInfo = JSON.parse(localStorage.getItem("shopInfo"));
				}

				console.log("shopInfo", shopInfo.store_id)

				var activeIndex = localStorage.getItem("activeIndex");

				var menu = this.menuList[activeIndex].children;

				var newArr = [];

				menu.forEach(item => {

					var child = item.children.find(childItem => childItem.status == 1)

					var index = item.title.indexOf("-");
					var val = item.title;
					item.path = child ? child.path : "*";
					item.desc = val.slice(index + 1).trim();
					item.title = val.slice(0, index).trim();
					
					console.log("item",item);

					if (item.status == 1 && item.id != '40') {

						newArr.push(item);

					} else if (item.status == 1 && shopInfo.user_id == shopInfo.store_id) {

						newArr.push(item);

					}

				})

				return newArr;
			},
			pageStyle() {
				return `margin-left: ${(this.isShowAside== true ? 158 : 0)}rem;
				width:${(this.isShowAside== true ? 1700 : 1724)}rem;`
			}
		},
		created() {

			//获取菜单列表
			this.getMenuList();

			//获取控制台数据
			this.getConsoleData();

			//店铺基础设置
			// var shopList = JSON.parse(localStorage.getItem('shopInfo'))
			// if(!shopList.store_address){
			// 	this.dialogVisible = true
			// }

			this.isControl = false;

			this.$bus.$on("initData", res => {
				this.isControl = false;
				this.isShowAside = false;
			})

			// this.$nextTick(() => {
			// 	// 今日营业额
			// 	var chartDom = document.getElementById('main');
			// 	var myChart = echarts.init(chartDom);

			// 	var option;

			// 	option = {
			// 		xAxis: {
			// 			type: 'category',
			// 			data: []
			// 		},
			// 		yAxis: {
			// 			type: 'value',
			// 			splitLine: {
			// 				show: false
			// 			},
			// 			axisLabel: {
			// 				show: false
			// 			}
			// 		},
			// 		series: [{
			// 			data: [120, 200, 150, 80, 70, 110, 130],
			// 			type: 'bar'
			// 		}]
			// 	};

			// 	option && myChart.setOption(option);

			// 	//本月营业额目标
			// 	var month = document.getElementById('month');
			// 	var monthChart = echarts.init(month);
			// 	var monthOption;

			// 	monthOption = {
			// 		tooltip: {
			// 			trigger: 'item'
			// 		},
			// 		legend: {
			// 			bottom: '5%',
			// 			left: 'center'
			// 		},
			// 		series: [{
			// 			name: 'Access From',
			// 			type: 'pie',
			// 			radius: ['40%', '70%'],
			// 			avoidLabelOverlap: false,
			// 			itemStyle: {
			// 				borderRadius: 5,
			// 				borderColor: '#fff',
			// 				borderWidth: 2
			// 			},
			// 			label: {
			// 				show: false,
			// 				position: 'center'
			// 			},
			// 			emphasis: {
			// 				label: {
			// 					show: true,
			// 					fontSize: '20',
			// 					fontWeight: 'bold'
			// 				}
			// 			},
			// 			labelLine: {
			// 				show: false
			// 			},
			// 			//展示的表格数据
			// 			data: [
			// 				{
			// 					value: 1048,
			// 					name: '已完成'
			// 				},
			// 				{
			// 					value: 735,
			// 					name: '未完成'
			// 				}
			// 			]
			// 		}]
			// 	};

			// 	monthOption && monthChart.setOption(monthOption);

			// 	//本月营业趋势
			// 	var dataTrend = document.getElementById('dataTrend');
			// 	var dataTrendP = echarts.init(dataTrend);
			// 	var dataTrendOption;

			// 	dataTrendOption = {
			// 		xAxis: {
			// 			type: 'category',
			// 			data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
			// 		},
			// 		yAxis: {
			// 			type: 'value'
			// 		},
			// 		series: [{
			// 			data: [820, 932, 901, 934, 1290, 1330, 1320],
			// 			type: 'line',
			// 			smooth: true
			// 		}]
			// 	};

			// 	dataTrendOption && dataTrendP.setOption(dataTrendOption);
			// })
		},
		methods: {
			...mapMutations(["getMenuList"]),
			//获取控制台数据
			getConsoleData() {

				this.$api.apiInterface("Home", "console").console({}, res => {

					if (res.code == 0) {
						console.log("res", res);
						this.consoleList = res.data;
					}

				})

			},
			//侧边栏菜单跳转
			handleSelect(menuIndex) {

				// if(this.menuIndex != menuIndex){
				// 	//全局加载
				// 	this.loading = true;
				// }

				// console.log("menuIndex",menuIndex);
				this.menuIndex = menuIndex;

				var path = this.asideMenu[0].children[menuIndex].path;

				this.$router.push({
					path
				})

			},
			//侧边栏展示
			showAside() {
				if (localStorage.getItem("asideMenu")) {
					this.asideMenu = JSON.parse(localStorage.getItem("asideMenu"));
				}

				this.isShowAside = !this.isShowAside;
			},
			handleClose(done) {
				this.dialogVisible = false;
			},
			//生成侧边菜单
			gerAsideMenu(item, index) {

				this.asideMenu.push({
					title: item.title,
					path: item.path,
					children: []
				});

				item.children.forEach(childItem => {
					if (childItem.path != item.path && childItem.status == 1) {
						this.asideMenu[0].children.push(childItem);
					} else if (childItem.status == 1) {
						this.asideMenu[0].children.unshift(childItem);
					}
				})

				// console.log("this.asideMenu",this.asideMenu)

				//缓存侧边栏
				// localStorage.setItem("asideMenu",JSON.stringify(this.asideMenu));
			},
			topNavHandle(item, index) {
				console.log("item", item);

				//初始化侧边栏菜单
				this.asideMenu = [];

				this.menuIndex = 0;

				this.activeIndex = index;

				this.$router.push({
					path: item.path
				})

				if (item.children) {
					this.isShowAside = true;

					//生成侧边菜单
					this.gerAsideMenu(item, index);
				} else {

					if (item.path == '/basics/index') {

						this.isShowAside = true;

						var menu = this.menuList[0].children.filter(rItem => rItem.path == item.path)[0];

						//生成侧边菜单
						this.gerAsideMenu(menu);

						this.dialogVisible = false;
					} else {
						this.isShowAside = false;
					}

				}

				this.isControl = true;

				this.isShow = false;

				setTimeout(() => {
					this.isShow = true;
				}, 150)

				//全局加载
				this.loading = true;

			}
		}
	}
</script>

<style scoped lang="less">
	.indexPage {
		display: flex;
		height: 100%;
		position: relative;
	}

	/deep/ .el-submenu__title {
		padding-left: 20rem !important;
	}

	/deep/ .el-submenu__icon-arrow {
		right: 20rem;
	}

	// 侧边栏
	/deep/ .el-submenu__title {
		height: 70rem !important;
		line-height: 70rem !important;
	}

	/deep/ .el-submenu__icon-arrow {
		margin-top: -3px !important;
	}

	/deep/ .el-menu-item {
		padding-left: 20rem !important;
		width: 100% !important;
		height: 70rem !important;
		line-height: 70rem !important;
		min-width: 100% !important;
	}

	/deep/ .el-menu {
		border-right: 0;
	}

	/deep/ .el-card__body {
		padding-bottom: 0;
		height: 97%;
	}

	.aside {
		width: 160rem;
		height: 100%;
		background: #fff;
		position: relative;
		box-shadow: 6rem 5rem 10rem -4rem #ccc;

		transition: all .3s;
		position: absolute;
		top: 0;

		.asideBtn {
			width: 0;
			height: 50rem;
			border-left: 20px solid #fff;
			border-top: 15px solid transparent;
			border-bottom: 15px solid transparent;
			position: absolute;
			top: 50%;
			right: -20rem;
			transform: translateY(-50%);
			cursor: pointer;

			.iconBtn {
				position: absolute;
				top: 50%;
				right: -2rem;
				transform: translateY(-50%);
				color: #ccc;
				font-size: 26rem;
			}
		}
	}

	i {
		font-style: normal;
	}

	.page {
		padding: 30rem 60rem 0rem 34rem;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		transition: all .3s;
		height: 100%;
		overflow: hidden;

		.memberData {
			ul {
				width: 100%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				li {
					width: 508rem;
					height: 238rem;
					background: #fff;
					border-radius: 5rem;
					color: #333;
					padding: 40rem 0 0 35rem;
					box-sizing: border-box;
					display: flex;
					margin-bottom: 30rem;

					h2 {
						font-weight: normal;
						font-size: 24rem;
						white-space: nowrap;
					}

					p {
						font-size: 36rem;
						margin-top: 35rem;
					}

					img {
						width: 240rem;
						height: 90rem;
						margin-top: 80rem;
						margin-left: auto;
						margin-right: 40rem;
					}
				}
			}
		}

		.saleData {
			margin-top: 34rem;

			ul {
				width: 100%;
				display: flex;
				justify-content: space-between;

				li {
					border-radius: 5rem;
					background: #fff;
					padding: 28rem 28rem;
					box-sizing: border-box;

					.title {
						font-size: 18rem;
						color: #282828;
					}
				}

				.todayData {
					width: 420rem;
					height: 423rem;
					position: relative;

					.data {
						margin-top: 140rem;
						display: flex;

						.echarts {
							position: absolute;
							bottom: -28rem;
							right: 0;
						}
					}
				}

				.monthData {
					width: 384rem;
					height: 423rem;
					display: flex;
					flex-direction: column;

					.echarts {
						margin: 0 auto;
					}
				}

				.dataTrend {
					width: 725rem;
					height: 423rem;
					position: relative;

					.echarts {
						position: absolute;
						bottom: -28rem;
						left: 50%;
						transform: translate(-50%);
					}
				}
			}
		}

		.controlArea {
			margin-bottom: 26rem;
			// overflow: scroll;
			// overflow-x: scroll;
			// overflow-y: hidden;
			width: 100%;
			height: 120rem;

			ul {
				width: 100%;
				display: flex;
				// width: 2022rem;
				align-items: flex-start;
				white-space: nowrap;

				li {
					flex-shrink: 0;
					width: 312rem;
					height: 102rem;
					border-radius: 10rem;
					background: #fff;
					font-size: 26rem;
					color: #fff;
					padding-left: 50rem;
					padding-top: 20rem;
					padding-right: 38rem;
					box-sizing: border-box;
					cursor: pointer;
					user-select: none;
					position: relative;
					display: flex;
					justify-content: space-between;
					transition: all .3s;
					// transform-origin: center;
					margin-right: 24rem;

					.go {
						width: 43rem;
						height: 21rem;
						background: rgba(255, 255, 255, .5);
						border-radius: 15rem;
						text-align: center;
						line-height: 21rem;
						font-size: 14rem;
						color: #fff;
						margin-top: 10rem;
						position: absolute;
						top: 15rem;
						right: 30rem;
					}
				}
			}

			ul>li:nth-child(1) {
				background: url("../../static/tabBg2.png") no-repeat;
				background-size: cover;
			}

			ul>li:nth-child(2) {
				background: url("../../static/tabBg5.png") no-repeat;
				background-size: cover;
			}

			ul>li:nth-child(3) {
				background: url("../../static/tabBg1.png") no-repeat;
				background-size: cover;
			}

			ul>li:nth-child(4) {
				background: url("../../static/tabBg3.png") no-repeat;
				background-size: cover;
			}

			ul>li:nth-child(5) {
				background: url("../../static/tabBg4.png") no-repeat;
				background-size: cover;
			}

			ul>li:nth-child(6) {
				background: url("../../static/tabBg6.png") no-repeat;
				background-size: cover;
			}

			ul>li:nth-child(7) {
				background: url("../../static/tabBg8.png") no-repeat;
				background-size: cover;
			}
		}

		.controlArea::-webkit-scrollbar {
			display: none;
		}

	}
</style>
