/**
 * 内置挂载集合
 */
//内置API
import $api from '../request/api.js'
//配置
import $config from '@/config/config'
//内置函数库
import $Fun from '../common/func'

import $Diy_prototype from '../common/prototype'
export default function run(Vue){
    Vue.prototype.$api = $api;
    Vue.prototype.$config = $config;
    Vue.prototype.$F = $Fun;
    $Diy_prototype(Vue);
}