<template>
	<div class="page">
		<el-container>
			<el-header>
				<div class="logo">
					<img src="../static/logo.png" alt="">
					<h1>门店后台管理系统</h1>
										
					<!-- 面包屑 -->
					<!-- <el-breadcrumb separator="/">
						<transition-group name="bread">
							<el-breadcrumb-item v-for="(item,index) in breadNav" :key="index">
							  <span v-if="index == 0">{{item.meta.title}}</span>
							  <span v-else>{{item.meta.title}}</span>
							</el-breadcrumb-item>
						</transition-group>
					  
					</el-breadcrumb> -->
					
				</div>
				<div class="userInfo">
					<el-dropdown trigger="click">
						<div class="info">
							<img class="avatar" src="../assets/logo.png" />
							<span style="margin: 0 10rem;">{{shopInfo.username}}</span>
							<i class="el-icon-arrow-down el-icon--right"></i>
						</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item>个人中心</el-dropdown-item>
							<el-dropdown-item @click.native="loginOut">退出</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</el-header>
			<el-main>
				<div class="aside">
					<div class="asideList">
						<div class="asideItem" v-for="(item,index) in asideList" :key="index"
						:class="{'activeClass':activeIndex == index}" @click="menuSelect(item,index)">
							<span>
								<img v-if="index == 0" src="../static/storeIcon.png" alt="">
								<img v-else src="../static/memberIcon.png"/>
								<i>{{item.title}}</i>
							</span>
						</div>
					</div>
				</div>
				
				<div class="main" style="position: relative;">
					<router-view :key="refreshIndex"></router-view>
				</div>
			</el-main>
		</el-container>
		
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from 'vuex'
	
	export default{
		name:"layout",
		data() {
			return{
				asideList:[
					{title:"门店",path:"/index/index"},
					{title:"会员",path:"/member/index"}
				],
				activeIndex:0,
				shopInfo:{},
				breadNav:[],//面包屑
				routePath:"",
				flag:true,
				refreshIndex:""
			}
		},
		watch:{
			$route(newVal,oldVal){
				
				this.routePath = newVal.fullPath;
				
				this.getBreadNav();
				
				if(newVal.name == "member" && localStorage.getItem("userInfo")){
					this.activeIndex = 1;
				}
				
			}
		},
		provide(){
		      return{
		        layout:this,
		      }
		},
		created() {
			
			localStorage.setItem("activeIndex",this.activeIndex);
			
            if(localStorage.getItem("shopInfo")){
				this.shopInfo = JSON.parse(localStorage.getItem("shopInfo"));
			}
			
		},
		methods:{
			refresh(){
				this.$bus.$emit("initData",1);
				this.refreshIndex = Math.random(0,999);
				
			},
			//获取面包屑导航
			getBreadNav(){
				// console.log("breadNav",this.$route.matched);
				this.breadNav = this.$route.matched.filter(item=>item.meta && item.meta.title);
			},
			menuSelect(item,index){
				
				this.activeIndex = index;
				
				this.$bus.$emit("initData",1);
				
				localStorage.setItem("activeIndex",this.activeIndex);
				
				this.$router.push({
					path:item.path
				})
				
			},
			//退出登录
			loginOut(){
				localStorage.clear("token");
				window.location.replace("/");
			}
		}
	}
</script>

<style scoped lang="less">
	
	.activeClass{
		background: #FFE300 !important;
		color: #333 !important;
	}
	
	.page {
		height: 100%;
	}
	
	i{
		font-style: normal;
	}

	/deep/.el-container {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	/deep/.el-header {
		width: 100%;
		background-color: #fff;
		height: 64rem !important;
		display: flex;
		align-items: center;
		padding: 0 32rem !important;
		justify-content: space-between;

		.logo {
			display: flex;
			align-items: center;

			h1 {
				margin-left: 62rem;
				color: #333;
				font-weight: normal;
				font-size: 25rem;
			}

			img {
				width: 63rem;
				height: 45rem;
			}
		}

		.userInfo {
			img{
				width: 42rem;
				height: 42rem;
			}
			
			.info {
				font-size: 20rem;
				cursor: pointer;
				user-select: none;
				display: flex;
				align-items: center;
			}
		}
	}

	/deep/.el-main {
		display: flex;
		flex: 1;
		padding: 0;
	}

    .aside{
		position: relative;
	}

	.asideList {
		display: flex;
		flex-direction: column;
		height: 100%;
		background: #282828;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 99;

		.asideItem {
			width: 60rem;
			height: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			user-select: none;
			color: #fff;
			
			span{
				display: flex;
				flex-direction: column;
				width: 26rem;
				align-items: center;
				text-align: center;
				font-size: 25rem;
				img{
					width: 26rem;
					height: 26rem;
					margin-bottom: 15rem;
				}
			}
		}
		
	}
	
	.main{
	    flex: 1;
		margin-left: 60rem;
		height: 100%;
	}
	
	.bread-enter-active,
	.bread-leave-active{
		transition: all 0.5s;
	}
	
	.bread-enter,
	.bread-leave-active{
		opacity: 0;
		transform: translateX(40rem);
	}
	
	.bread-leave-active{
		// position: absolute;
	}
	
	.refresh{
		display: block;
		font-size: 30rem;
		margin-top: 5rem;
		margin-left: 20rem;
		cursor: pointer;
		user-select: none;
	}
</style>
