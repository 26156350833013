<template>
	<div>
		<div class="topNav">
			<div style="width: 100%;display: flex;align-items: center;justify-content: space-between;">
				<div style="display: flex;align-items: center;" @click="addCoupon">
					<img src="../../static/addIcon.png" alt="">
					<span>添加优惠卷</span>
				</div>
				<el-button style="background-color: #FFE300 ;border: none;color: black;" 
					@click="getCouponSending"> 优惠券发送状态
				</el-button>
			</div>
		</div>
		
		<el-card>

			<el-table :data="couponList" stripe style="width: 1650rem" v-loading="load">
				<el-table-column prop="id" label="id" width='50' align='center'>
				</el-table-column>
				<el-table-column prop="name" label="优惠卷名称" align='center' width="130">
				</el-table-column>
				<el-table-column prop="type" sortable label="类型" align='center'>
				</el-table-column>
				<el-table-column   label="优惠" align='center' width='100'>
					<template slot-scope="scope" >
						<span v-if="scope.row.type == '立减'">{{scope.row.discount_money}}元</span>
						<span v-else>{{scope.row.discount_money}}折</span>
					</template>
				</el-table-column>
				<el-table-column label="生效剧本"  align='center'>
					<template slot-scope="scope" >
						
						  <el-button v-if="scope.row.books_ids && scope.row.books_ids.length >= 1 "   style="background-color: #FFE300 ;border: none;" circle size='mini' icon='el-icon-notebook-2'  @click="bookCouponBtn(scope.row)"></el-button>
						<span v-else>所有</span>
					</template>
					
				</el-table-column>
				<el-table-column label="过期固定天数" width="130" align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.day && scope.row.day >= 1 ">
							<span>
								{{scope.row.day}}
							</span>
						</span>
						<span v-else>无</span>
					</template>
				</el-table-column>
				<el-table-column label="过期统一时间" width="165" align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.end_time != 0">
							<span>
								{{scope.row.end_time}}
							</span>
						</span>
						<span v-else>无</span>
					</template>
				</el-table-column>
				<el-table-column prop="start_time" sortable label="生效时间" width="165" align='center'>
				</el-table-column>
				
				<el-table-column label="操作" width='150' align='center' fixed="right">
					<template slot-scope="scope">
						
							<el-button type="primary"circle icon="el-icon-edit" size='mini' @click="changeCoupon(scope.row)">
							</el-button>
							<el-button style="margin-right: 15rem;" type="success"circle icon="el-icon-position" size='mini' @click="SendCoupon(scope.row)">
							</el-button>	
							<el-popconfirm confirm-button-text='确定' cancel-button-text='不用了' icon="el-icon-info"
								icon-color="red" :title="`确定删除${scope.row.name}商品？`" @confirm='delCoupon(scope.row.id)'>
								<el-button type="danger" slot="reference" circle icon="el-icon-delete" size='mini'>
								</el-button>
							</el-popconfirm>
							
					</template>
				</el-table-column>
			</el-table>
			
			<dir style="text-align: center;">
				<el-pagination @current-change="couponhandleCurrentChange" :page-size="12"
					layout="prev, pager, next,->, total" :total="+couponTotal">
				</el-pagination>
			</dir>
			
			
		</el-card>

		<!-- 添加优惠卷 -->
		<el-dialog :title="title" :visible.sync="couponAdd" width="60%">

			<el-form ref="form" :model="form" label-width="240rem" size='mini'>

				<el-form-item label="优惠卷名称:">
					<el-input class="inputW" v-model="form.name" placeholder="请选择优惠卷名称"></el-input>
				</el-form-item>

				<el-form-item label="优惠卷类型:">
					<el-select class="inputW" v-model="form.type" placeholder="请选择优惠卷类型">
						<el-option label="立减" value="1"></el-option>
						<el-option label="折扣" value="2"></el-option>
					</el-select>

				</el-form-item>

				<el-form-item label="优惠卷折扣:">
					<el-input class="inputW" type="number" v-model="form.discount_money" placeholder="请选择优惠卷折扣">
					</el-input>
					<div class="tip">
						{{form.type==1 ? '提示：输入立减金额' : '提示：选择的优惠卷类型为折扣的时候，请填写0~10的数字。如：填写的是9.8，为9.8折扣'}}
					</div>
				</el-form-item>

				<el-form-item label="剧本选择:">
					<el-button style="background-color: #FFE300 ;border: none;color: black;" @click="getBook"> 选择剧本
					</el-button>
					<div class="tip" >
						提示：不选择剧本时，绑定所有的剧本
					</div>
				</el-form-item>

				<el-form-item v-if="books_name && books_name.length > 0 " label="剧本:">
					<div>
						<el-tag v-for="(item,index) in books_name" :key="index" closable :disable-transitions="false"
							@close="delBookID(index)">
							{{item}}
						</el-tag>
					</div>
				</el-form-item>
				
				<el-form-item label="优惠卷过期:">
					<el-radio-group v-model="radio" @input='radioClick'>
						<el-radio label='1'>过期固定天数</el-radio>
						<el-radio label='2'>过期统一时间</el-radio>
					</el-radio-group>
				</el-form-item>
				
				<el-form-item v-if="radio == 1" label="过期固定天数:">
					<el-input class="inputW" type='number' v-model="form.day" placeholder="请选择优惠卷固定天数"></el-input>
				</el-form-item>
				
				<el-form-item v-if="radio == 2" label="过期统一时间:">
					<el-date-picker class="inputW" v-model="form.end_time" type="datetime" placeholder="选择优惠劵过期时间">
					</el-date-picker>
				</el-form-item>
				
				<el-form-item label="优惠劵生效时间:">
					<el-date-picker class="inputW" v-model="form.start_time" type="datetime" placeholder="选择优惠劵生效时间">
					</el-date-picker>
				</el-form-item>
				
			</el-form>


			<span slot="footer" class="dialog-footer">
				<el-button @click="couponAdd = false">取 消</el-button>
				<el-button type="primary" :loading="loading" @click="couponClick">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 选择剧本-->
		<el-dialog title="选择剧本" :visible.sync="bookAdd" width="50%">
			<div style="margin-bottom: 30rem;display: flex;align-items: center;justify-content: space-between;">
				<div>
					剧本名称：
					<el-input style="width: 200rem;" v-model="book_title" placeholder="请填写剧本名称" size='mini'></el-input>
				</div>
				
				<div>
					发售类型：
					<el-select style="width: 120rem;" v-model="book_status" placeholder="请选择发售类型" size='mini'>
						<el-option label="全部" value=""></el-option>
						<el-option label="城限" value="城限"></el-option>
						<el-option label="盒装" value="盒装"></el-option>
						<el-option label="独家" value="独家"></el-option>
					</el-select>
					
				</div>
				
				<div>
					<el-button style="background-color: #FFE300 ;border:1rem solid #FFE300 ;color: black;" icon='el-icon-search' size='mini' @click="getBookList"></el-button>
					<el-button style="background-color: #FFE300 ;border:1rem solid #FFE300 ;color: black;" icon='el-icon-refresh' size='mini' @click="getBook"></el-button>
				</div>
			</div>
			<el-table v-loading="load" ref="multipleTable" :data="bookList" tooltip-effect="dark" style="width: 100%" size= 'mini'
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="book_id" sortable label="剧本id"></el-table-column>
				<el-table-column prop="title" label="剧本名称"></el-table-column>
				<el-table-column label="剧本图片">
					<template slot-scope="scope">
						<img class="bookImg" :src="scope.row.headPic" alt="">
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @current-change="bookhandleCurrentChange" :page-size="4" layout="prev, pager, next, ->,total"
				:total="+bookTotal">
			</el-pagination>
			<span slot="footer" class="dialog-footer">
				<el-button @click="bookAdd = false" size='small'>取 消</el-button>
				<el-button type="primary" @click="bookClick" size='small'>确 定</el-button>
			</span>
		</el-dialog>
		
		<!-- 发送优惠卷 -->
		<el-dialog title="发送优惠卷" :visible.sync="couponSend" width="60%">
			<el-form ref="form" :model="sendForm" label-width="240rem" size='mini'>

				<el-form-item label="优惠卷名称:">
					<span>{{sendForm.name}}</span>
				</el-form-item>

				<el-form-item label="优惠卷类型:">
					<span>{{sendForm.type}}</span>
				</el-form-item>

				<el-form-item label="优惠卷折扣:">
					<span>{{sendForm.discount_money}}</span>
				</el-form-item>

				<el-form-item label="发送用户:">
					<el-radio-group v-model="userRadio">
						<el-radio label='1'>选择用户</el-radio>
						<el-radio label='2'>所有用户</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item v-show="userRadio == 1" label="选择用户ID:">
					<el-button style="background-color: #FFE300 ;border: none;color: black;" size="small"
						@click="getUser"> 选择用户
					</el-button>
					<div style="margin-top: 20rem;">
						<el-tag  v-for="(tag,index) in userID" :key="index" closable :disable-transitions="false"
							@close="handleClose(tag)">
							{{tag}}
						</el-tag>
					</div>
				</el-form-item>

			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="couponSend = false">取 消</el-button>
				<el-button type="primary" :loading="loading" @click="couponSendClick">确 定</el-button>
			</span>
		</el-dialog>
		
		<!-- 用户列表 -->
		<el-dialog title="选择用户" :visible.sync="userDialog" width="50%">
			
			<div style="margin-bottom: 30rem;">
				<el-input style="width: 220rem;" v-model="userForm.mobile" placeholder="请填写用户手机号" size='mini'></el-input>
				<el-button style="background-color: #FFE300 ;border:1rem solid #FFE300 ;color: black;" icon='el-icon-search' size='mini' @click="getUserList"></el-button>
				<el-button style="background-color: #FFE300 ;border:1rem solid #FFE300 ;color: black;" icon='el-icon-refresh' size='mini' @click="getUser"></el-button>
			</div>
			
			<el-table v-loading="load" ref="multipleTable" :data="userList" tooltip-effect="dark" style="width: 100%" size = 'mini'  max-height="400" stripe
				@selection-change="handleSelectionUser">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="user_id" sortable label="用户id"align='center'></el-table-column>
				<el-table-column  label="用户昵称"align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.nikename" >{{scope.row.nikename}}</span>
						<span v-else>暂未设置</span>
					</template>
				</el-table-column>
				<el-table-column label="DM" align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.is_dm == 0 ">
							无为
						</span>
						<span v-else>胜任</span>
					</template>
				</el-table-column>
				<el-table-column prop="mobile" label="用户手机号"align='center'></el-table-column>
				
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="userDialog = false">取 消</el-button>
				<el-button type="primary" @click="userDialog = false">确 定</el-button>
			</span>
		</el-dialog>
		
		<!-- 优惠卷状态 -->
		<el-dialog title="优惠卷发送状态" :visible.sync="couponing" width="70%" :before-close="cancelClick">
			<el-table v-loading="load"  :data="couponingList" stripe style="width: 100%"  max-height="400">
				<el-table-column prop="coupon_id" sortable label="优惠卷ID" align='center' width='120'></el-table-column>
				<el-table-column prop="name" label="优惠卷名称"align='center' width='150'></el-table-column>
				<el-table-column  label="状态"align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.status == 0 ">
							发送成功
						</span>
						<span v-else>发送失败</span>
					</template>
				</el-table-column>
				<el-table-column prop="all_sum" label="总数量"align='center'></el-table-column>
				<el-table-column prop="in_sum" label="已发送数量"align='center'></el-table-column>
				<el-table-column prop="create_time" sortable label="创建时间"align='center' width='180'></el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelClick()">取 消</el-button>
				<el-button type="primary" @click="cancelClick()">确 定</el-button>
			</span>
		</el-dialog>
		
		<!-- 生效剧本 -->
		<el-dialog title="生效剧本" :visible.sync="bookDialog" width="50%">
			<el-table :data="bookCouponList"  style="width: 100%" size= 'mini' height="500">
				<el-table-column prop="id" label="剧本id"></el-table-column>
				<el-table-column prop="title" label="剧本名称"></el-table-column>
				<el-table-column label="剧本图片">
					<template slot-scope="scope">
						<img class="bookImg" :src="scope.row.headPic" alt="">
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name:'index_sendCoupon',
		menuName:"优惠券列表",
		data() {
			return {
				title:'',
				radio: '',
				couponAdd: false,
				bookAdd: false,
				form: {
					name: '', //名称
					type: '', //1立减2折扣
					discount_money: '', //折扣金额
					day: '', //选其一 固定天数
					end_time: '', //选其一 统一到期时间
					start_time: '', //设置生效时间 0 领取时间
					books_ids: [], //[]代表全部 [1,2,3,4,5]则是剧本ID JSON
				},
				loading: false,
				load: false,
				couponList: [],
				couponTotal: '',
				couponpage: 1,
				bookList: [],
				bookTotal: '',
				bookpage: 1,
				books_ids: [],
				books_name:[],
				book_title:'',
				book_status:'',
				//发送优惠卷
				couponSend: false,
				userDialog:false,
				userList:[],
				userTotal:'',
				userForm:{
					mobile:'',
					is_dm:-1,
				},
				sendForm: {},
				userRadio: '1',
				userID: [],
				inputVisible: false,
				inputValue: '',
				userCoupon:{
					id: '',
					user_id: ''
				},
				//优惠卷发送状态
				couponing:false,
				couponingList:[],
				setInter:null,
				
				//生效剧本
				bookDialog:false,
				bookCouponList:[]
			}
		},

		components: {

		},
		created() {
			this.getCouponList()
			this.CouponSendingList()
		},
        inject:["layout"],
		methods: {
			//转换类型
			couponType(val) {
				if (val == 1) {
					return val = '立减'
				}
				if (val == 2) {
					return val = '折扣'
				}
			},
			couponTypeNum(val) {
				if (val == '立减') {
					return val = 1
				} else if (val == '折扣') {
					return val = 2
				} else {
					return val
				}
			},

			formatTime(val) {
				var date = new Date(val * 1000);
				var Y = date.getFullYear()
				var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
				var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
				var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
				var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
				var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
				return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
			},

			//添加优惠价弹窗
			addCoupon() {
				this.form = {}
				this.books_ids = []
				this.books_name = []
				this.couponAdd = true
				this.title = '添加优惠卷内容'
			},

			//优惠卷radio
			radioClick(e) {
				if (e == 1) {
					this.form.end_time = ''
				} else {
					this.form.day = ''
				}
			},

			//优惠卷保存
			couponClick() {
				
				delete this.form.books_ids_val
				
				this.loading = true

				if (!this.form.name) {
					this.loading = false
					return this.$message.error('优惠卷名称暂未设置，请先完善!');
				}
				if (!this.form.type) {
					this.loading = false
					return this.$message.error('优惠卷类型暂未设置，请先完善!');
				}
				if (!this.form.discount_money) {
					this.loading = false
					return this.$message.error('优惠卷折扣金额暂未设置，请先完善!');
				}

				if (this.form.type == 2 && (this.form.discount_money > 10 || this.form.discount_money < 0)) {
					this.loading = false
					return this.$message.error('选择的优惠卷类型为折扣的时候，请填写0~10的数字');
				}

				if (!this.form.start_time) {
					this.loading = false
					return this.$message.error('优惠卷生效时间暂未设置，请先完善!');
				} else {
					this.form.start_time = Math.ceil(new Date(this.form.start_time).getTime() / 1000)
				}


				if (this.radio == 1 && this.form.day <= 0) {
					this.loading = false
					return this.$message.error('设置的过期固定天数必须大于0天');
				}

				if (this.radio == 2) {

					this.form.end_time = Math.ceil(new Date(this.form.end_time).getTime() / 1000)

					if (this.form.end_time <= this.form.start_time) {
						this.loading = false
						return this.$message.error('设置的过期统一时间必须大于生效时间');
					}
				}

				this.form.type = this.couponTypeNum(this.form.type)

				this.form.books_ids = JSON.stringify(this.books_ids)

				this.$api.setCoupon(this.form, (res) => {
					this.loading = false
					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，设置成功',
							type: 'success'
						});
						this.couponAdd = false
						this.getCouponList()
					} else {
						this.$message.error(res.msg);
					}
				})

			},

			//获取优惠卷列表
			getCouponList() {
				this.load = true
				this.$api.getCoupon({
					page: this.couponpage,
					limit: 12
				}, (res) => {
					this.load = false
					this.layout.loading =false;
					if (res.code == 0) {
						this.couponTotal = res.data.count
						this.couponList = res.data.list

						this.couponList.forEach((item) => {

							if (item.end_time > 0) {
								item.end_time = this.formatTime(item.end_time)
							}

							item.type = this.couponType(item.type)

							item.start_time = this.formatTime(item.start_time)

							if (item.books_ids) {
								item.books_ids = JSON.parse(item.books_ids)
							}
							return item
						})
						
						
					} else {
						this.$message.error(res.msg);
					}
				})
			},

			//优惠劵分页
			couponhandleCurrentChange(val) {
				this.load = true
				this.couponpage = val
				this.getCouponList()
			},

			//修改指定优惠劵
			changeCoupon(val) {
				
				this.title = '修改优惠卷内容'
				
				this.form = JSON.parse(JSON.stringify(val))
				
				this.form.books_ids_val.forEach(item => {
					this.books_ids.push(item.id)
					
					this.books_name.push(item.title)
				})
				this.books_name = Array.from(new Set(this.books_name))
				this.books_ids = Array.from(new Set(this.books_ids))
				
				if (this.form.day > 0) {
					this.radio = '1'
				} else {
					this.radio = '2'
				}


				this.couponAdd = true
			},
			//删除指定优惠卷
			delCoupon(val) {
				this.$confirm(`确认删除${val.name}？`)
					.then(_ => {
						this.$api.delCoupon({
							id: val.id
						}, (res) => {
							if (res.code == 0) {
								this.$message.success({
									message: '恭喜你，删除成功',
									type: 'success'
								});
								this.getCouponList()
							} else {
								this.$message.error(res.msg);
							}

						})
					})
					.catch(_ => {});
			},
			//发送优惠卷弹窗
			SendCoupon(val) {
				this.couponSend = true
				this.userID = []
				this.sendForm = JSON.parse(JSON.stringify(val))
			},

			//获取用户Id
			getUser(){
				this.userDialog = true
				this.userForm.mobile = ''
				this.getUserList()
			},
			getUserList(){
				this.load = true
				this.$api.getUserList(this.userForm,(res)=>{
					this.load = false
					if (res.code == 0) {
						this.userList = res.data.list
						this.userTotal = res.data.count
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			//选择用户Id
			handleSelectionUser(val){
				val.forEach(item => {
					this.userID.push(item.user_id)
				})
				
				this.userID = Array.from(new Set(this.userID))
				
				if(this.userID.length > 10){
					this.$message.error('选择用户最多数量为10个，请不要超选');
					this.userID = []
				}
			},
			handleClose(tag) {
				this.userID.splice(this.userID.indexOf(tag), 1);
			},
			//确定发送优惠卷
			couponSendClick() {
				
				this.loading = true
				this.userCoupon.id = this.sendForm.id
				
				if(this.userID.length > 10){
					this.loading = false
					return this.$message.error('选择用户最多数量为10个，请不要超选');
				}
				
				if(this.userRadio == 2){
					this.userCoupon.user_id = 0
				}else{
					this.userCoupon.user_id = JSON.stringify(this.userID)
				}
				

				
				this.$api.setCouponSend(this.userCoupon,(res)=>{
					this.loading = false
					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，发送成功',
							type: 'success'
						});
						this.couponSend = false
						
						this.CouponSendingList()
					} else {
						this.$message.error(res.msg);
					}
				})
			},

			//剧本列表按钮
			getBook() {
				this.book_status = ''
				this.book_title = ''
				this.bookAdd = true
				this.getBookList()
			},
			getBookList(){
				this.load = true
				this.$api.getBookList({
					page: this.bookpage,
					limit: 4,
					title:this.book_title,
					specification:this.book_status
				}, (res) => {
					this.load = false
					if (res.code == 0) {
						this.bookList = res.data.list
						this.bookTotal = res.data.count
					}
				})
			},
			
			//book分页
			bookhandleCurrentChange(val) {
				this.load = true
				this.$api.getBookList({
					page: val,
					limit: 4
				}, (res) => {
					this.load = false
					if (res.code == 0) {
						this.bookList = res.data.list
					}
				})
			},
			//保存book选择
			handleSelectionChange(val) {
				console.log(val);
				val.forEach(item => {
					this.books_ids.push(item.book_id)
					
					this.books_name.push(item.title)
				})
				
				this.books_name = Array.from(new Set(this.books_name))
				this.books_ids = Array.from(new Set(this.books_ids))
				
			},
			bookClick() {
				this.bookAdd = false
			},
			delBookID(index) {
				this.books_name.splice(index, 1)
				this.books_ids.splice(index, 1)
			},
		
			//优惠卷发送状态弹窗
			getCouponSending(){
				this.couponing = true
				
				this.setInter =  setInterval(()=>{
					this.CouponSendingList()
				},2000)

				
			},
			//优惠卷发送状态列表
			CouponSendingList(){
				this.$api.getCouponSending({},(res)=>{
					
					if (res.code == 0) {
						this.couponingList = res.data
						this.couponingList.forEach(item =>{
							item.create_time = this.formatTime(item.create_time )
							return item
						})
						
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			//关闭优惠卷发送状态弹窗
			cancelClick(){
				
				this.couponing = false

				clearInterval(this.setInter)
			},
			
			//生效剧本
			bookCouponBtn(val){
				
				this.bookDialog = true
				
				this.bookCouponList = val.books_ids_val
				
			}
		}

	}
</script>

<style scoped lang="less">
	.el-tag+.el-tag {
		margin-left: 10px;
	}

	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
	}


	/deep/.el-card__body,
	.el-main {
		padding: 0 20rem;
		max-height: 980rem;
		height: auto;
	}

	/deep/.el-dialog__body {
		padding: 30rem 20rem 0px;
		color: #606266;
		font-size: 14px;
		word-break: break-all;
	}

	.bookImg {
		width: 110rem;
		height: 148rem;
	}

	.topNav {
		display: flex;
		font-size: 16rem;
		color: #409EFF;
		align-items: center;
		margin-bottom: 20rem;
		margin-left: 25rem;
		cursor: pointer;
		user-select: none;

		img {
			width: 26rem;
			height: 26rem;
			margin-right: 12rem;
		}
	}

	.tip {
		font-size: 12px;
		color: #C0C4CC;

		div {
			line-height: 0;
		}
	}

	.inputW {
		width: 380rem;
	}
</style>
