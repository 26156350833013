<template>
	<div class="noAuth">
		<img src="../../static/noAuth.png" alt="">
		<span class="txt">~无权限访问</span>
		<!-- <el-button type="primary" style="margin-top: 50rem;" @click="backHome">返回首页</el-button> -->
	</div>
</template>

<script>
	export default{
		data() {
			return{
				
			}
		},
		methods:{
			backHome(){
				this.$router.push({
					path:"/"
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.noAuth{
		height: 100%;
		
		display: flex;
		flex-direction: column;
		align-items: center;
		// justify-content: center;
		
		img{
			width: 700rem;
			height: 700rem;
			margin-top: 30rem;
		}
		
		.txt{
			font-size: 48rem;
			color: #333;
			margin-top: 30rem;
		}
	}
</style>