<template>
 <div class="scanbox" v-if="showScanbox">
   <div class="scanbox_pub" @click="showScanbox=false"></div>
   <div class="scanbox_main">
     <input ref="inputVal" type="password" @focus="cfocus" @keydown="scanSub" @blur="cblur" v-model="usercode" autofocus />
	 <!-- <img src="../../static/scanCode.png" alt=""> -->
     <b v-if="!isscan">鼠标点击我获得焦点</b>
     <b v-else>请将{{title}}<br>放至小白盒扫描</b>
   </div>
 </div>
</template>

<script>
export default {
  name: 'scanbox',
  props:{
    title:{
      type:String,
      default:'会员码'
    },
    wxToRes:{
      type:Function,
      default:null
    }
  },
  mounted() {
    console.log(this.title)
  },
  data () {
    return {
      isscan:false,
      usercode:'',
      showScanbox:false
    }
  },
  methods:{
    show(){
      this.usercode = ''
      this.showScanbox = true
      this.$nextTick(function(){
        this.$refs.inputVal.focus();
      })
    },
    hide(){
      this.showScanbox = false
    },
    scanSub(e){
      if(e.key == 'Enter'){
		  // console.log("scanSub",e)
        this.wxToRes(this.usercode)
      }
    },
    cfocus(e){
      // console.log(e,'进入')
      this.isscan = true
    },
    cblur(e){
      // console.log(e,'离开')
       this.isscan = false
    },
  }
}
</script>

<style scoped lang="less">
  .scanbox{
    position: fixed;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    &_pub{
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.5);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
    &_main{
      width: 70vw;
      height: 70vh;
      background: #ffffff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      border-radius: 20px;
      margin: auto;
      display: flex;
      align-items: center;

      input{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        background: rgba(0,0,0,0);
        border-radius: 20px;
        color: rgba(0,0,0,0);
      }

      b{
        display: block;
        text-align: center;
        font-size: 40px;
        color: #192945;
        width: 100%;
      }
    }
  }
</style>
