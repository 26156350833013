<template>
	<div class="nav">
		<el-card class="body">
			<!-- 返回按钮 -->
			<el-button v-if="this.setPrice==1" size="mini" @click="backPage" type="primary" class="el-icon-arrow-left">返回</el-button>
			
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="商户基本设置" name="first">
					<el-form ref="form" :model="shopInfo" label-width="230rem">
						<ul>
							<li>
								<el-form-item label="商户名称:">
									<el-input v-model="shopInfo.store_name" placeholder="请输入商户名称"></el-input>
								</el-form-item>
							</li>

							<li>
								<el-form-item label="商户手机号:">
									<div class="telephone" v-for="(item,index) in mobiles " :key="index"
										:style="index !=0 ? 'margin-top: 20rem' :'' ">
										<el-input style="width: 260rem;" v-model="item.title" placeholder='请输手机号名称'>
										</el-input>
										<el-input style="width: 708rem;" v-model="item.mobile" placeholder='请输入手机号'>
										</el-input>
										<img v-if="index == 0" class="addIcon" src="../../static/addIcon.png" alt=""
											@click="addTel">
										<img v-else class="addIcon" src="../../static/delIcon.png" alt=""
											@click="delTel(index)">
									</div>
								</el-form-item>
							</li>

							<li>
								<el-form-item label="商户地址:">
									<div class="telephone">
										<el-input style="width: 1168rem;" v-model="shopInfo.store_address"
											placeholder="请输入商户地址"></el-input>
										<img class="local" src="../../static/sp_local.png" alt="地图" @click="map = true">
									</div>
								</el-form-item>
							</li>
							<li>
								<el-form-item label="经度:">
									<el-input v-model="shopInfo.longitude" placeholder="请输入商户经度"></el-input>
								</el-form-item>
								<el-form-item label="纬度:">
									<el-input v-model="shopInfo.latitude" placeholder="请输入商户纬度"></el-input>
								</el-form-item>
							</li>
							<li>
								<el-form-item label="wifi名字:">
									<el-input v-model="shopInfo.wifi_name" placeholder="请输入wifi名字"></el-input>
								</el-form-item>
								<el-form-item label="wifi密码:">
									<el-input v-model="shopInfo.wifi_pwd" placeholder="请输入wifi密码"></el-input>
								</el-form-item>
							</li>
							<li>
								<ImageBox title="LOGO" :image="shopInfo.logo" :toRes="(tourl)=>{shopInfo.logo = tourl}"></ImageBox>
							</li>
							<li>
								<ImageBox title="首页背景图" :image="shopInfo.head_pic" :toRes="(tourl)=>{shopInfo.head_pic = tourl}"></ImageBox>
							</li>

							<li>
								<el-form-item label="积分别名:">
									<el-input v-model="shopInfo.integral_name" placeholder="请输入积分别名"></el-input>
								</el-form-item>
							</li>
							<li>
								<el-form-item label="滚动公告:">
									<el-input :rows=5 class="textarea" type="textarea" v-model="shopInfo.notice_content"
										placeholder="请输入滚动公告">
									</el-input>
								</el-form-item>
							</li>
							<li>
								<el-form-item label="欢迎语:">
									<div>
										<el-input :rows='8' class="textarea" type="text" v-model="shopInfo.welcome"
											placeholder="请输入欢迎语">
										</el-input>
										<div class="tagList">
											<el-tag v-for="(item,index) in tagList" :key="index" type="warning" style="margin-right: 10rem;cursor: pointer;" @click="addTags(item)">{{item.name}}</el-tag>
										</div>
									</div>
									
								</el-form-item>
							</li>
							<li>
								<el-form-item label="企业客服链接:">
									<el-input style="width: 700rem;" v-model="shopInfo.kefu_link" placeholder="请设置企业客服链接"></el-input>
								</el-form-item>
							</li>
							<li>
								<el-form-item label="企业微信ID:">
									<el-input style="width: 700rem;" v-model="shopInfo.enterprise_id" placeholder="请设置企业ID"></el-input>
								</el-form-item>
							</li>
							<li>
								<el-form-item label="是否开启积点赠送:">
									<el-radio v-model="shopInfo.score_playsend" label="1">开启</el-radio>
									<el-radio v-model="shopInfo.score_playsend" label="0">关闭</el-radio>
								</el-form-item>
							</li>
							<li>
								<el-form-item label="店铺小程序码:">
									<el-image 
									    style="width: 200rem; height: 200rem"
									    :src="wxCode" 
									    :preview-src-list="[wxCode]">
									  </el-image>
								</el-form-item>
							</li>
						</ul>
					</el-form>

					<div class="btn">
						<el-button class="button_type" :loading="loading" @click="shopSubmit">确定设置</el-button>
					</div>
				</el-tab-pane>
				<el-tab-pane label="剧本基本设置" name="second">
					<el-form ref="form" :model="shopInfo" label-width="130px">
						<ul>
							<li>
								<el-form-item label="盒装价格:">
									<el-input v-model="shopInfo.box_price" placeholder='请输入盒装价格'></el-input>
								</el-form-item>
								<el-form-item label="城限价格:">
									<el-input v-model="shopInfo.city_price" placeholder='请输入城限价格'></el-input>
								</el-form-item>
							</li>

							<li>
								<el-form-item label="独家价格:">
									<el-input v-model="shopInfo.single_price" placeholder='请输入独家价格'></el-input>
								</el-form-item>
								<!-- <el-form-item label="全息价格:">
									<el-input v-model="shopInfo.holographic_price" placeholder='请输入全息价格'></el-input>
								</el-form-item> -->
							</li>
							<!-- <li>
								<el-form-item label="实景价格:">
									<el-input v-model="shopInfo.real_price" placeholder='请输入实景价格'></el-input>
								</el-form-item>
							</li> -->
							<li>
								<el-form-item label="价格说明:">
									<el-input :rows=5 class="textarea" type="textarea" v-model="shopInfo.price_msg"
										placeholder='请输入价格说明'></el-input>
								</el-form-item>
							</li>
						</ul>
					</el-form>

					<div class="btn">
						<el-button class="button_type" :loading="loading" @click="bookClick">确定设置</el-button>
					</div>
				</el-tab-pane>
				
				<el-tab-pane label="积分商城设置" name="third">
					<el-form ref="form" :model="shopInfo" label-width="280rem">
						<ul>
							<li>
								<el-form-item label="游玩赠送积分数量:">
									<el-input v-model="shopInfo.score_playsend" placeholder='请设置游玩赠送积分数量'></el-input>
								</el-form-item>
							</li>
				
							<li>
								<el-form-item label="积分商城轮播:">
									<el-button type="primary" size="small" class="addSwiper" @click="addSwiper('')">添加轮播+</el-button>
								</el-form-item>
							</li>
							<li>
								<el-table
								    :data="scoreSwiper"
								    border
								    style="width: 100%">
								    <el-table-column
								      prop="id"
								      label="ID"
									  align="center"
								      width="180">
								    </el-table-column>
								    <!-- <el-table-column
								      prop="name"
									  align="center"
								      label="名称">
								    </el-table-column> -->
									<el-table-column
									  align="center"
									  label="图片">
									  <template slot-scope="scope">
									      <el-image
									            style="width: 100px; height: 100px"
									            :src="scope.row.imgUrl"
									            fit="fill"
												:preview-src-list="[scope.row.imgUrl]"></el-image>
									  </template>
									</el-table-column>
									<el-table-column
									  label="操作"
									  align="center"
									  width="180">
									  <template slot-scope="scope">
										  <el-button size="mini" type="warning" @click="addSwiper(scope.row)">重选</el-button>
										  <el-button size="mini" type="danger" @click="delSwiper(scope.row)">删除</el-button>
									  </template>
									</el-table-column>
								  </el-table>
							</li>
						</ul>
					</el-form>
				
					<div class="btn">
						<el-button class="button_type" :loading="loading" @click="scoreSet">确定设置</el-button>
					</div>
				</el-tab-pane>
			</el-tabs>
		</el-card>
		

		<imgLibrary ref="imgLibrary" :toImage="changeImg"></imgLibrary>

		<el-dialog title="地图" :visible.sync="map" width="50%">
			<iframe id="mapPage" width="100%" height="500rem" frameborder=0
				src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=SETBZ-BKK6J-DN6F7-FB5WM-QONG5-ASBEO&referer=myapp">
			</iframe>
			<span slot="footer" class="dialog-footer">
				<el-button @click="mapCancel">取 消</el-button>
				<el-button type="primary" @click="mapSubmit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import UploadImg from '../../components/uploadImg.vue'
	import imgLibrary from '../../components/qrun/imgLibrary.vue'
	import ImageBox from '../../components/qrun/ImageBox.vue'
    import Vue from 'vue';

	export default {
		name:'index_basics',
		menuName:"基础设置",
		data() {
			return {
				activeName: 'first',
				shopInfo: {
					logo: '',
					head_pic: '', //首页的背景图
					integral_name: '', //积分别名
					latitude: '', //纬度
					longitude: '', //经度
					notice_content: '', //滚动通告
					store_address: "", //商户地址
					store_mobiles: "",
					store_name: "", //商户名称
					welcome: '', //欢迎语言
					wifi_name: '',
					wifi_pwd: '',
					kefu_link:"",
					enterprise_id:"",

					real_price: "", //实景价格
					single_price: "", //独家价格
					box_price: "", //盒装价格
					city_price: "", //城限价格
					holographic_price: "", //全息价格
					price_msg: '', //价格说明
					
					score_playsend:"",
					score_swiper:""
				},

				mobiles: [{
					title: '',
					mobile: ''
				}, ],

				uploadRet:()=>{},
				UploadStatus: false,

				map: false,
				mapInfo: {},
				loading: false,

				//区分上传按钮
				type: 1,
				
				//标签列表
				tagList:[
					{name:"用户名"},
					{name:"早中晚"}
				],
				setPrice:"",
				scoreSwiper:[],
				reloadImg:{},
				wxCode:""
			}
		},
		computed: {

		},
		inject:["layout"],
		components: {
			ImageBox,
			UploadImg,
			imgLibrary
		},
		created() {
			
			if(this.$route.query && this.$route.query.setPrice == '1'){
				this.activeName = "second";
				this.setPrice = this.$route.query.setPrice;
			}
			
			window.addEventListener('message', (event) => {
				var loc = event.data;
				if (loc && loc.module == 'locationPicker') {
					this.mapInfo = loc
				}
			}, false);

			//店铺的基础设置
			if(localStorage.getItem('shopInfo')){
				this.shopInfo = JSON.parse(localStorage.getItem('shopInfo'));
				this.mobiles = this.shopInfo.store_mobiles && JSON.parse(this.shopInfo.store_mobiles);
				this.layout.loading =false;
			}
			
			//店铺小程序码
			this.getWxCode();
			
		},
		methods: {
			//店铺小程序码
			getWxCode(){
				this.$api.apiInterface('Home', "xcxQrcode").xcxQrcode({}, res => {
					
					// console.log("店铺小程序码", res);
					if (res.code == 0) {
				        this.wxCode = res.data;
						return;
					}
				
					this.$message({
						type: "warning",
						message: res.msg
					})
				})
			},
			addSwiper(value){
				
				this.reloadImg = value;
				
				this.$refs.imgLibrary.show();
				
				this.type = 3;
				
			},
			delSwiper(value){
				
				var reloadImg = value;
				
				var index = this.scoreSwiper.findIndex(item=>{
					return item.id == reloadImg.id;
				})
				
				this.scoreSwiper.splice(index,1);
				
			},
			backPage(){
				this.$router.go(-1);
			},
			addTags(item){
				let welcome = this.shopInfo.welcome?this.shopInfo.welcome:''
				this.shopInfo.welcome = `{${item.name}}` + welcome
			},
			//添加商户手机号
			addTel() {
				this.mobiles.push({
					title: '',
					mobile: ''
				})
			},
			//删除手机号
			delTel(index) {
				this.$confirm('确认要删除这个手机号吗？')
				.then(_ => {
					this.mobiles.splice(index, 1)
				})
				.catch(_ => {});
			},

			//切换
			handleClick(tab, event) {
				
				console.log("this.shopInfo",this.shopInfo);
				
				if(tab.index == 1 && !this.shopInfo.store_address){
					this.$message({
					    message: '商户基本设置暂未完善，请先完善',
					    type: 'warning'
					});
				}else if(tab.index == 2 && this.shopInfo.score_swiper){
					this.scoreSwiper = JSON.parse(this.shopInfo.score_swiper);
				}

			},

			showUpload(ret = ()=>{}){
				this.UploadStatus = true
				this.uploadRet = ret
			},

			//选择图片
			changeImg(pic) {
				console.log('val',pic);

				// this.uploadRet(val);
				this.$refs.imgLibrary.hide()
				
				if(this.type == 3){
					
					if(this.reloadImg){
						
						var index = this.scoreSwiper.findIndex(item=>{
							return item.id == this.reloadImg.id;
						})
						
						this.scoreSwiper[index].imgUrl = pic;
						
					}else{
						
						this.scoreSwiper.push({
							id:this.scoreSwiper.length <=0 ? this.scoreSwiper.length+1 : this.scoreSwiper[this.scoreSwiper.length-1].id+1,
							// name:`轮播图${this.scoreSwiper.length+1}`,
							imgUrl:pic
						})
						
					}
					
				}

			},

			//map弹窗
			mapCancel() {
				this.map = false
			},

			mapSubmit() {
				this.shopInfo.store_address = this.mapInfo.poiaddress
				this.shopInfo.latitude = this.mapInfo.latlng.lat
				this.shopInfo.longitude = this.mapInfo.latlng.lng
				this.map = false
			},
			//获取商铺信息
			getShop() {
				this.$api.getShopList({}, (res) => {
					if (res.code == 0) {
						
						localStorage.setItem('shopInfo', JSON.stringify(res.data))
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			//商户保存按钮
			shopSubmit() {
				this.loading = true

				if (!this.shopInfo.store_name) {
					this.loading = false
					return this.$message.error('商户名称暂未完善，请先完善！');
				}


				for (var i = 0; i < this.mobiles.length; i++) {
					if (!this.mobiles[i].title || !this.mobiles[i].mobile) {
						this.loading = false
						return this.$message.error('商户手机号暂未完善,请完善所有的商户手机号！');
					}
				}


				if (!this.shopInfo.store_address) {
					this.loading = false
					return this.$message.error('商户地址暂未完善，请先完善！');
				}

				if (!this.shopInfo.latitude || !this.shopInfo.longitude) {
					this.loading = false
					return this.$message.error('商户地址的经纬度暂未完善，请先完善！');
				}


				this.shopInfo.store_mobiles = JSON.stringify(this.mobiles)
				
				
				

				this.$api.setShopList(this.shopInfo, (res) => {
					this.loading = false
					if (res.code == 0) {
						this.getShop()
						this.$message.success({
							message: '恭喜你，设置成功',
							type: 'success'
						});
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			//剧本保存按钮
			bookClick() {
				this.loading = true
				this.$api.setShopList(this.shopInfo, (res) => {
					this.loading = false
					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，设置成功',
							type: 'success'
						});
						this.getShop()
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			//积分商城设置
			scoreSet(){
				this.shopInfo.score_swiper = JSON.stringify(this.scoreSwiper)
				
				this.loading = true
				this.$api.setShopList(this.shopInfo, (res) => {
					this.loading = false
					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，设置成功',
							type: 'success'
						});
						this.getShop()
					} else {
						this.$message.error(res.msg);
					}
				})
			}
		}

	}
</script>

<style scoped lang="less">
	/deep/.el-tabs__item {
		font-size: 20px;
		color: #8c939d;
	}

	/deep/.el-tabs__item:hover {
		color: #FFE300 !important;
		cursor: pointer;
	}

	/deep/.el-tabs__item.is-active {
		color: #FFE300 !important;
	}

	/deep/.el-tabs__active-bar {
		background-color: #FFE300 !important;
	}

	/deep/.el-form-item__label {
		font-size: 18px;
		color: #606266;
	}

	/deep/.el-form-item__content {
		margin-right: 100rem;
		// display: flex;
		// flex-direction: column;
		// align-items: center;
	}

	.nav {
		display: flex;
		justify-content: space-between;
		height: 100%;

		.body {
			width: 100%;
			overflow-y: scroll;

			ul>li {
				display: flex;
				align-items: center;

				.local {
					position: absolute;
					right: 30rem;
					width: 30rem;
					height: 34rem;
				}

				.addIcon {
					width: 42rem;
					height: 42rem;
					margin-left: 30rem;
				}

				.telephone {
					display: flex;
					align-items: center;
				}
			}
		}

		.inputW {
			width: 250rem;

		}

		.textarea {
			width: 1168rem;
		}


		.btn {

			width: 500rem;
			margin: 100rem auto;

			.button_type {
				width: 500rem;
				height: 100rem;
				background-color: #FFE300;
				border: #FFE300;
				color: #000;
				font-size: 20px;
				letter-spacing: 2px;
			}

			.button_up {
				width: 200rem;
				height: 50rem;
				background-color: #FFE300;
				border: #FFE300;
				color: #000;
				font-size: 20rem;
				letter-spacing: 2rem;
			}
		}

		.button_up {
			width: 200rem;
			height: 50rem;
			background-color: #FFE300;
			border: #FFE300;
			color: #000;
			font-size: 20rem;
			letter-spacing: 2rem;
		}


		.avatar-uploader-icon[data-v-32349162] {
			border: 1rem dashed #d9d9d9;
			border-radius: 6rem;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}

		.avatar-uploader .el-upload {

			border-radius: 6rem;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}

		.avatar-uploader .el-upload:hover {
			border-color: #409EFF;
		}

		.avatar-uploader-icon {
			font-size: 28rem;
			color: #8c939d;
			width: 178rem;
			height: 178rem;
			line-height: 178rem;
			text-align: center;
		}

		.avatar {
			width: 178rem;
			height: 178rem;
			display: block;
		}


		.avatar-uploader-icons[data-v-32349162] {
			border: 1rem dashed #d9d9d9;
			border-radius: 6rem;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}


		.avatar-uploader-icons {
			font-size: 28rem;
			color: #8c939d;
			width: 200rem;
			height: 177rem;
			line-height: 177rem;
			text-align: center;
		}

		.avatars {
			width: 200rem;
			height: 177rem;
			display: block;
		}
	}
</style>
