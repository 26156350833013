export default {
	state:{
		token: 0
	},
	mutations: {
		setToken(state,val) {
			state.token = val
		}
	},
	getters: {
	}
}
