<template>
	<div class="pages">
		<el-card style="padding-bottom: 20rem;">
			<el-button type="primary" size="mini" class="" style="margin-bottom: 20rem;" @click="newUser">{{'角色添加'}}
			</el-button>
			<el-table v-loading="loading" border :data="list" :default-sort="{prop: 'date', order: 'descending'}">
				<el-table-column prop="id" label="ID" width="100" align="center">
				</el-table-column>

				<el-table-column prop="name" :label="'角色名字'" align="center">
				</el-table-column>

				<el-table-column :label="'创建时间'" prop="update_time" align="center">
				</el-table-column>

				<el-table-column :label="'操作'" width="250" align="center">
					<template slot-scope="scope" size="mini">
						<el-button size="mini" type="primary" icon="el-icon-edit" @click="handleEdit(scope.row)">
							{{'编辑'}}</el-button>
						<el-button size="mini" icon="el-icon-delete" type="danger" @click="handleDelete(scope.row)">
							{{'删除'}}</el-button>
					</template>
				</el-table-column>

			</el-table>
		</el-card>

		<div class="pagination">
			<el-pagination background layout="prev, pager, next" :page-count="pageSize" :current-page="page"
				:hide-on-single-page="true" :page-sizes="[limit]" @current-change="handlePageChange">
			</el-pagination>
		</div>

		<!-- 删除角色 -->
		<el-dialog :title="'信息'" :visible.sync="deleteUser" width="20%" :before-close="handleClose">
			<span>{{'确定删除此角色'}}</span>
			<span slot="footer" class="dialog-footer">
				<el-button size="mini" type="primary" @click="sureDeleteUser">{{'确 定'}}</el-button>
				<el-button size="mini" @click="deleteUser = false">{{'取 消'}}</el-button>
			</span>
		</el-dialog>
		<!-- 添加新角色/编辑角色 -->
		<el-dialog  :title="userData.id? '编辑角色':'添加新角色'" width="50%" :visible.sync="outerVisible">
			<el-form v-loading="isLoading" label-width="120rem" :model="userData" :rules="rules">
				<el-form-item :label="('名称')+':'" prop="name">
					<el-col :span="8">
						<el-input :placeholder="'请输入名称'" size="mini" :disabled="userData.id? true : false"
							v-model="userData.name">
						</el-input>
					</el-col>
				</el-form-item>
				<el-form-item :label="'权限'+':'">
					<el-tree style="margin-top: 7rem;" :data="perList" show-checkbox default-expand-all
						node-key="id" ref="tree" highlight-current 
						:props="defaultProps" @check-change="checkChange">
					</el-tree>
					
					<!--  -->
					
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button size="mini" :loading="loading" type="primary" @click="sureUser">{{'保 存'}}</el-button>
				<el-button size="mini" @click="cancelUser">{{'取 消'}}</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	
	
	export default {
		name: "index_roleSet",
		menuName:"角色管理",
		data() {
			return {
				isLoading:true,
				outerVisible: false,
				deleteUser: false,
				powerOpen: false,
				powerText: "",
				defaultProps: {
					children: 'children',
					label: 'title'
				},
				userData: {
					name: '',
					power: '',
					id: ''
				},
				page: 1,
				limit: 12,
				count: 0,
				pageSize: 0,
				list: [],
				date: 1,
				loading: false,
				rules: {
					name: [{
							required: true,
							message: '请输入名称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 20,
							message: '长度在 3 到 20 个字符',
							trigger: 'blur'
						}
					]
				},
				perList: [],
				treeCheckList: [],
				isTree: false,
				nodeList: [],
				loading: false,
				nodeCheckList:[]
			}
		},
		computed: {

		},
		mounted() {
			this.getUserList(this.page, this.limit)
		},
		created() {
			this.getPerList();

		},
		methods: {
			//获取权限列表
			getPerList(){
				this.$api.getMenuList({}, res => {
					if (res.code == 0) {
						
						var menuList = res.data;
						
						this.perList = menuList;
							
						this.isTree = true;
						console.log("this.perList", this.perList);
						
					}
				})
			},
			//格式时间
			formatDate(date) {
				date = new Date(date);
				var y = date.getFullYear();
				var m = date.getMonth() + 1;
				var d = date.getDate();
				var h = date.getHours();
				var m1 = date.getMinutes();
				var s = date.getSeconds();
				m = m < 10 ? ("0" + m) : m;
				d = d < 10 ? ("0" + d) : d;
				return y + "-" + m + "-" + d + " " + h + ":" + m1 + ":" + s;
			},
			//获取角色的列表
			getUserList() {
				this.loading = true
				this.$api.getRoleSet({
					page: this.page,
					limit: this.limit
				}, res => {
					this.loading = false
					if (res.code == 0) {
						this.count = res.data.count;
						this.list = res.data.list;

						this.pageSize = Math.ceil(this.count / this.limit);

						this.list.update_time = res.data.list.map(item => {
							item.update_time = this.formatDate(item.update_time * 1000);
							return item
						})
					}

				})

			},
			//添加新角色
			newUser() {
				this.nodeCheckList = [];
				this.perList = [];
				
				this.treeCheckList = [];
				this.isTree = true;
				this.outerVisible = true;
				this.userData = {};
				
				this.perList = JSON.parse(localStorage.getItem("menuList"));
				
				this.isLoading = false;
			},
			//编辑指定角色
			handleEdit(row) {
				// console.log("row",row.power);
				this.isLoading = true;
				
				this.outerVisible = true;
				
				this.userData = row;
				
				this.$nextTick(()=>{
					
					setTimeout(()=>{
						this.nodeCheckList = [];
						
						var ids = row.power.split(',');
						
						ids.forEach(item=>{
							this.$refs.tree.setChecked(item,true)
						})
						
						this.isLoading = false;
					},1000)

				})

			},
			//删除按钮
			handleDelete(row) {
				this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确 定',
					cancelButtonText: '取 消',
					type: 'warning'
				}).then(() => {
					//需要调用接口
					this.$api.delGroup({
						id: row.id
					}, (res) => {
						console.log(res);
						if (res && res.code === 0) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.getUserList(this.page, this.limit)
						}
					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			//删除指定角色取消按钮
			handleClose() {
				this.deleteUser = false
			},
			//确定删除指定角色按钮
			sureDeleteUser() {
				this.deleteUser = false
			},
			//取消添加或者编辑角色
			cancelUser() {
				this.treeCheckList = [];
				this.isTree = false;
				this.nodeList = [];

				this.outerVisible = false
				this.userData = {}
			},
			//确定添加或者编辑角色
			sureUser() {

				this.loading = true;
                
				var ids = this.nodeCheckList.length > 0 ? this.nodeCheckList : this.treeCheckList;
				
				var formData = {
					power: ids.join(","),
					name: this.userData.name,
					id: this.userData.id || ""
				}

				this.$api.getRoleList(formData, (res) => {
					if (res.code == 0) {
						this.getUserList(this.page, this.limit);
						this.$message.success("分配权限成功");
					} else {
						this.$message({
							message: res.msg,
							type: 'warning'
						});
					}

				})

				this.nodeList = [];
				this.treeCheckList = [];
				this.outerVisible = false;
				this.loading = false;

			},
			checkChange(val, bool) {
				
				var ids = [...this.$refs.tree.getCheckedKeys(),...this.$refs.tree.getHalfCheckedKeys()];
				
				this.nodeCheckList = ids;

			},
			// 分页操作
			handleSizeChange(pageSize) {
				this.limit = pageSize;
				this.getUserList(this.page, this.limit);
			},
			handlePageChange(page) {
				// console.log(page);
				this.page = page;
				this.getUserList(this.page, this.limit);
			}
		}
	}
</script>

<style lang="less" scoped>
	.pages {
		position: relative;
		height: 100%;
	}

	/deep/.el-dialog {
		position: relative;
	}

	/deep/ .el-dialog__body {
		height: 630rem;
		overflow: scroll;
		padding: 0rem 0;
		// border-top: 1rem solid #d3d4d6;
		border-bottom: 1rem solid #d3d4d6;
	}

	/deep/.el-form {
		// height: 100%;
	}

	/deep/.el-dialog__body::-webkit-scrollbar {
		display: none;
	}

	.pagination {
		display: flex;
		justify-content: center;
		margin-top: 20rem;
	}

	/deep/.el-dialog__footer {
		height: 50rem;
		line-height: 50rem;
	}

	.dialog-footer {
		position: absolute;
		bottom: 0;
		right: 0;
		margin-right: 15rem;
	}

	.layui-btn {
		display: inline-block;
		height: 38rem;
		line-height: 38rem;
		padding: 0 18rem;
		margin-bottom: 10rem;
	}

	.add_roles {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 80rem 0 20rem;
		height: 42rem;
		line-height: 42rem;
		border-bottom: 1rem solid #eee;
		font-size: 14rem;
		color: #333;
		overflow: hidden;
		border-radius: 2rem 2rem 0 0;
	}

	.userPower_btn {
		display: none;
		position: absolute;
		right: 0;
		top: 0;
	}

	.userPower {
		position: relative;
	}

	.userPower:hover .userPower_btn {
		display: block;
		background-color: white;
	}
</style>
