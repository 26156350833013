<template>
	<div class="form" v-loading="isLoadForm">
		<template v-if="deductionInfo && deductionInfo.collection">
			<div class="session">
				<label class="label" style="">场次信息：</label>
				<div class="sessionInfo">
					<div class="info">
						<img :src="deductionInfo.headPic" alt="">
						<div class="content">
							<div class="title mb">{{deductionInfo.title}}</div>
							<div class="conPrice mb fs">￥{{deductionInfo.play_money}}/位</div>
							<div class="conItem mb fs">
								<span>应收：{{deductionInfo.play_member}}人</span>
							</div>
							<div class="conItem mb fs">
								<span style="margin-right: 20rem;">扫码收款：{{deductionInfo.collection.OnlinePay}}元</span>
								<span style="margin-right: 20rem;">会员收款：{{deductionInfo.collection.MemberPay}}</span>
								<span style="margin-right: 20rem;">美团收款：{{deductionInfo.collection.MeituanPay}}元</span>
								<span style="margin-right: 20rem;">现金收款：{{deductionInfo.collection.MeituanPay}}元</span>
							</div>
							<div class="conItem mb fs">
								<span style="margin-right: 20rem;">剧本费：{{deductionInfo.collection.BookPay}}元</span>
								<span style="margin-right: 20rem;">修仙费：{{deductionInfo.collection.SleepPay}}元</span>
								<span style="margin-right: 20rem;">其他费：{{deductionInfo.collection.OtherPay}}元</span>
							</div>

						</div>
					</div>
					<div class="settlement">
						<div class="sPrice">总计：{{deductionInfo.collection.BookPay}}元</div>
					</div>

					<ul class="navList">
						<li @click="paySession">买单</li>
						<li @click="getCollRecord()">收款记录</li>
						<li @click="getMTlist()">美团核销</li>
						<!-- <li>记忆相册</li> -->
						<!-- <li>邀请玩家</li> -->
					</ul>
				</div>
			</div>

			<div class="playerList">
				<!-- <label style="font-size: 22rem;">玩家列表：</label> -->
				<label style="font-size: 22rem;">参与买单人员：</label>
				<template v-if="deductionInfo.collection.BuyNames.length > 0">
					<el-tag style="margin-right: 15rem;" size="small" type="primary"
						v-for="item in deductionInfo.collection.BuyNames" :key="item.id">
						{{item}}
					</el-tag>
				</template>
				<el-tag type="info" v-else>暂无人员</el-tag>
			</div>

			<div class="dmList">
				<label class="label">DM列表：</label>
				<ul>
					<li v-for="(item,index) in deductionInfo.dm_list" :key="item.id">
						<img style="width: 62rem;height: 62rem;"
							:src=" item.avatar ? item.avatar :  require('../../static/home_huli.png')" alt="">
						<img class="sexIcon"
							:src="item.sex == 2 ? require('@/static/girl.png') : require('@/static/boy.png')" alt="">
						<div class="name">
							<div>{{item.nickname}}</div>
							<div
								style="background: linear-gradient(139deg, #FAD126, #FF544F);font-size: 16rem;border-radius: 5rem;text-align: center;padding: 5rem;margin-top: 5rem;">
								{{item.uname}}
							</div>
						</div>
					</li>
				</ul>
			</div>

			<div class="dmList">
				<label class="label">玩家列表：</label>
				<ul v-if="deductionInfo.user_list && deductionInfo.user_list.length>0">
					<li v-for="(item,index) in deductionInfo.user_list" :key="item.id">
						<img style="width: 62rem;height: 62rem;"
							:src=" item.avatar ? item.avatar :  require('../../static/home_huli.png')" alt="">
						<img class="sexIcon"
							:src="item.sex == 2 ? require('@/static/girl.png') : require('@/static/boy.png')" alt="">
						<div class="name">
							<div>{{item.nickname}}</div>
							<div style="display: flex;align-items: center;margin-top: 10rem;">
								<el-switch size="mini" v-model="item.open_integral" @change="scoreChangeHandle(item)">
								</el-switch>
								<span
									style="font-size: 12rem;color: #ccc;display: block;margin-left: 10rem;">*是否开启积点赠送</span>
							</div>
						</div>

						<div class="kickOut" style="margin: 0 10rem;cursor: pointer;" title="踢出玩家" @click="kickOut(item)">
							<img src="../../static/kickOut.png" alt="">
						</div>
					</li>
				</ul>
				<el-tag type="info" v-else>暂无玩家</el-tag>
			</div>

			<!-- 场次状态 -> 未完成 -->
			<div class="playerList" v-if="deductionInfo.status == 0">
				<!-- <label style="font-size: 22rem;">玩家列表：</label> -->
				<label style="font-size: 22rem;">场次结束时间：</label>
				<el-date-picker v-model="end_time" size="small" type="datetime" placeholder="选择日期时间">
				</el-date-picker>
			</div>

			<!-- 扣款 -->
			<el-drawer :wrapperClosable="isWrapper" title="扣款" :visible.sync="isDeduction" direction="rtl">

				<div class="deduction" v-loading='isLoading'>
					<div class="info m_xy">
						<img :src="deductionInfo.headPic" alt="">
						<div class="content">
							<div class="title mb">{{deductionInfo.title}}</div>
							<div class="conPrice mb fs">￥{{deductionInfo.play_money}}/位</div>
						</div>
					</div>

					<div style="width: 100%;height: 10rem;background: #F3F3F3;"></div>

					<div class="coupon">
						<el-dropdown style="width: 100%;" trigger="click">
							<div style="display: flex;justify-content: space-between;">
								<span class="el-dropdown-link">
									优惠券
								</span>
								<span style="cursor: pointer;">
									{{ selCoupon == "" ? '请选择' : (typeof selCoupon == 'undefined' ? '不可用' : selCoupon.name)}}
									<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
							</div>

							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item>
									<label>
										<div
											style="width: 420rem !important;display: flex;justify-content: space-between;align-items: center;">
											<span>不选用</span>
											<el-radio v-model="payform.coupon_id" label="-1"></el-radio>
										</div>
									</label>
								</el-dropdown-item>
								<el-dropdown-item divided v-for="(item,index) in deductionInfo.couponOkList"
									:key="index">
									<label>
										<div
											style="width: 420rem !important;display: flex;justify-content: space-between;align-items: center;">
											<div class="cardInfo">
												<p style="font-size: 20rem;line-height: 30rem;"> <span
														style="font-size: 12rem;">{{item.show?'' : '(不支持本场核销)'}}</span>{{item.name}}
												</p>
												<p style="font-size: 12rem;line-height: 30rem;">
													{{item.start_time | formatTime}}~{{item.end_time | formatTime}}
												</p>
											</div>

											<div class="select">
												<span
													style="margin-right: 10rem;">{{item.discount_money * 1}}{{item.type == 1 ? '元' : '折'}}</span>
												<el-radio v-model="payform.coupon_id" :label="item.id"
													:style="item.show?'' : 'display:none'"></el-radio>
											</div>
										</div>
									</label>

								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>

					<div style="width: 100%;height: 10rem;background: #F3F3F3;"></div>

					<div class="deductionItem m_xy">
						<ul>
							<li>
								<span class="deTil">剧本费用</span>
								<input class="input" v-model="price" type="text" placeholder="请输入剧本费用" />
							</li>
							<li style="margin-top: 20rem;display: flex;flex-wrap: wrap;">
								<span class="deTil">快捷人数</span>
								<span class="deNum" v-for="item in deductionInfo.play_member" :key="item"
									@click="selPlay_member(item)">x{{item}}</span>
							</li>
						</ul>
					</div>

					<div style="width: 100%;height: 10rem;background: #F3F3F3;"></div>

					<div class="deductionItem m_xy">
						<ul>
							<li>
								<span class="deTil">修仙费用</span>
								<input class="input" v-model="payform.sleep_price" type="text" placeholder="请输入修仙费用" />
							</li>
						</ul>
					</div>

					<div style="width: 100%;height: 10rem;background: #F3F3F3;"></div>

					<div class="deductionItem m_xy">
						<ul>
							<li>
								<span class="deTil">其他费用</span>
								<input v-model="payform.other_price" class="input" type="text" placeholder="请输入其他费用" />
							</li>
						</ul>
						<textarea v-model="payform.remake" class="textarea" placeholder="请输入其他费用备注"></textarea>
						<div style="margin-left: 385rem;">{{payform.remake.length}}/200字</div>
					</div>

					<div style="width: 100%;height: 10rem;background: #F3F3F3;"></div>

					<div class="memberCard m_xy">
						<ul>
							<li class="card" :style="item.get_time?'' : 'filter: grayscale(100%);'"
								v-for="(item,index) in deductionInfo.card_list" :key="item.id">
								<!-- 个人 -->
								<div class="top" v-if="item.type == 1">
									<span>个人储值卡{{item.get_time?'' : '(此卡暂未激活)'}}</span>
									<span>剩余本场核销次数：{{1 - item.theatre_buy_count <= 0 ? '0' : 1 - item.theatre_buy_count}}</span>
								</div>

								<!-- 团队 -->
								<div class="top" v-else>
									<span>团队储值卡</span>
									<span>不限制本场核销次数</span>
								</div>

								<div class="bottom">
									<span>{{item.card_name}}</span>
									<div>
										<span
											style="margin-right: 10rem;font-size: 20rem;color: #f60;">余额：{{item.money ? item.money : '0.00'}}</span>
										<el-radio
											:style="!item.get_time ? 'display:none' : item.type == 2 ? '' : item.type == 1 && (1 - item.theatre_buy_count >= 1 ) ? '' : 'display:none'"
											v-model="priceIndex" :label="index"></el-radio>
									</div>
								</div>
							</li>

							<!-- 在线支付 -->
							<li class="card">
								<div class="top" style="background: #00D13B;">
									<span>扫码在线支付</span>
								</div>

								<div class="bottom">
									<span>支持微信、支付宝、银联</span>
									<div>
										<el-radio v-model="priceIndex" label="200"></el-radio>
									</div>
								</div>
							</li>

							<!-- 在线支付 -->
							<li class="card">
								<div class="top" style="background: #f60;">
									<span>现金支付</span>
								</div>

								<div class="bottom">
									<span>现金支付</span>
									<div>
										<el-radio v-model="priceIndex" label="500"></el-radio>
									</div>
								</div>
							</li>
						</ul>
					</div>

				</div>

				<div class="pay" @click="pay">
					<span style="margin-right: 10rem;"
						:class="isPayLoading ? 'el-icon-s-finance':'el-icon-loading'"></span>{{isPayLoading ? '支付':'正在支付'}}
				</div>

			</el-drawer>

			<!-- 支付提示 -->
			<div class="payTips" v-if="isPay">

				<span class="tips">{{tips}}</span>

				<input type="text" style="opacity: 0;" ref="input" class="input" v-model="payform.auth_code"
					@change="changeHandle" @blur="tips = '鼠标点击我获得焦点'" @focus="tips = '请扫码支付'" />

			</div>

			<div class="sBtn" @click="overSession">
				{{deductionInfo.status == 0 ? '结束场次' : (deductionInfo.status == 1 ? '对账确认场次' :'核销完成')}}
			</div>

			<!-- 团队分成 -->
			<el-dialog title="团队分成" :visible.sync="isTeam" width="50%">

				<h3>总计：{{deductionInfo.collection.BookPay}}</h3>

				<ul class="teamMoney">
					<li v-for="(item,index) in teamList" :key="index">
						<el-tag>{{item.team_name}}</el-tag>
						<div>
							<span>金额：</span>
							<el-input v-model="item.money" placeholder="请设置金额" type="text" size="small"
								style="width: 300rem;"></el-input>
						</div>
					</li>
				</ul>

				<span slot="footer" class="dialog-footer">
					<el-button size="mini" @click="isTeam = false">取 消</el-button>
					<el-button size="mini" type="primary" @click="confirmSession">确 定</el-button>
				</span>
			</el-dialog>

			<!-- 收款记录 -->
			<el-dialog title="收款记录" :visible.sync="isDialog" width="85%">

				<!-- 筛选 -->
				<div style="margin-top: 20rem;">
					<baseList :colList.sync="colList" :listData.sync="tabListData"></baseList>
				</div>

				<!-- 分页 -->
				<pagination :callback="pageSizeFun" pageType="1" :limit="collRecordData.limit"
					:page="collRecordData.page" :count="collRecordData.count"></pagination>

			</el-dialog>
			
			<!-- 美团核销列表 -->
			<el-dialog title="美团核销列表" :visible.sync="isMTDialog" width="85%">
			
				<!-- 筛选 -->
				<div style="margin-top: 20rem;">
					<baseList :colList.sync="MTcolList" :callBack="listCallBack" :listData.sync="MTList"></baseList>
				</div>
			
				<!-- 分页 -->
				<!-- <pagination :callback="pageSizeFun" pageType="1" :limit="collRecordData.limit"
					:page="collRecordData.page" :count="collRecordData.count"></pagination> -->
			
			</el-dialog>

		</template>
		
		<!-- 美团核销 -->
		<el-dialog title="美团核销" :visible.sync="isMToffDialog" width="35%">
            
			<div class="coupon_main" style="">
				<div class="main">
					<div >
						名称：<span>{{MTlists.deal_title}}</span>
					</div>
					<div >
						单价：<span>{{MTlists.deal_price}}元</span>
					</div>
					<div>
						剩余核销数量：<span>{{MTlists.available_count}}</span>
					</div>
					<div>
						核销数量：<el-input style="width: 300rem;" type="text" v-model="storeform.count" placeholder="请输入核销数量" size="mini"></el-input>
					</div>
				</div>

			</div>
			
		    <span slot="footer" class="dialog-footer">
		    	<el-button size="mini" @click="isMToffDialog = false">取 消</el-button>
		    	<el-button size="mini" type="primary" @click="storeCommit">确 定</el-button>
		    </span>
		</el-dialog>
		
		<!-- 小白盒扫码 -->
		<scanBox ref="scanBox" title="美团核销码" :wxToRes="scanSub"></scanBox>

	</div>
</template>

<script>
	import baseList from "@/components/baseList/index.vue"
	import pagination from "@/components/pagination/index.vue"
	import {
		mapState
	} from "vuex"
	
	import scanBox from "@/components/scanBox"

	export default {
		data() {
			return {
				apiName: 'Theatre',
				collRecordData: {
					theatre_id: '',
					limit: 10,
					page: 1,
					count: 0
				},
				isDialog: false,
				isLoadForm: true,
				isDeduction: false,
				isPayLoading: true,
				deductionInfo: {},
				payform: {
					theatre_id: '',
					is_test: 2,
					card_no: '',
					price: '',
					sleep_price: '',
					other_price: '',
					remake: '',
					pay_type: 1,
					user_id: 1,
					coupon_id: '',
					auth_code: ""
				},
				price: '',
				sleep_price: '',
				other_price: '',
				priceIndex: 0,
				isLoading: true,
				isPay: false,
				isFocus: false,
				tips: "请扫码支付",
				isWrapper: true,
				teamList: [{
						name: "狐说",
						money: "",
						id: 1
					},
					{
						name: "幻娱乐",
						money: "",
						id: 2
					},
					{
						name: "狐说",
						money: "",
						id: 3
					}
				],
				isTeam: false,
				colList: [{
						lable: '场次ID',
						key: 'theatre_id',
						type: 'text'
					},
					{
						lable: '剧本名称',
						key: 'books_info',
						attr:"title",
						type: 'text'
					},
					{
						lable: '房间名称',
						key: 'room_name',
						type: 'text'
					},
					{
						lable: '支付类型',
						key: 'pay_type',
						type: 'status',
						options: [{
								title: "余额",
								key: "1"
							},
							{
								title: "在线支付",
								key: "2"
							},
							{
								title: "美团",
								key: "3"
							},
							{
								title: "现金",
								key: "4"
							}
						]
					},
					{
						lable: '会员卡号',
						key: 'card_no',
						type: 'text'
					},
					{
						lable: '剧本收款费用',
						key: 'price',
						type: 'text'
					},
					{
						lable: '总计金额',
						key: 'total',
						type: 'text'
					},
					{
						lable: 'DM扣款人',
						key: 'dm_info',
						type: 'text'
					},
					{
						lable: '支付时间',
						key: 'pay_time',
						type: 'time',
						width: 180
					},
					{
						lable: '支付类型',
						key: 'status',
						type: 'status',
						options: [{
								title: "未支付",
								key: "0"
							},
							{
								title: "已支付",
								key: "1"
							}
						]
					}
				],
				tabListData: [],
				isOverSession: false,
				end_time: "",
				isMTDialog:false,
				MTList:[],
				MTlists:{},
				MTcolList:[
					{
						lable: 'ID',
						key: 'id',
						type: 'text',
						width: 80
					},
					{
						lable: '商户id',
						key: 'store_id',
						type: 'text',
						width: 80
					},
					{
						lable: '门店id',
						key: 'open_shop_uuid',
						type: 'text'
					},
					{
						lable: '门店名称',
						key: 'shopname',
						type: 'text',
					},
					{
						lable: '分点名称',
						key: 'branchname',
						type: 'text',
						width: 80
					},
					{
						lable: '门店地址',
						key: 'shopaddress',
						type: 'text',
					},
					{
						lable: '所在城市',
						key: 'cityname',
						type: 'text',
						width: 80
					},
					{
						lable: '授权状态',
						key: 'is_error',
						type: 'status',
						width: 80,
						options:[
							{title:"失败",key:"1"},
							{title:"成功",key:"0"}
						]
					},
					{
						lable: '操作',
						key: 'action',
						type: 'buttons',
						fixed: 'right',
						isShow: true,
						width: 150,
						options: [{
							type: 'btn',
							title: "扫码核销",
							clickType: 'sureClick',
							btnType: "warning"
						}]
					}
				],
				isMTBoxDialog:false,
				isFoucus:false,
				status:1,
				storeform:{
					qr_code:'',
					count:''
				},
				timer:null,
				shopInfo:{},
				isMToffDialog:false
			}
			
		},
		props: {
			formData: Object
		},
		components: {
			baseList,
			pagination,
			scanBox
		},
		inject: ["sessions"],
		created() {
			
			//获取团队列表
			this.getTeamList();

			this.initData();

		},
		watch: {
			isUser(newVal, oldVal) {
				this.initData();
			}
			
		},
		mounted() {

		},
		computed: {
			...mapState({
				userInfo: state => state.user.userInfo,
				isUser: state => state.user.isUser
			}),
			selCoupon() {

				var data = "";

				if (this.deductionInfo.couponOkList && this.payform.coupon_id != '') {

					if (this.payform.coupon_id == -1) {
						return {
							name: "不选用"
						}
					} else {
						data = this.deductionInfo.couponOkList.filter(item => {
							return this.payform.coupon_id == item.id && item.show;
						})[0]
					}

				}

				return data;
			}
		},
		filters: {
			formatTime(val) {
				var date = new Date(val * 1000);
				var Y = date.getFullYear()
				var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
				var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
				var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
				var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
				var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
				return Y + '-' + M + '-' + D
			},
		},
		methods: {
			//防抖
			debounce(fn, wait) {
				if (this.timer !== null) {
					clearTimeout(this.timer)
				}
				this.timer = setTimeout(fn, wait);
			},
			storeCommit(){
				var query = {
					theatre_id:this.formData.id,
					qr_code:this.storeform.qr_code,
					shop_id:this.shopInfo.id,
					type:1,
					count:this.storeform.count
				}
				
				this.$api.apiInterface(this.apiName, "teamBuy").teamBuy(query, res => {
					console.log("------", res);
							
					if (res.code == 0) {
						this.$message({
							type: "success",
							message: '核销成功'
						})
						
						this.isMToffDialog = false;
						
						return;
					}
							
					this.$message({
						type: "warning",
						message: res.msg
					})
							
				})
			},
			//小白盒扫码
			scanSub(code) {
				this.storeform.qr_code = code;
				
				var query = {
					theatre_id:this.formData.id,
					qr_code:code,
					shop_id:this.shopInfo.id,
					type:1
				}
			
				this.$api.apiInterface(this.apiName, "teamBuyCheck").teamBuyCheck(query, res => {
					console.log("------", res);
			        this.$refs.scanBox.hide();
					
					if (res.code == 0) {
						this.$message({
							type: "success",
							message: '扫码成功'
						})
						
						this.MTlists = res.data[0];
						this.storeform.count = this.MTlists.available_count;
						
						this.isMToffDialog = true;
						
						return;
					}
			
					this.$message({
						type: "warning",
						message: res.msg
					})
			
				})
			
			},
			listCallBack(type,data){
				console.log("data",data);
				
				this.shopInfo = data;
				
				this.storeform.qr_code = "";
				
				this.$refs.scanBox.show();
			},
			//获取美团核销列表
			getMTlist(){
				this.$api.apiInterface('DianPing', "shopList").shopList({}, res => {
					
					console.log("res", res);
					if(res.code == 0){
						this.MTList = res.data.list;
						
						this.isMTDialog = true;
					}
					
				})
			},
			kickOut(val) {
				
				this.$confirm('是否踢出该玩家?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					
					this.$api.apiInterface(this.apiName, "kick").kick({theatre_id:this.formData.id,user_id:val.user_id}, res => {
						console.log("res", res);
						if (res.code == 0) {
							this.$message({
								type: "success",
								message: "操作成功"
							})
					
							this.initData();
					
							return;
						}
					
						this.$message({
							type: "warning",
							message: res.msg
						})
					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
			},
			scoreChangeHandle(val) {
				// console.log("val",val);

				var query = {
					theatre_id: this.formData.id,
					user_id: val.user_id,
					open_integral: val.open_integral ? '1' : '0'
				}

				this.$api.apiInterface(this.apiName, "changeOpenIntegral").changeOpenIntegral(query, res => {
					console.log("res", res);
					if (res.code == 0) {
						this.$message({
							type: "success",
							message: "修改成功"
						})

						this.initData();

						return;
					}

					this.$message({
						type: "warning",
						message: res.msg
					})
				})

			},
			//分页回调
			pageSizeFun(type, e, pageType) {
				// console.log("pageType",pageType);
				if (type == 'handlePage') {

					if (pageType == 1) {
						this.queryData.page = e;
						//获取列表数据
						this.initData();
					} else {
						this.resourceData.page = e;
						//获取剧本资源库
						this.getScriptList();
					}

				}
			},
			//获取收款记录
			getCollRecord() {
				this.isDialog = true;

				this.collRecordData.theatre_id = this.formData.id;

				this.$api.apiInterface(this.apiName, "payOrder").payOrder(this.collRecordData, res => {
					console.log("获取收款记录", res);
					if (res.code == 0) {
						this.collRecordData.count = Number(res.data.count);
						this.tabListData = res.data.list;

						console.log("this.colList", this.colList);
					}
				})

			},
			//对账确认场次
			confirmSession() {
				console.log("teamList", this.teamList);
				var team_data = [];

				this.teamList.forEach((item, index) => {
					team_data.push({
						team_id: item.id,
						money: item.money
					})
				})

				var queryData = {
					theatre_id: this.formData.id,
					team_data
				}

				this.$api.apiInterface(this.apiName, "comfirm").comfirm(queryData, res => {
					this.isTeam = false;
					console.log("对账确认场次", res);
					if (res.code == 0) {
						this.$message({
							type: "success",
							message: "操作成功"
						})

						this.initData();

						return;
					}

					this.$message({
						type: "warning",
						message: res.msg
					})
				})
			},
			overSession() {

				if (this.deductionInfo.status == 0) {

					if (this.end_time == "") {
						this.$message({
							type: 'warning',
							message: '请选择本场次结束时间'
						});
						return;
					}

					var end_time = new Date(this.end_time).getTime() / 1000;

					//结束场次
					this.$confirm('是否结束该场次?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'

					}).then(() => {

						this.$api.apiInterface(this.apiName, "closing").closing({
							theatre_id: this.formData.id,
							end_time
						}, res => {
							console.log("结束场次", res);
							if (res.code == 0) {
								this.$message({
									type: 'success',
									message: '操作成功!'
								});

								this.initData();
								return;
							}

							this.$message({
								type: 'error',
								message: res.msg
							});
						})

					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消操作'
						});
					});

				} else if (this.deductionInfo.status == 1) {
					//对账确认场次
					this.isTeam = true;
				}

			},
			//获取团队列表
			getTeamList() {
				this.$api.apiInterface(this.apiName, "teamList").teamList({}, res => {
					// console.log("获取团队列表", res);
					if (res.code == 0) {
						var teamList = res.data;

						this.teamList = teamList.map((item, index) => {
							item.money = "";
							return item;
						})
					}
				})
			},
			initData() {

				var queryData = {
					theatre_id: this.formData.id,
					user_id: this.userInfo.user_id
				}

				// console.log('queryData', queryData)

				this.$api.apiInterface(this.apiName, "getTheatreInfo").getTheatreInfo(queryData, res => {
					// console.log("res", res);
					this.isLoadForm = false;

					if (res.code == 0) {
						this.isLoading = false;

						this.deductionInfo = res.data;
						this.deductionInfo.play_member = Number(this.deductionInfo.play_member);

						if (this.deductionInfo.user_list.length > 0) {
							this.deductionInfo.user_list.forEach(item => {
								item.open_integral = item.open_integral == '1' ? true : false;
							})
						}

						if (this.deductionInfo.couponOkList) {
							this.deductionInfo.couponOkList.forEach(item => {
								if (item.books && item.books.length > 0) {
									if (item.books_ids.indexOf(this.deductionInfo.book_id) == -1) {
										item.show = false
									} else {
										item.show = true
									}
								} else {
									item.show = true
								}
							})

							this.deductionInfo.couponOkList.sort((x, y) => {
								if (x.show > y.show) {
									return -1
								} else {
									return 1
								}
								return 0
							})
						}

						return;
					}

					this.$message({
						type: "warning",
						message: res.msg
					})
				})
			},
			selPlay_member(val) {
				this.price = this.deductionInfo.play_money * val;
			},
			paySession() {

				// this.isLoading = true;

				this.isWrapper = true;

				this.isDeduction = true;

			},
			//支付
			pay() {

				this.payBtnTxt = "正在支付";

				this.isPayLoading = false;

				if (!this.selCoupon || this.payform.coupon_id == -1) {
					this.payform.coupon_id = '';
				}

				if (this.price == '') {
					this.payform.price = 0;
				} else {
					this.payform.price = this.price;
				}

				if (this.payform.sleep_price == '') {
					this.payform.sleep_price = 0;
				}

				if (this.payform.other_price == '') {
					this.payform.other_price = 0;
				}

				this.payform.user_id = this.userInfo.user_id;
				this.payform.theatre_id = this.formData.id;

				if (this.priceIndex == 200) {
					this.payform.pay_type = 2;
					this.payform.auth_code = "";
					this.payform.card_no = "";
				} else if (this.priceIndex == 500) {
					this.payform.pay_type = 4;
					this.payform.auth_code = "";
					this.payform.card_no = "";
				} else {
					this.payform.auth_code = '';
					this.payform.pay_type = 1;
					this.payform.card_no = this.deductionInfo.card_list[this.priceIndex].card_no;
				}

				console.log("this.payform", this.payform);

				if (this.payform.pay_type == 2) {
					this.tips = "请扫码支付";

					this.isPay = true;

					this.isWrapper = false;

					this.$nextTick(() => {
						this.$refs.input.focus();
					})

					return;
				}

				this.$api.apiInterface(this.apiName, "scanPay").scanPay(this.payform, res => {
					// console.log("res",res);
					this.isPayLoading = true;

					if (res.code == 0) {
						this.$message({
							type: "success",
							message: "支付成功"
						})

						this.initData();

						return;
					}

					this.$message({
						type: "warning",
						message: res.msg
					})

				})

			},
			changeHandle() {

				// console.log("this.payform.auth_code",this.payform.auth_code);
				this.$api.apiInterface(this.apiName, "scanPay").scanPay(this.payform, res => {
					// console.log("res",res);
					if (res.code == 0) {
						this.tips = "支付成功";
						this.initData();

						setTimeout(() => {
							this.isPay = false;
							this.isDeduction = false;
						}, 2000)
						return;
					}

					this.$message({
						type: "warning",
						message: res.msg
					})

				})
			}
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .el-drawer {
		width: 27% !important;
	}

	.form {
		height: 85%;
		overflow: auto;
		position: relative;
	}

	.label {
		display: block;
		font-size: 22rem;
		margin-bottom: 10rem;
	}

	.session {

		img {
			width: 142rem;
			height: 172rem;
		}

		.mb {
			margin-bottom: 10rem;
		}

		.fs {
			font-size: 18rem;
		}

		.info {
			display: flex;

			.content {
				margin-left: 30rem;
				font-size: 22rem;

				.conPrice {
					color: #f60;
				}
			}
		}

		.settlement {
			display: flex;
			font-size: 22rem;
			margin-top: 20rem;

			.sPrice {
				font-weight: bold;
			}
		}

	}

	.navList {
		display: flex;

		li {
			display: block;
			padding: 10rem 30rem;
			background: #FFE300;
			font-size: 22rem;
			border-radius: 5rem;
			text-align: center;
			cursor: pointer;
			margin-right: 10rem;
			margin-top: 20rem;
		}
	}

	.playerList {
		margin-top: 25rem;
		display: flex;
		align-items: center;
	}

	.dmList {
		margin-top: 25rem;

		ul {
			display: flex;
			margin-top: 25rem;

			li {
				display: flex;
				align-items: center;
				margin-right: 25rem;
				// width: auto;
				// min-width: 170rem;
				// max-width: 350rem;
				height: 100rem;
				border: 2rem dashed #ccc;
				border-radius: 5rem;
				padding: 0 25rem 0 15rem;
				position: relative;

				.name {
					font-size: 22rem;
					margin-left: 15rem;
				}

				.sexIcon {
					width: 20rem;
					height: 20rem;
					position: absolute;
					top: 10rem;
					right: 5rem;
				}
			}
		}
	}

	.sBtn {
		// width: 100rem;
		padding: 10rem 30rem;
		background: #FFE300;
		font-size: 22rem;
		border-radius: 5rem;
		text-align: center;
		cursor: pointer;
		position: absolute;
		bottom: 20rem;
		left: 50%;
		transform: translateX(-50%);
	}

	.deduction {

		// padding: 0 30rem;
		// height: 100%;
		overflow: auto;
		box-sizing: border-box;
		padding-bottom: 80rem;
		box-sizing: border-box;

		.m_xy {
			margin: 20rem 20rem;
		}

		img {
			width: 102rem;
			height: 132rem;
		}

		.mb {
			margin-bottom: 10rem;
		}

		.fs {
			font-size: 18rem;
		}

		.info {
			display: flex;

			.content {
				margin-left: 30rem;
				font-size: 22rem;

				.conPrice {
					color: #f60;
				}
			}
		}

		.coupon {
			margin: 20rem 20rem;

		}

	}

	/deep/ .el-radio__label {
		display: none;
	}

	.deductionItem {
		.deTil {
			font-size: 18rem;
			margin-right: 15rem;
		}

		.input {
			width: 350rem;
			border: 2rem solid #ccc;
			border-radius: 5rem;
			height: 45rem;
			padding-left: 10rem;
			box-sizing: border-box;
		}

		.deNum {
			background: #FFBA71;
			padding: 5rem 15rem;
			color: #fff;
			border-radius: 5rem;
			margin-right: 10rem;
			margin-bottom: 10rem;
			cursor: pointer;
		}

		.textarea {
			width: 100%;
			background: #F0F0F0;
			margin-top: 15rem;
			height: 120rem;
			padding: 15rem 15rem;
			box-sizing: border-box;
		}
	}

	.memberCard {
		/deep/ .el-radio__label {
			display: none;
		}

		.card {
			width: 100%;
			height: 80rem;
			box-shadow: 3rem 3rem 10rem 3rem #ccc;
			border-radius: 10rem;
			overflow: hidden;
			margin-bottom: 20rem;

			.top {
				width: 100%;
				height: 30rem;
				background: #3D9CFD;
				color: #fff;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 10rem 10rem;
				box-sizing: border-box;
			}

			.bottom {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 50rem;
				padding-left: 10rem;
				padding-right: 10rem;
				box-sizing: border-box;
			}

		}
	}

	.pay {
		width: 100%;
		height: 60rem;
		line-height: 60rem;
		background: #FF8400;
		box-shadow: 0rem 3rem 10rem 3rem #ccc;
		position: absolute;
		bottom: 0;
		left: 0;
		text-align: center;
		font-size: 23rem;
		color: #fff;
		user-select: none;
		cursor: pointer;
		z-index: 9999;
	}

	.payTips {
		width: 700rem;
		height: 600rem;
		line-height: 500rem;
		background: #fff;
		border-radius: 10rem;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 10000;
		font-size: 32rem;
		font-weight: bold;
		text-align: center;
		overflow: hidden;

		.tips {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.input {
			width: 700rem;
			height: 600rem;
			text-align: center;
			font-size: 32rem;
		}
	}

	.tips {}

	.teamMoney {
		display: flex;
		margin-top: 30rem;
		flex-direction: column;
		align-items: center;

		li {
			width: 580rem;
			// height: 50rem;
			border-radius: 5rem;
			border: 2rem dashed #ccc;
			margin-bottom: 20rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10rem 20rem;
		}
	}
	
	.closeBtn{
		font-size: 40rem;
		position: absolute;
		top:0;
		right: 20rem;
		cursor: pointer;
		z-index: 999;
	}
	
	.dialog {
		width: 1000rem;
		height: 800rem;
		background: #fff;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-40%, -50%);
		z-index: 9999;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10rem;
		padding: 25rem 25rem;
		box-sizing: border-box;
		box-sizing: 25rem 25rem 25rem 25rem #333;
		// box-shadow: 2px 2px 2px 2px #ccc;
	
		.boxInput {
			display: inline-block;
			width: 100%;
			height: 100%;
			border: 5rem solid #333;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 10rem;
			color: #fff;
			opacity:0;
		}
	
		.scanLogin {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 99;
			transform: translate(-50%, -50%);
			pointer-events: none;
			overflow: initial;
	
			p {
				width: 250rem;
				font-size: 32rem;
				color: #282828;
				text-align: center;
				line-height: 50rem;
			}
		}
	}
	
	.mask {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, .5);
		z-index: 9998;
	}
	
	.coupon_main{
		display: flex;
		flex-direction: column;
		align-items: center;
		
		.main{
			div{
				margin-bottom: 20rem;
			}
		}
	}
</style>
