/**
 * 配置文件
 */
export default {
	/**
	 * 基础设置
	 */
	router: {
		routerMode: 'hash', // hash | history
	},
	baseURL: "https://jhs.csqingyu.com",
	imageURL: "https://jhs.csqingyu.com"
	// baseURL: "http://hushuo.i.tn",
	// imageURL: "http://hushuo.i.tn"
}
 