import Vue from 'vue'
import App from './App.vue'

import func from './common/func'

Vue.prototype.$func = func;

import config from "./config/config.js"

import $load from './lib/load.js';

import $api from './request/api.js'

Vue.prototype.$api = $api;

Vue.config.productionTip = false;

/**
 * QRun 框架启动
 */
$load.load()

var init = new Vue({
  render: h => h(App),
  router:$load.router,
  store:$load.store,
  //事件总线
  beforeCreate () {
    Vue.prototype.$bus = this;
  }
}).$mount('#app')

