<template>
	<div class="recharge">
		<el-card style="width: 100%;">
			<div class="status">
				<span class="icon" style='background: #FFE300;'></span>
				<span style="font-size: 18rem;color: #666666;">充值</span>
			</div>

			<input class="input" placeholder="请输入充值金额" v-model="input">
			</input>

			<div class="submit">确认充值</div>

		</el-card>

	</div>
</template>

<script>
	export default {
		name:"member_recharge",
		data() {
			return {
				input: ""
			}
		}
	}
</script>

<style scoped lang="less">
	.recharge {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0rem 0 0 30rem;
		box-sizing: border-box;
	}

	.input {
		width: 100%;
		background: #F0F0F0;
		padding: 20rem 30rem;
		box-sizing: border-box;
		border-radius: 5rem;
	}

	.submit {
		width: 300rem;
		height: 60rem;
		background: #FFE300;
		text-align: center;
		line-height: 60rem;
		border-radius: 5rem;
		font-size: 18rem;
		margin: 60rem auto 50rem;
		cursor: pointer;
		user-select: none;
	}
	
	.status {
		margin-bottom: 15rem;
		.icon {
			display: inline-block;
			width: 13rem;
			height: 13rem;
			border-radius: 50%;
			margin-right: 10rem;
		}
	}
</style>
