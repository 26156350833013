<template>
	<div>
		<div class="topNav">
			<div style="width: 100%;display: flex;align-items: center;justify-content: space-between;">
				<div style="display: flex;align-items: center;" @click="addVIP">
					<img src="../../static/addIcon.png" alt="">
					<span>添加会员</span>
				</div>
				<div>
					<el-input style="width: 250rem;" type='number' v-model="userForm.mobile" placeholder="请填写用户手机号"
						size='small'></el-input>
					<el-select style="width: 170rem;" v-model="is_dm" placeholder="筛选会员" size='small'>
						<el-option label="全部" value="-1"></el-option>
						<el-option label="会员" value="0"></el-option>
						<el-option label="DM" value="1"></el-option>
					</el-select>
					<el-button style="background-color: #FFE300 ;border:1rem solid #FFE300 ;color: black;"
						icon='el-icon-search' size='small' @click="filter"></el-button>
					<el-button style="background-color: #FFE300 ;border:1rem solid #FFE300 ;color: black;"
						icon='el-icon-refresh' size='small' @click="refresh"></el-button>
				</div>
			</div>
		</div>
		<el-card>
			<el-table :data="userList" stripe style="width: 1650rem">
				<el-table-column prop="user_id" label="id" sortable align='center'>
				</el-table-column>
				<el-table-column label="昵称" align='center' width='120'>
					<template slot-scope="scope">
						<span v-if="scope.row.nickname">{{scope.row.nickname}}</span>
						<span v-else>暂未设置</span>
					</template>
				</el-table-column>
				<el-table-column label="头像" align='center'>
					<template slot-scope="scope">
						<img v-if="scope.row.avatar" class="imgIcon" :src="scope.row.avatar" alt="头像">
						<span v-else>暂未设置</span>
					</template>
				</el-table-column>
				<el-table-column prop="mobile" label="手机号" align='center' width='120'>
				</el-table-column>
				<el-table-column label="性别" align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.sex == 1">公子</span>
						<span v-else>仙子</span>
					</template>
				</el-table-column>
				<el-table-column prop="birthday" label="生日" align='center' width='100'>
				</el-table-column>
				<el-table-column label="会员卡" align='center'>
					<template slot-scope="scope">
					<el-button  style="background-color: #FFE300 ;border: none;" circle
							size='mini' icon='el-icon-bank-card' @click="cardBtn(scope.row)"></el-button>
					</template>
				</el-table-column>

				<el-table-column label="DM状态" align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.is_dm == 1">胜任</span>
						<span v-else>无</span>
					</template>
				</el-table-column>
				<el-table-column prop="dm_time" sortable label="成为DM时间" align='center' width='180'>
					<template slot-scope="scope">
						<span v-if="scope.row.is_dm == 1">{{scope.row.dm_time}}</span>
						<span v-else>无为</span>
					</template>
				</el-table-column>
				<el-table-column prop="reg_time" sortable label="注册时间" align='center' width='180'>
				</el-table-column>
				<el-table-column prop="last_time" sortable label="最后登录时间" align='center' width='180'>
					<template slot-scope="scope">
						<span v-if="scope.row.last_time">{{scope.row.last_time}}</span>
						<span v-else>最近暂未登录</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align='center' fixed="right" width='220'>
					<template slot-scope="scope">
						<div>
							
							<el-button type="primary" size='mini'
								@click="userLogin(scope.row)">
								会员登录
							</el-button>
							
							<el-button type="warning" icon="el-icon-edit" size='mini'
								@click="changeUser(scope.row)">
								编辑
							</el-button>

						</div>
					</template>
				</el-table-column>
			</el-table>

			<div style="text-align: center;">
				<el-pagination @current-change="userhandleCurrentChange" :page-size="10"
					layout="prev, pager, next,->, total" :total="+userTotal">
				</el-pagination>
			</div>

		</el-card>

		<!-- 修改会员DM状态 -->
		<el-dialog title="修改DM状态" :visible.sync="userStatus" width="60%">
			<el-form ref="form" :model="userInfo" label-width="240rem" size='mini'>

				<el-form-item label="昵称:">
					<span v-if="userInfo.nikename">{{userInfo.nikename}}</span>
					<span v-else>暂未设置</span>
				</el-form-item>
				<el-form-item label="头像:">
					<img v-if="userInfo.avatar" class="imageIcon" :src="userInfo.avatar" alt="头像">
					<span v-else>暂未设置</span>
				</el-form-item>
				<el-form-item label="手机号:">
					{{userInfo.mobile}}
				</el-form-item>
				<el-form-item label="DM:">
					<el-switch style="display: block" v-model="userInfo.is_dm" active-color="#FFE300" active-text="是"
						inactive-text="不是">
					</el-switch>
				</el-form-item>
				<el-form-item label="工资类型:">
					<el-radio v-model="user.salary_type" label="1">固定</el-radio>
					<el-radio v-model="user.salary_type" label="2">比例</el-radio>
				</el-form-item>
				<el-form-item label="盒装工资:">
					<el-input v-model="user.salary_box" style="width: 300rem;" size="mini" placeholder="请输入盒装工资"></el-input>
				</el-form-item>
				<el-form-item label="城限工资:">
					<el-input v-model="user.salary_city" style="width: 300rem;" size="mini" placeholder="请输入城限工资"></el-input>
				</el-form-item>
				<el-form-item label="独家工资:">
					<el-input v-model="user.salary_sole" style="width: 300rem;" size="mini" placeholder="请输入独家工资"></el-input>
				</el-form-item>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="userStatus = false" size='small'>取 消</el-button>
				<el-button type="primary" :loading="loading" @click="changeUserBtn" size='small'>确 定</el-button>
			</span>
		</el-dialog>
		<!-- 添加会员 -->
		<el-dialog title="添加会员" :visible.sync="addUser" width="40%">
			<el-form ref="form" :model="setUser" label-width="240rem" size='small'>
				<el-form-item label="手机号:">

					<el-input style="width: 250rem;" type='number' v-model="setUser.mobile" placeholder="请填写手机号">
					</el-input>
				</el-form-item>
				<el-form-item label="性别:">
					<el-radio-group v-model="setUser.sex" size="mini">
						<el-radio label="1">公子</el-radio>
						<el-radio label="2">仙子</el-radio>

					</el-radio-group>
				</el-form-item>
				<el-form-item label="生日:">
					<el-date-picker style="width: 250rem;" v-model="setUser.birthday" type="date" placeholder="选择日期">
					</el-date-picker>
				</el-form-item>


			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="addUser = false" size='small'>取 消</el-button>
				<el-button type="primary" :loading="loading" @click="addUserbtn" size='small'>确 定</el-button>
			</span>
		</el-dialog>
		<!-- 会员卡列表 -->
		<el-dialog title="会员卡" :visible.sync="card" width="60%">
			<el-table :data="cardList" size='mini'   height="400rem" >
				<el-table-column property="id" label="id" align='center' width = '80'></el-table-column>
				<el-table-column width="150" property="card_name" label="会员卡名称" align='center'>
				</el-table-column>
				
				<el-table-column  width="200"  label="会员卡卡号" align='center'>
					<template slot-scope="scope">
						<span>{{scope.row.card_no ? scope.row.card_no : '暂未开通'}}</span>
					</template>
				</el-table-column>
				
				<el-table-column label="余额" align='center'>
					<template slot-scope="scope">
						{{scope.row.money ? scope.row.money : '0.00'}}
					</template>
				</el-table-column>
				
				<el-table-column  label="类型" align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">立减卡</span>
						<span v-else>打折卡</span>
					</template>
				</el-table-column>
				<el-table-column  label="折扣" align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">{{scope.row.scale}}元</span>
						<span v-else>{{scope.row.scale}}折</span>
					</template>
				</el-table-column>
				<el-table-column  label="开通时间" align='center' width = '150'>
					<template slot-scope="scope">
						<span >{{scope.row.get_time ? $func.formatTime(scope.row.get_time) :'暂未开通' }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align='center' fixed="right">
					<template slot-scope="scope">
						
						<el-button v-if="scope.row.card_no" circle type="primary"  icon="el-icon-s-finance" size='mini'
							@click="upCard(scope.row)">
						</el-button>
						
						
						<el-popconfirm
							title="确定开通此会员卡吗？"
							v-else
							@confirm = 'openCard(scope.row.id)'
						>
						  <el-button slot="reference" circle type="primary"  icon="el-icon-s-claim" size='mini'>
						  </el-button>
						</el-popconfirm>
						
						<!-- <el-button 
							@click="upCard(scope.row)">
						</el-button> -->
						
					</template>
				</el-table-column>
				
			</el-table>
		</el-dialog>
		
		<!-- 会员卡充值 -->
		<el-dialog title="会员卡充值" :visible.sync="upCardStatus" width="40%">
			<el-form ref="form" :model="cardItem" label-width="240rem" size='small'>
				
				<el-form-item label="会员卡名称:">
					{{cardItem.card_name}}
				</el-form-item>
				
				<el-form-item label="会员卡卡号:">
					{{cardItem.card_no}}
				</el-form-item>
				
				<el-form-item label="充值金额:">
					<el-input style="width: 250rem;" type='number' v-model="cardInfo.money" placeholder="请输入充值金额">
					</el-input>
					<div class="tip" >
						提示：此卡充值金额最低为{{cardItem.min_renew_price}}元！
					</div>
				</el-form-item>
				
				<el-form-item label="充值说明:">
					<el-input :rows=5  type="textarea" v-model="cardInfo.msg"
						placeholder='请输入充值说明'></el-input>
				</el-form-item>
			
			</el-form>
			
			<span slot="footer" class="dialog-footer">
				<el-button @click="upCardStatus = false" size='small'>取 消</el-button>
				<el-button type="primary" :loading="loading" @click="upSubmit" size='small'>确 定</el-button>
			</span>
		</el-dialog>
		
	</div>
</template>

<script>
	export default {
		name: "index_staff",
		menuName:"会员列表",
		data() {
			return {
				//接口参数
				apiName: 'user',
				loading: false,
				//用户列表
				is_dm: '',
				userList: [],
				userTotal: '',
				userForm: {
					mobile: '',
					is_dm: -1,
					page: 1,
					limit: 10
				},
				//修改会员DM状态
				userStatus: false,
				userInfo: {},
				user: {
					user_id: '',
					type: '',
					salary_type:"1",
					salary_box:"",
					salary_city:"",
					salary_sole:""
				},
				//添加用户
				addUser: false,
				setUser: {
					mobile: '',
					birthday: '',
					sex: '1'
				},
				//会员卡
				userID:'',
				card:false,
				cardList:[],
				upCardStatus:false,
				cardItem:{},
				cardInfo:{
					user_id:'',
					money:'',
					msg:'',
					card_no:''
				}
			}
		},
		inject: ["layout"],
		components: {},
		created() {
			this.getUserList()
		},
		methods: {
			//会员登录
			userLogin(item){
				console.log("item",item);
				this.$api.apiInterface("Member","idLogin").idLogin({user_id:item.user_id},res=>{
					if(res.code == 0){
						this.$message({
							type: "success",
							message: '登录成功'
						})
						
						var userInfo = res.data;
						
						localStorage.setItem("userInfo",JSON.stringify(userInfo));
						
						this.$router.push({
							path: "/member/index?isLogin=true"
						})
						return;
					}
					this.$message({
						type: "warning",
						message: res.msg
					})
				})
			},
			//获取用户列表
			getUserList() {
				this.layout.loading = true;
				this.$api.getList(this.apiName, this.userForm, (res) => {
					this.layout.loading = false;
					if (res.code == 0) {
						this.userList = res.data.list
						this.userTotal = res.data.count
						this.userList.forEach(item => {
							item.reg_time = this.$func.formatTime(item.reg_time)
							item.birthday = this.$func.formatYear(item.birthday)
							if (item.last_time) {
								item.last_time = this.$func.formatTime(item.last_time)
							}
							if (item.dm_time) {
								item.dm_time = this.$func.formatTime(item.dm_time)
							}
							return item
						})

					} else {
						this.$message.error(res.msg);
					}
				})
			},
			//刷新
			refresh() {
				this.is_dm = ''
				this.userForm.is_dm = -1
				this.userForm.mobile = ''
				this.getUserList()
			},
			//筛选
			filter() {
				if (this.is_dm) {
					this.userForm.is_dm = this.is_dm
				} else {
					this.userForm.is_dm = -1
				}

				this.getUserList()
			},
			//用户分页
			userhandleCurrentChange(val) {
				this.userForm.page = val
				this.getUserList()
			},

			//添加用户
			addVIP() {
				this.addUser = true
				this.setUser = {
					mobile:'',
					birthday:'',
					sex: '1'
				}
			},
			//确定添加
			addUserbtn() {
				this.loading = true
				
				let phone = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
				
				if(this.setUser.mobile && phone.test(this.setUser.mobile)){
					
				}else{
					this.loading = false
					this.$message.error('请填写正确的手机号');
					return
				}
				
				
				if(this.setUser.birthday){
					this.setUser.birthday = Math.ceil(new Date(this.setUser.birthday).getTime() / 1000)
				}else{
					this.loading = false
					this.$message.error('生日暂未选择，请完善');
					return
				}
				
				
				this.$api.apiInterface(this.apiName, 'addUser').addUser(this.setUser, res => {
					this.loading = false
					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，操作成功',
							type: 'success'
						});
						this.addUser = false
						this.getUserList()
					} else {
						this.$message.error(res.msg);
					}
				
				})
				
			},
			
			//修改用户信息弹窗
			changeUser(val) {

				this.userStatus = true

				this.userInfo = JSON.parse(JSON.stringify(val))

				if (this.userInfo.is_dm == 1) {
					this.userInfo.is_dm = true
				} else {
					this.userInfo.is_dm = false
				}


			},
			//修改用户信息
			changeUserBtn() {
				this.loading = true

				if (this.userInfo.is_dm) {
					this.user.type = 1
				} else {
					this.user.type = 0
				}

				this.user.user_id = this.userInfo.user_id

				this.$api.apiInterface(this.apiName, 'setDm').setDm(this.user, res => {
					this.loading = false
					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，修改成功',
							type: 'success'
						});
						this.userStatus = false
						this.getUserList()
					} else {
						this.$message.error(res.msg);
					}

				})
			},
			//会员卡
			cardBtn(val){
				console.log("val",val);

				
				this.userID = val.user_id
				this.card = true
				
				
				this.cardList = Object.values(val.cards)
			},
			//充值会员卡弹窗
			upCard(val){
				this.upCardStatus = true
				this.cardItem = val
				this.cardInfo= {
					user_id:'',
					money:'',
					msg:'',
					card_no:''
				}
			},
			//充值
			upSubmit(){

				if(+this.cardInfo.money < +this.cardItem.min_renew_price){
					this.$message.error('未达到此卡最低充值金额，请重新充值');
					this.cardInfo.money = ''
					return
				}
				
				this.loading = true
				
				this.cardInfo.user_id = this.userID
				this.cardInfo.card_no = this.cardItem.card_no

				
				this.$api.apiInterface(this.apiName, 'recharge').recharge(this.cardInfo, res => {
					this.loading = false
					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，充值成功',
							type: 'success'
						});
						this.getUserList()
						this.card = false
						this.upCardStatus = false
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			
			//开通会员卡
			openCard(val){
				
				
				
				this.$api.apiInterface(this.apiName, 'openCard').openCard({
					user_id:this.userID,
					card_id:val
				}, res => {
					this.loading = false
					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，开通成功',
							type: 'success'
						});
						this.getUserList()
						this.card = false
					} else {
						this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">
	/deep/.el-card__body,
	.el-main {
		padding: 0 20rem;
		max-height: 980rem;
		height: auto;
	}

	/deep/.el-dialog__body {
		padding: 30rem 20rem 0px;
		color: #606266;
		font-size: 14px;
		word-break: break-all;
	}

	// /deep/.el-switch__label.is-active {
	//     color: #FFE300;
	// }


	.topNav {
		display: flex;
		font-size: 16rem;
		color: #409EFF;
		align-items: center;
		margin-bottom: 20rem;
		margin-left: 25rem;
		cursor: pointer;
		user-select: none;

		img {
			width: 26rem;
			height: 26rem;
			margin-right: 12rem;
		}
	}

	.imgIcon {
		width: 80rem;
		height: 80rem;
	}

	.imageIcon {
		width: 160rem;
		height: 160rem;
	}
	
	.tip {
		font-size: 12px;
		color: #C0C4CC;
	
		div {
			line-height: 0;
		}
	}
</style>
