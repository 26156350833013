<template>
	<div>
		404
	</div>
</template>

<script>
	export default {
		default:'404'
	}
</script>

<style>
</style>