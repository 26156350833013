<template>
	<div style="height: 100%;display: flex;flex-direction: column;position: relative;">
		<div class="topNav">
			<div style="display: flex;align-items: center;" @click="listHandle('add')">
				<img class="topImg" src="../../static/addIcon.png" alt="">
				<span>添加剧本</span>
			</div>
		</div>
		<el-card style="flex: 1;display: flex;flex-direction: column;">
			<div class="status">
				<span class="icon"></span>
				<span style="font-size: 18rem;color: #666666;">剧本</span>
			</div>

			<!-- 列表 -->
			<div class="playList"
				style="height: 95%;padding-bottom: 30rem;box-sizing: border-box;  overflow-y: scroll;">
				<!-- 筛选 -->
				<div style="padding-right: 50rem;box-sizing: border-box;margin: 30rem 0;">
					<topNav :navCallBack="navCallBack" :navOptions="screenOptions"></topNav>
				</div>

				<div style="margin-bottom: 30rem;" v-for="(item,index) in listData.list" :key="index">
					<!-- <keep-alive> -->
					<listItem :listCallback="listCallback" :item="item">
					</listItem>
					<!-- </keep-alive> -->
				</div>

				<pagination :callback="pageSizeFun" pageType="1" :limit="queryData.limit" :page="queryData.page"
					:count="listData.count"></pagination>
			</div>

			<!-- 表单 -->
			<div style="margin-top: 30rem;height: 95%;">
				
				<formPage ref="formPage" :isEdit.sync="isEdit" :colList.sync="colList" :listData.sync="playResource.list"
					:formData="formData" :formCallBack="formCallBack">
					<!-- 筛选 -->
					<template #screenArea>
						<topNav :navCallBack="navCallBack" :navOptions="screenOptions"></topNav>
					</template>
					<!-- 分页 -->
					<template #formPageSize>
						<pagination :limit="resourceData.limit" pageType="2" :callback="pageSizeFun" :page="resourceData.page"
							:count.sync="playResource.count"></pagination>
					</template>
				</formPage>
				
				<!-- 遮罩 -->
				<!-- <div class="mask"></div> -->

			</div>

		</el-card>
	</div>
</template>

<script>
	import pagination from "@/components/pagination/index.vue"
	import formPage from "@/components/playForm/index.vue"
	import topNav from "@/components/topNav/index.vue"
	import listItem from "@/components/listItem/index.vue"
	
	var that = null;

	export default {
		name: "index_play",
		menuName: "剧本列表",
		data() {
			return {
				//----接口名称----
				apiName: "books",
				//表头数据
				colList: [
					{
						lable: 'ID',
						key: 'id',
						type: 'text'
					},
					{
						lable: '剧本标题',
						key: 'title',
						type: 'text'
					},
					{
						lable: '工作室',
						key: 'publisher',
						type: 'text'
					},
					{
						lable: '主题',
						key: 'theme',
						type: 'text'
					},
					{
						lable: '封面图',
						key: 'headPic',
						type: 'image'
					},
					{
						lable: '简短介绍',
						key: 'shortDesc',
						type: 'textarea',
						width: 220
					},
					{
						lable: '作者',
						key: 'author',
						type: 'text'
					},
					{
						lable: '剧本类型',
						key: 'type',
						type: 'checkBox'
					},
					{
						lable: '发售类型',
						key: 'specification',
						type: 'text'
					},
					{
						lable: '难度',
						key: 'difficulty',
						type: 'radio'
					},
					{
						lable: '写入时间',
						key: 'create_time',
						type: 'time',
						width: 120
					},
					{
						lable: '操作',
						key: 'action',
						type: 'buttons',
						fixed: 'right',
						isShow: true,
						width: 150,
						options: [{
							type: 'btn',
							title: "选择该本",
							clickType: 'sureClick',
							btnType: "primary"
						}]
					}
				],
				formData: {
					book_id: "",
					recommend: "",
					price: "",
					is_recommend: '0',
					is_preview: '0',
					is_top: '0',
					status: '1',
					dm_number:"1",
					dm_info:[]
				},
				//后台接口数据渲染结构
				listData: {
					count: 0,
					list: []
				},
				isEdit: false,
				queryData: {
					limit: 5,
					page: 1,
					specification: "",
					title: ""
				},
				//剧本资源库筛选参数
				screenOptions: [{
						key: 'title',
						value: '',
						lable: "标题",
						isLike: true,
						type: 'txt'
					},
					{
						key: 'specification',
						value: '',
						lable: "发售类型",
						type: 'status',
						options: [{
								name: "全部",
								key: ''
							},
							{
								name: "城限",
								key: '城限'
							},
							{
								name: "盒装",
								key: '盒装'
							},
							{
								name: "独家",
								key: '独家'
							}
						]
					}
				],
				//剧本库列表参数
				resourceData: {
					limit: 5,
					page: 1,
					title: "",
					specification: ""
				},
				//剧本资源库
				playResource: {
					count: 0,
					list: []
				},
				isMask:false
			}
		},
		components: {
			pagination,
			formPage,
			topNav,
			listItem
		},
		inject: ['layout'],
		created() {
			
			that = this;
			
			//获取列表数据
			this.initData();

			//获取剧本资源库
			this.getScriptList();
		},
		beforeRouteLeave (to, from, next) {
			
			if(to.name == "index_basics"){
				that.isMask = false;
			}
			
			next();

		},
		methods: {
			//筛选回调
			navCallBack(dataList) {

				dataList.forEach(item => {
					this.resourceData.page = 1;

					this.resourceData[item.key] = item.value;

					this.queryData[item.key] = item.value

				})

                if(this.isEdit){
					this.getScriptList();
				}else{
					this.initData();
				}


			},
			//分页回调
			pageSizeFun(type, e,pageType) {
				// console.log("pageType",pageType);
				if (type == 'handlePage') {
					
					if(pageType == 1){
						this.queryData.page = e;
						//获取列表数据
						this.initData();
					}else{
						this.resourceData.page = e;
						//获取剧本资源库
						this.getScriptList();
					}
					
				}
			},
			//获取剧本资源库
			getScriptList() {

				this.layout.loading = true;

				this.$api.apiInterface(this.apiName, 'getResources').getResources(this.resourceData, (res) => {
					if (res.code == 0) {

						this.playResource.list = res.data.list;
						this.playResource.count = Number(res.data.count);

						this.layout.loading = false;
					}
				})

			},
			//添加/编辑剧本
			listHandle(type, val) {
                
				if(type != 'edit'){
					
					this.queryData = {
						limit: 5,
						page: 1,
						specification: "",
						title: ""
					}
					
				}

				this.resourceData = {
					limit: 5,
					page: 1,
					specification: "",
					title: ""
				}
				
				//获取剧本资源库
				this.getScriptList();

				if (type == 'add') {
					
					this.$refs.formPage.playInfo = null;
					
					this.formData = {
						book_id: "",
						recommend: "",
						price: "",
						is_recommend: '0',
						is_preview: '0',
						is_top: '0',
						status: '1',
						dm_number:"1",
						dm_info:[],
						playInfo:null
					}
					
					this.formData.status = this.formatStatus(this.formData.status);
					
				} else {

					for (var key in this.formData) {
						
						this.formData[key] = val[key];

						this.formData.playInfo = {
							headPic: val.headPic,
							title: val.title,
							list_type: val.type,
							shortDesc: val.shortDesc,
							specification: val.specification
						}

						this.formData.is_recommend = this.formatStatus(val.is_recommend);
						this.formData.is_preview = this.formatStatus(val.is_preview);
						this.formData.is_top = this.formatStatus(val.is_top);
						this.formData.status = this.formatStatus(val.status);
						
						// if(key == "dm_info"){
							
						// 	this.formData.dm_info = this.formData.dm_info == "" ? [] : JSON.parse(this.formData.dm_info);
							
						// }
						
						
					}
					
				}

				this.isEdit = true;
				
				this.isMask = true;
			},
			formatStatus(status) {
				if (typeof status == "boolean") {
					return status == true ? '1' : '0';
				} else {
					return status == '1' ? true : false;
				}
			},
			//表单回调
			formCallBack(type, formData) {

				if (type == 'confirm') {
					formData.is_recommend = this.formatStatus(formData.is_recommend);
					formData.is_preview = this.formatStatus(formData.is_preview);
					formData.is_top = this.formatStatus(formData.is_top);
					formData.status = this.formatStatus(formData.status);

					delete formData.playInfo;

					this.isEdit = false;
					this.isMask = false;
					
					this.listEdit(formData);

				} else if (type == 'cancel') {
					this.isEdit = false;
					this.isMask = false;
					this.initData();
				}else if(type == "closeDialog"){
					this.isEdit = false;
					this.isMask = false;
				}
				//初始化剧本库资源
				else {
					
					this.resourceData.page = 1;
					
					this.initData();

					this.getScriptList();
					
				}
				
			},
			//列表回调
			listCallback(type, val) {
				console.log("val,type",type, val);
				switch (type) {
					//剧本编辑
					case 'edit':
						var queryData = {
							id:val.id,
							book_id: val.book_id,
							recommend: val.recommend,
							price: val.price,
							is_recommend: val.is_recommend,
							is_preview: val.is_preview,
							is_top: val.is_top,
							status: val.status
						}
						

						this.listEdit(queryData);
						break;
						//剧本上下架
					case 'status':
						var queryData = {
							id: val.id,
							status: val.status
						}

						this.scriptSXHandle(queryData);
						break;
						//剧本删除
					case 'del':
						var queryData = {
							id: val.id
						}

						this.listDel(queryData);
						break;
					case 'editPlay':

						this.listHandle('edit', val);
						break;
				}
			},
			//剧本上下架
			scriptSXHandle(queryData) {
				console.log('queryData', queryData)

				this.$api.apiInterface(this.apiName, 'changStatus').changStatus(queryData, (res) => {
					if (res.code == 0) {
						this.$message({
							type: 'success',
							message: '操作成功'
						});
						this.initData();
						return;
					}
					this.$message({
						type: 'warning',
						message: res.msg
					});
				})
				
			},
			//剧本删除
			listDel(queryData) {
				this.$api.listDel(this.apiName, queryData, res => {
					// console.log("----",res);
					if (res.code == 0) {
						this.$message({
							type: 'success',
							message: '删除成功'
						});
						
						this.initData();
						return;
					}
					this.$message({
						type: 'warning',
						message: res.msg
					});
				})
			},
			//编辑/添加列表
			listEdit(query) {
				
				var queryData = Object.assign(query);
				
				queryData.dm_info = JSON.stringify(queryData.dm_info);

				this.$api.listEdit(this.apiName, queryData, res => {
					// console.log("----",res);
					if (res.code == 0) {
						this.$message({
							type: 'success',
							message: '操作成功'
						});

						this.initData();
					}
				})

			},
			//获取列表
			initData() {
				this.layout.loading = true;

				this.$api.getList(this.apiName, this.queryData, res => {
					// console.log("----",res);
					if (res.code == 0) {
						var listData = res.data.list;

						this.listData.count = Number(res.data.count);

						this.listData.list = listData.map(item => {
							item.isRecommend = item.is_recommend == 1 ? true : false;
							item.isPreview = item.is_preview == 1 ? true : false;
							item.dm_info =  item.dm_info == "" ? [] : JSON.parse(item.dm_info);
							return item;
						})

						this.layout.loading = false;
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">
	/deep/ .is-leaf {
		background: #9DD578 rgba(157, 213, 120, .15) !important;
	}

	// .playList::-webkit-scrollbar{
	// 	display: none;
	// }

	.topNav {
		display: flex;
		font-size: 16rem;
		color: #409EFF;
		align-items: center;
		margin-bottom: 20rem;
		margin-left: 25rem;
		cursor: pointer;
		user-select: none;

		img {
			width: 26rem;
			height: 26rem;
			margin-right: 12rem;
		}
	}

	.status {
		margin-bottom: 15rem;

		.icon {
			display: inline-block;
			width: 13rem;
			height: 13rem;
			background: #9DD578;
			border-radius: 50%;
			margin-right: 10rem;
			box-sizing: border-box;
		}
	}
	
	.mask{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, .5);
	}

</style>
