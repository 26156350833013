<template>
	<div class="script">
		<div class="palyItem">
			<div class="palyImg">
				<el-image class="img"
				      :src="item.headPic"
				      fit="fill"></el-image>
				
				
				<div class="specification">
					<el-tag size="mini" :type="saleType">{{item.specification}}</el-tag>
				</div>
				
			</div>
			<div class="palyInfo">
				<h2 class="til">{{item.title}}</h2>
				<ul class="cate">
					<li v-for="(listItem,listIndex) in listType" :key="listIndex">{{listItem}}</li>
				</ul>
				<p class="price" style="">￥{{item.price}}</p>
				<ul class="control">
					<li @click="listHandle('top')">
						<img src="../../static/topIcon.png" alt="">
						<span style="color: #409EFF;">置顶</span>
					</li>
					<li @click="listHandle('status')">
						<img src="../../static/shopIcon.png" alt="">
						<span style="color: #409EFF;">{{item.status==1 ? '下架':'上架'}}</span>
					</li>
					<li @click="listHandle('del')">
						<img src="../../static/delIcon.png" alt="">
						<span style="color: #409EFF;">删除</span>
					</li>
				</ul>
			</div>
		</div>
		
		<div class="dmList">
			<span>DM配置：</span>
			<template v-if="item.dm_info && item.dm_info.length > 0">
				<el-tag style="margin-right: 20rem;" size="mini" v-for="(item,index) in item.dm_info" :key="">
					
					{{item.type == 1 ? "主D" : item.name }}<span v-if="item.money!=''">({{item.money}})</span> 
				</el-tag>
			</template>
			
			<el-tag size="mini" v-else>暂无DM</el-tag>
		</div>
		
		<div class="itemControl">
			<p style="display: flex;align-items: center;">
				<span style="margin-right: 30rem;color: #666666;">店铺推荐</span>
				<el-switch @change="listHandle('edit')" v-model="item.isRecommend">
				</el-switch>
		    </p>
			<p style="display: flex;align-items: center;margin-top: 30rem;">
				<span style="margin-right: 30rem;color: #666666;">新本预告</span>
				<el-switch @change="listHandle('edit')" v-model="item.isPreview">
				</el-switch>
			</p>
			<div style="margin-top: 20rem;margin-left: 82rem;" class="conBtn">
				<el-button type="primary" size="mini" @click="listHandle('editPlay')">编辑</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		props: {
			item: Object,
			listCallback: Function
		},
		computed: {
			//分类
			listType() {

				var index = this.item.type.indexOf(',');
				
				if(index!=-1){
					return this.item.type = this.item.type.split(',');
				}else{
					return this.item.type = [this.item.type];
				}
				
			},
			//发售类型
			saleType(){
				if(this.item.specification == "城限"){
					return 'danger'
				}else if(this.item.specification == "独家"){
					return 'primary'
				}else{
					return 'warning'
				}
				
			}
		},
		created() {

		},
		methods: {
			//消息提示
			showMessage(msg,callback){
				this.$confirm(`是否${msg}该剧本?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					callback();
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
			},
			listHandle(type) {
				switch (type) {
					//编辑列表
					case 'edit':
						this.item.is_recommend = this.item.isRecommend == true ? '1' : '0';
					    this.item.is_preview = this.item.isPreview == true ? '1' : '0';
						this.listCallback(type, this.item);
						break;
					//剧本上下架
					case 'status':
					    var msg = this.item.status==1 ? '下架' : '上架';
						
						this.showMessage(msg,()=>{
							this.item.status = this.item.status == 1 ? '0' : '1';
							this.listCallback(type, this.item);
						})
						
						break;
					//剧本置顶
					case 'top':
					    var msg = this.item.is_top==1 ? '取消置顶' : '置顶';
						
						this.showMessage(msg,()=>{
							this.item.is_top = this.item.is_top == 1 ? '0' : '1';
							this.listCallback('edit', this.item);
						})
						break;
					//剧本删除
					case 'del':
					
					    this.showMessage('删除',()=>{
						    this.listCallback(type, this.item);
					    })
						
						break;
					//剧本编辑
					case 'editPlay':
					
						this.listCallback(type, this.item);
						
						break;
				}


			}
		}
	}
</script>

<style scoped lang="less">
	.script {
		display: flex;
		justify-content: space-between;
	}

	.control {
		display: flex;
		margin-top: 20rem;

		li {
			display: flex;
			align-items: center;
			margin-right: 25rem;
			cursor: pointer;

			img {
				width: 28rem;
				height: 28rem;
				margin-right: 6rem;
			}
		}
	}

	.palyItem {
		// width: 500rem;

		display: flex;

		.palyImg {
			width: 135rem;
			height: 170rem;
			margin-right: 15rem;
			position: relative;

			.img {
				width: 100%;
				height: 100%;
			}
			
			.specification{
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		.palyInfo {
			.price {
				color: #FF284C;
				font-weight: bold;
				font-size: 22rem;
			}

			.til {
				font-size: 28rem;
				color: #333;
			}

			.cate {
				display: flex;
				margin: 10rem 0;

				li {
					background: rgba(64, 158, 255, .5);
					border-radius: 20rem;
					text-align: center;
					padding: 5rem 15rem;
					color: #fff;
					margin-right: 10rem;
				}
			}

			.info {
				width: 260rem;
				color: #ccc;
				//超出两行省略号
				display: -webkit-box;
				overflow: hidden;
				-webkit-box-orient: vertical;
				line-clamp: 3;
				-webkit-line-clamp: 3; //显示几行
			}
		}
	}

	.itemControl {
		margin-right: 50rem;
	}
</style>
