<template>
	<div>
		<div class="topNav">
			<div style="width: 100%;display: flex;align-items: center;justify-content: space-between;">
				<div style="display: flex;align-items: center;" @click="addShopBtn(null)">
					<img src="../../static/addIcon.png" alt="">
					<span>添加商品分类</span>
				</div>
			</div>
		</div>

		<el-card>
			<el-table :data="shopTypeList" style="width: 100%" max-height='980rem'>
				<el-table-column prop="id" sortable label="id" width="180" align='center'>
				</el-table-column>
				<el-table-column prop="ord" sortable label="ord" width="180" align='center'>
				</el-table-column>
				<el-table-column prop="name" label="商品分类" width="180" align='center'>
				</el-table-column>
				<el-table-column prop="update_time" label="更新时间" width="180" align='center'>
				</el-table-column>
				<el-table-column label="操作" align='center' fixed="right">
					<template slot-scope="scope">
						<div>
							<el-button circle type="primary" icon="el-icon-edit" size='mini'
								@click="addShopBtn(scope.row)" style="margin-right: 10rem;">
							</el-button>

							<el-popconfirm confirm-button-text='确定' cancel-button-text='不用了' icon="el-icon-info"
								icon-color="red" :title="`确定删除${scope.row.name}商品分类？`" @confirm='delShopBtn(scope.row)'>
								<el-button type="danger" slot="reference" circle icon="el-icon-delete" size='mini'>
								</el-button>
							</el-popconfirm>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</el-card>


		<!-- 添加商品分类 -->
		<el-dialog :title="title" :visible.sync="addType" width="40%">
			<el-form ref="form" :model="form" label-width="240rem" size='small'>
				<el-form-item label="产品分类名称:">

					<el-input style="width: 250rem;" v-model="form.name" placeholder="请输入产品分类名称">
					</el-input>
				</el-form-item>

				<el-form-item label="排序号:">

					<el-input style="width: 250rem;" type='number' v-model="form.ord" placeholder="请输入排序号">
					</el-input>
					<div class="tip">
						提示：不填写时默认为0,数字越大越前
					</div>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addType = false" size='small'>取 消</el-button>
				<el-button type="primary" :loading="loading" @click="submit" size='small'>确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: "index_shop",
		menuName: "商品分类",
		data() {
			return {
				apiName: 'Shop',

				title: '',
				loading: false,
				//商品分类
				shopTypeList: [],
				//添加，修改商品分类
				addType: false,
				form: {
					name: '',
					ord: '',
					id: ''
				},

			}
		},
		inject: ["layout"],
		components: {},
		created() {
			this.getShopType()
		},
		methods: {

			//获取商品分类
			getShopType() {
				this.layout.loading = true
				this.$api.apiInterface(this.apiName, 'getCate').getCate({}, res => {
					this.layout.loading = false
					if (res.code == 0) {
						this.shopTypeList = res.data.list
						this.shopTypeList.forEach(item => {
							item.update_time = this.$func.formatTime(item.update_time)
							return item
						})
					} else {
						this.$message.error(res.msg);
					}

				})
			},
			//添加,修改商品分类
			addShopBtn(val) {
				this.addType = true

				if (val) {
					this.form.name = JSON.parse(JSON.stringify(val.name))
					this.form.ord = +JSON.parse(JSON.stringify(val.ord))
					this.form.id = JSON.parse(JSON.stringify(val.id))
					this.title = '修改商品分类'
				} else {
					this.title = '添加商品分类'
					this.form = {}
				}

			},
			//确定添加，修改
			submit() {
				this.loading = true
				this.$api.apiInterface(this.apiName, 'cateEdit').cateEdit(this.form, res => {
					this.loading = false
					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，设置成功',
							type: 'success'
						});
						this.addType = false
						this.getShopType()
					} else {
						this.$message.error(res.msg);
					}

				})
			},
			//删除商品分类
			delShopBtn(val) {

				this.$confirm(`确认删除${val.name}分类吗？`)
					.then(_ => {
						this.$api.apiInterface(this.apiName, 'cateDel').cateDel({
							id: val.id
						}, res => {
							if (res.code == 0) {
								this.$message.success({
									message: '恭喜你，删除成功',
									type: 'success'
								});
								this.getShopType()
							} else {
								this.$message.error(res.msg);
							}

						})
					})
					.catch(_ => {});
			}

		}
	}
</script>

<style scoped lang="less">
	/deep/.el-card__body,
	.el-main {
		padding: 0 20rem;
		max-height: 980rem;
		height: auto;
	}

	/deep/.el-dialog__body {
		padding: 30rem 20rem 0px;
		color: #606266;
		font-size: 14px;
		word-break: break-all;
	}

	.topNav {
		display: flex;
		font-size: 16rem;
		color: #409EFF;
		align-items: center;
		margin-bottom: 20rem;
		margin-left: 25rem;
		cursor: pointer;
		user-select: none;

		img {
			width: 26rem;
			height: 26rem;
			margin-right: 12rem;
		}
	}

	.tip {
		font-size: 12px;
		color: #C0C4CC;

		div {
			line-height: 0;
		}
	}
</style>
