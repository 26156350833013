<template>
	<div>
		<div class="topNav">
			<div style="width: 100%;display: flex;align-items: center;justify-content: space-between;">
				<div style="display: flex;align-items: center;" @click="addShopBtn(null)">
					<img src="../../static/addIcon.png" alt="">
					<span>添加商品</span>
				</div>
				<div>
					<el-input style="width: 250rem;" type='number' v-model="form.title" placeholder="请填写商品名称"
						size='small'></el-input>
					<el-input style="width: 250rem;margin-left: 10rem;" type='number' v-model="form.pid"
						placeholder="请填写商品分类id" size='small'></el-input>
					<el-select style="width: 170rem;margin-left: 10rem;" v-model="is_type" placeholder="筛选类型"
						size='small'>
						<el-option label="优惠卷" value="1"></el-option>
						<el-option label="实物" value="2"></el-option>
					</el-select>
					<el-select style="width: 170rem;margin-left: 10rem;" v-model="is_status" placeholder="筛选状态"
						size='small'>
						<el-option label="全部" value="-1"></el-option>
						<el-option label="下架" value="0"></el-option>
						<el-option label="上架" value="1"></el-option>
					</el-select>
					<el-button style="background-color: #FFE300 ;border:1rem solid #FFE300 ;color: black;"
						icon='el-icon-search' size='small' @click="filter"></el-button>
					<el-button style="background-color: #FFE300 ;border:1rem solid #FFE300 ;color: black;"
						icon='el-icon-refresh' size='small' @click="refresh"></el-button>
				</div>
			</div>
		</div>

		<el-card>
			<el-table :data="product" style="width: 100%" max-height='980rem'>
				<el-table-column prop="id" sortable label="id" align='center'>
				</el-table-column>
				<el-table-column prop="pic" label="商品图" align='center'>
					<template slot-scope="scope">
						<el-image style="width: 80px; height: 40px" :src="scope.row.pic" fit="scale-down"></el-image>
					</template>
				</el-table-column>
				<el-table-column prop="title" label="商品名称" align='center' width='180'>
				</el-table-column>
				<el-table-column label="商品描述" align='center'>
					<template slot-scope="scope">
						<el-popover placement="bottom" width="400rem" trigger="click">
							<div style="width: 400rem;">
								{{scope.row.content}}
							</div>
							<el-button slot="reference" style="background-color: #FFE300 ;border: none;" circle
								size='mini' icon='el-icon-document'></el-button>
						</el-popover>

					</template>
				</el-table-column>

				<el-table-column label="商品类型" align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">优惠卷</span>
						<span v-else>实物</span>
					</template>
				</el-table-column>
				<el-table-column prop="sum" label="兑换积分" align='center'>
				</el-table-column>
				<el-table-column prop="stock" label="库存" align='center'>
				</el-table-column>
				<el-table-column prop="max_buy" label="限购次数" align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.max_buy == -1">不限制</span>
						<span v-else>{{scope.row.max_buy}}</span>
					</template>
				</el-table-column>
				<el-table-column label="上架" align='center' width='100'>
					<template slot-scope="scope">
						<el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
							active-text="是" @change="changeStatus(scope.row.status,scope.row.id)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="商品分类" align='center' width='100'>
				</el-table-column>
				<el-table-column label="优惠卷" align='center' width='80'>
					<template slot-scope="scope">
						<el-button v-if="scope.row.coupon_ids_val && scope.row.coupon_ids_val.length >= 1 "
							style="background-color: #FFE300 ;border: none;" circle size='mini' icon='el-icon-s-ticket' @click="couponUseBtn(scope.row.coupon_ids_val)">
						</el-button>
						<span v-else>暂无</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align='center' fixed="right" width='100'>
					<template slot-scope="scope">

						<el-button circle type="primary" icon="el-icon-edit" size='mini' @click="addShopBtn(scope.row)"
							style="margin-right: 10rem;">
						</el-button>

						<el-popconfirm confirm-button-text='确定' cancel-button-text='不用了' icon="el-icon-info"
							icon-color="red" :title="`确定删除${scope.row.title}商品？`" @confirm='del(scope.row.id)'>
							<el-button type="danger" slot="reference" circle icon="el-icon-delete" size='mini'>
							</el-button>
						</el-popconfirm>

					</template>
				</el-table-column>
			</el-table>
			<dir style="text-align: center;">
				<el-pagination @current-change="producthandleCurrentChange" :page-size="8"
					layout="prev, pager, next,->, total" :total="+productTotal">
				</el-pagination>
			</dir>
		</el-card>


		<!-- 添加,修改商品 -->
		<el-dialog :title="title" :visible.sync="addType" width="50%">
			<el-form ref="form" :model="productInfo" label-width="120px" size='mini'>
				<ul>
					<li class="liFlex">
						<el-form-item label="商品名称:">
							<el-input class="inputW" v-model="productInfo.title" placeholder="请输入商品名"></el-input>
						</el-form-item>

					</li>

					<li>
						<el-form-item label="商品类型:">
							<el-radio-group v-model="productInfo.type">
								<el-radio label="1">优惠卷</el-radio>
								<el-radio label="2">实物</el-radio>
							</el-radio-group>
						</el-form-item>

					</li>

					<li style="display: flex;position: relative;">

						<el-form-item label="商品图:">
							<img v-if="productInfo.pic" :src="productInfo.pic" class="avatar"/>
							<i v-else class="el-icon-plus avatar-uploader-icon" @click="addGoodsImg"></i>
						</el-form-item>
						<el-button class="button_up" @click="addGoodsImg">上传商品图</el-button>
						
					</li>

					<li class="liFlex">

						<el-form-item label="兑换积分:">
							<el-input class="inputW" type='number' v-model="productInfo.sum"
								placeholder="请输入兑换商品所需要的积分"></el-input>
						</el-form-item>

					</li>

					<li class="liFlex">

						<el-form-item label="限购次数:">
							<el-input class="inputW" type='number' v-model="productInfo.max_buy" placeholder="请输入限购次数">
							</el-input>
							<div class="tip">
								提示：填写-1时，不限制次数
							</div>
						</el-form-item>
						<el-form-item label="存货:">
							<el-input class="inputW" type='number' v-model="productInfo.stock" placeholder="请输入商品存货">
							</el-input>
						</el-form-item>
						
					</li>

					<li>
						<el-form-item label="商品分类id:">

							<el-button style="background-color: #FFE300 ;border: none;color: black;margin-right: 30rem;"
								size="small" @click="changeShop"> 选择商品分类id
							</el-button>

							<el-tag size="small" v-if="productInfo.pid" closable :disable-transitions="false"
								@close="handleShopClose()">
								{{productInfo.pid}}
							</el-tag>

						</el-form-item>
					</li>

					<li>
						<el-form-item label="优惠卷:">

							<el-button style="background-color: #FFE300 ;border: none;color: black;" size="small"
								@click="changeCoupon"> 选择优惠劵
							</el-button>
							<div style="margin-top: 20rem;">
								<el-tag style="margin-right: 10rem;" size="small" v-for="(tag,index) in couponName" :key="index" closable
									:disable-transitions="false" @close="handleCoupponClose(index)">
									{{tag}}
								</el-tag>
							</div>
						</el-form-item>
					</li>

					<li>
						<el-form-item label="状态:">
							<el-switch style="display: block" v-model="productInfo.status" active-color="#FFE300"
								size='mini' active-text="上架" inactive-text="下架">
							</el-switch>
						</el-form-item>
					</li>

					<li>
						<el-form-item label="商品描述:">
							<el-input :rows=5 type="textarea" v-model="productInfo.content"></el-input>
						</el-form-item>
					</li>

				</ul>

			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addType = false" size='small'>取 消</el-button>
				<el-button type="primary" :loading="loading" @click="submit" size='small'>确 定</el-button>
			</span>
		</el-dialog>

		<!-- 商品图 -->
		<ImgLibrary ref="imgLibrary" :toImage="changeImg"></ImgLibrary>

		<!-- 优惠劵 -->
		<el-dialog title="选择优惠劵" :visible.sync="couponStatus" width="70%">
			<el-table v-loading="load" ref="multipleTable" :data="couponList" tooltip-effect="dark" style="width: 100%"
				size='mini' max-height="400" stripe @selection-change="handleSelectionCoupon">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="id" label="id" width='50' align='center'>
				</el-table-column>
				<el-table-column prop="name" label="优惠卷名称" align='center' width="130">
				</el-table-column>
				<el-table-column prop="type" sortable label="类型" align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">立减</span>
						<span v-else>折扣</span>
					</template>
				</el-table-column>
				<el-table-column label="优惠" align='center' width='100'>
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">{{scope.row.discount_money}}元</span>
						<span v-else>{{scope.row.discount_money}}折</span>
					</template>
				</el-table-column>
			</el-table>
			<dir style="text-align: center;">
				<el-pagination @current-change="couponhandleCurrentChange" :page-size="10"
					layout="prev, pager, next,->, total" :total="+couponTotal">
				</el-pagination>
			</dir>
			<span slot="footer" class="dialog-footer">
				<el-button @click="couponStatus = false" size='small'>取 消</el-button>
				<el-button type="primary" :loading="loading" @click="couponStatus = false" size='small'>确 定</el-button>
			</span>
		</el-dialog>

		<!-- 商品分类 -->
		<el-dialog title="商品分类" :visible.sync="shopStatus" width="40%">
			<el-table v-loading="load" ref="multipleTable" :data="shopTypeList" tooltip-effect="dark"
				style="width: 100%" size='mini' max-height="400" stripe @selection-change="handleSelectionShopStatus">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="id" sortable label="id" width="180" align='center'>
				</el-table-column>
				<el-table-column prop="name" label="商品分类" width="180" align='center'>
				</el-table-column>
			</el-table>
			</span>
		</el-dialog>

		<!-- 商品已使用优惠劵 -->
		<el-dialog title="优惠劵" :visible.sync="coupontype" width="40%">
			<el-table :data="couponShopList" size='mini' height="500rem">
				<el-table-column prop="id" label="id" align='center'>
				</el-table-column>
				<el-table-column prop="name" label="优惠劵名称" align='center'>
				</el-table-column>
				<el-table-column prop="type" label="类型" align='center'>
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">立减</span>
						<span v-if="scope.row.type == 2">折扣</span>
					</template>
				</el-table-column>
				<el-table-column   label="优惠" align='center' width='100'>
					<template slot-scope="scope" >
						<span v-if="scope.row.type == 1">{{scope.row.discount_money}}元</span>
						<span v-else>{{scope.row.discount_money}}折</span>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
	import ImgLibrary from '../../components/qrun/imgLibrary.vue'
	
	export default {
		name: "index_shopProduct",
		menuName: "商品管理",
		data() {
			return {
				apiName: 'Shop',

				upImg: false,

				title: '添加商品',
				loading: false,

				is_status: '',
				is_type: '',

				form: {
					page: 1,
					limit: 8,
					pid: '', //分类id
					title: '', //标题
					type: '', //类型
					status: -1, //-1全部 上架状态
				},
				product: [],
				productTotal: '',
				//添加，修改商品
				addType: false,
				productInfo: {
					pic:""
				},
				//优惠劵
				couponpage: 1,
				couponStatus: false,
				couponList: [],
				couponTotal: [],
				couponID: [],
				couponName:[],
				load: false,

				shopTypeList: [],
				shopStatus: false,

				//商品的优惠劵
				coupontype: false,
				couponShopList:[]
			}
		},
		inject: ["layout"],
		components: {
			ImgLibrary
		},
		created() {
			this.getProduct()

		},
		methods: {
			//添加商品图片
            addGoodsImg(){
				this.$refs.imgLibrary.show();
			},
			changeImg(val){
				
				this.$set(this.productInfo,'pic',val);
				
				this.$refs.imgLibrary.hide();
			},
			//获取商品分类
			getShopType() {
				this.load = true
				this.$api.apiInterface(this.apiName, 'getCate').getCate({}, res => {
					this.load = false
					if (res.code == 0) {
						this.shopTypeList = res.data.list
						this.shopTypeList.forEach(item => {
							item.update_time = this.$func.formatTime(item.update_time)
							return item
						})
					} else {
						this.$message.error(res.msg);
					}

				})
			},
			getProduct() {
				this.layout.loading = true
				this.$api.apiInterface(this.apiName, 'getGoods').getGoods(this.form, res => {
					this.layout.loading = false
					if (res.code == 0) {

						this.product = res.data.list

						this.product.forEach(item => {
							item.create_time = this.$func.formatTime(item.create_time)
							item.coupon_ids = JSON.parse(item.coupon_ids)
							if (item.status == 1) {
								item.status = true
							} else {
								item.status = false
							}
							return item
						})

						this.productTotal = res.data.count

					} else {
						this.$message.error(res.msg);
					}

				})
			},
			//分页
			producthandleCurrentChange(val) {
				this.form.page = val
				this.getProduct()
			},
			//筛选
			filter() {
				if (this.is_status) {
					this.form.status = this.is_status
				} else {
					this.form.status = -1
				}

				this.form.type = this.is_type
				this.getProduct()
			},
			//刷新
			refresh() {
				this.is_status = ''
				this.is_type = ''
				this.form.status = -1
				this.form.title = ''
				this.form.pid = ''
				this.form.page = 1
				this.form.type = ''
				this.getProduct()

			},
			//修改上架状态
			changeStatus(val, id) {

				if (val) {
					val = 1
				} else {
					val = 0
				}

				this.$api.apiInterface(this.apiName, 'ChangeGoods').ChangeGoods({
					id,
					status: val
				}, res => {
					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，修改成功',
							type: 'success'
						});

						this.getProduct()

					} else {
						this.$message.error(res.msg);
					}

				})
			},

			//添加，修改商品
			addShopBtn(val) {
				this.addType = true

				if (val) {
					this.productInfo = JSON.parse(JSON.stringify(val))
					
					this.productInfo.coupon_ids_val.forEach(item => {
						this.couponID.push(item.id)
						this.couponName.push(item.name)
					})
					this.couponID = Array.from(new Set(this.couponID))
					this.couponName = Array.from(new Set(this.couponName))
					this.title = '修改商品'
				} else {
					this.couponID = []
					this.couponName = []
					this.productInfo = {}
					this.title = '添加商品'

				}
			},
			//submit
			submit() {
				
				delete	this.productInfo.coupon_ids_val
				
				this.productInfo.coupon_ids = JSON.stringify(this.couponID)

				if (this.productInfo.status) {
					this.productInfo.status = 1
				} else {
					this.productInfo.status = 0
				}

				this.loading = true
				this.$api.apiInterface(this.apiName, 'goodsEdit').goodsEdit(this.productInfo, res => {
					this.loading = false
					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，操作成功',
							type: 'success'
						});
						this.addType = false
						this.getProduct()
					} else {
						this.$message.error(res.msg);
					}

				})
			},
			//删除商品
			del(val) {
				this.$api.apiInterface(this.apiName, 'goodsDel').goodsDel({
					id: val
				}, res => {

					if (res.code == 0) {
						this.$message.success({
							message: '恭喜你，删除成功',
							type: 'success'
						});
						this.getProduct()

					} else {
						this.$message.error(res.msg);
					}

				})
			},
			//选择优惠劵操作
			handleSelectionCoupon(val) {
				val.forEach(item => {
					this.couponID.push(item.id)
					this.couponName.push(item.name)
				})

				this.couponID = Array.from(new Set(this.couponID))
				this.couponName = Array.from(new Set(this.couponName))
			},

			//选择商品分类
			handleSelectionShopStatus(val) {
				val.forEach(item => {

					this.productInfo.pid = item.id
				})

				if (this.productInfo.pid) {
					this.shopStatus = false
				}

			},
			handleShopClose() {
				this.productInfo.pid = ''
			},

			changeShop() {
				this.shopStatus = true
				this.getShopType()
			},

			changeCoupon() {
				this.couponStatus = true
				this.load = true
				this.$api.getList('coupon', {
					page: this.couponpage,
					limit: 10
				}, (res) => {
					this.load = false
					if (res.code == 0) {

						this.couponList = res.data.list
						this.couponTotal = res.data.count

					} else {
						this.$message.error(res.msg);
					}

				})
			},
			handleCoupponClose(tag) {
				this.couponName.splice(tag, 1);
				this.couponID.splice(tag, 1);
			},
			couponhandleCurrentChange(val) {
				this.couponpage = val
				this.changeCoupon()
			},
			//查看商品的优惠劵
			couponUseBtn(val){
				this.coupontype = true
				this.couponShopList = val
			},
		}
	}
</script>

<style scoped lang="less">
	/deep/.el-card__body,
	.el-main {
		padding: 0 20rem;
		max-height: 980rem;
		height: auto;
	}

	/deep/.el-dialog__body {
		padding: 30rem 20rem 0px;
		color: #606266;
		font-size: 14px;
		word-break: break-all;
	}

	.topNav {
		display: flex;
		font-size: 16rem;
		color: #409EFF;
		align-items: center;
		margin-bottom: 20rem;
		margin-left: 25rem;
		cursor: pointer;
		user-select: none;

		img {
			width: 26rem;
			height: 26rem;
			margin-right: 12rem;
		}
	}

	.tip {
		font-size: 12px;
		color: #C0C4CC;

		div {
			line-height: 0;
		}
	}

	.inputW {
		width: 250rem;
	}

	.button_up {
		height: 50rem;
		line-height: 15rem !important;
		background-color: #FFE300;
		border: #FFE300;
		color: #000;
		letter-spacing: 2px;
		position: absolute;
		bottom: 45rem;
		left: 420rem;
	}

	.avatar-uploader-icon {
		font-size: 28rem;
		color: #8c939d;
		width: 200rem;
		height: 178rem;
		line-height: 178rem;
		text-align: center;
		border: 1rem dashed #d9d9d9;
		border-radius: 6rem;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar {
		width: 200rem;
		height: 178rem;
		display: block;
	}

	.liFlex {
		display: flex;

		justify-content: space-between;
	}

	.tip {
		font-size: 12px;
		color: #C0C4CC;

		div {
			line-height: 0;
		}
	}
</style>
