/**
 * 内置USE集合
 */
import Router from 'vue-router'
import less from 'less'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';

import $Diy_use from "@/common/use";
export default function run(Vue){
    Vue.use(Router)
    Vue.use(less)
    Vue.use(ElementUI)
    return $Diy_use(Vue);
}

