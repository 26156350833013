/**
 * 框架依赖加载库
 */
import Vue from 'vue'
/**
 * 内置
 */

import $uses from './use.js'
import $prototype from './prototype.js'
//路由启动
import router from '../router/main'

import store from "@/store/index.js"

import func from "@/common/func.js"

Vue.prototype.$func = func;

import config from '@/config/config.js'
Vue.prototype.$config = config;

import qyPub from "@/common/common.js"

qyPub.initRem();
qyPub.init();

import VueQrcodeReader from "vue-qrcode-reader";

Vue.use(VueQrcodeReader);

Vue.prototype.$imgIn = (url) => {
  
  if(url == "" || url == null){
	  return;
  }
	
  if (url.indexOf('https://') != -1 || url.indexOf('http://') != -1) {
    return url;
  } else {

    return config.imageURL + url;
  }
}

//挂载导入
const load = () => {
	//use 集合
	$uses(Vue);
	//prototype 集合
	$prototype(Vue);
	return Vue
}

Vue.config.productionTip = false
export default {
	load,
	router,
	store
}
