<template>
	<div>
		<el-card style="padding-bottom: 20rem;">
			<el-button type="primary" size="mini" style="margin-bottom: 20px;" @click="addAdmin">{{'添加'}}
			</el-button>
			<el-table v-loading="loading" border :data="list" style="width: 100%">
				<el-table-column prop="id" label="ID" width="80" align="center">
				</el-table-column>
				<el-table-column prop="username" :label="'账号'" width="120" align="center">
				</el-table-column>
				<!-- <el-table-column prop="roleName"
                         :label="$L('角色')"
                         label=""
                         width="150"
                         align="center"> -->
				</el-table-column>
				<el-table-column :label="'创建时间'" width="240" align="center">
					<template slot-scope="scope">
					    <span>{{scope.row.create_time | formatTime}}</span>
					</template>
				</el-table-column>
				<el-table-column :label="'更新时间'" width="240" align="center">
					<template slot-scope="scope">
					    <span>{{scope.row.update_time | formatTime}}</span>
					</template>
				</el-table-column>
				<el-table-column :label="'状态'" width="80" align="center">
					<template slot-scope="scope">
						{{scope.row.status ? "是" :"否" }}
					</template>
				</el-table-column>
				<el-table-column :label="'操作'" align="center">
					<template slot-scope="scope" size="mini">
						<el-button size="mini" type="primary" icon="el-icon-edit" @click="handleEdit(scope.row)">
							{{'编辑'}}
						</el-button>
						<el-button size="mini" icon="el-icon-delete" type="danger" @click="handleDelete(scope.row)">
							{{'删除'}}
						</el-button>
					</template>
				</el-table-column>
			</el-table>

			<!-- <el-pagination style="text-align: center;" :current-page="page" :total="count" :page-size="limit"
				:pager-count="5" :page-sizes="[3, 5,10, 20, 30,]" layout="prev, pager, next, jumper, ->, sizes, total"
				@current-change="handlePageChange" @size-change="handleSizeChange" /> -->

		</el-card>

		<!-- 添加编辑管理员 -->
		<el-dialog :title="form.id? '编辑管理员':'添加管理员'" :visible.sync="dialogVisible" width="40%"
			:before-close="handleClose">
			<el-form :model="form" label-width="150px" size="mini" :rules="rules" ref="ruleForm">
				<el-form-item :label="'账号'+':'">
					<el-col :span="8">
						<el-input v-model="form.username" :disabled='form.id? true : false'></el-input>
					</el-col>
				</el-form-item>
				<el-form-item :label="'密码'+':'" prop="password">
					<el-col :span="8">
						<el-input v-model="form.password" type="password"></el-input>
					</el-col>
				</el-form-item>
				<el-form-item :label="'确认密码'+':'" prop="repassword">
					<el-col :span="8">
						<el-input v-model="form.repassword" type="password"></el-input>
					</el-col>
				</el-form-item>
				<el-form-item :label="'角色'+':'">
					<el-select v-model="form.group_id" :placeholder="'请选择角色'">
						<el-option v-for="(item,index) in roleList" :key="index" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="'状态'+':'">
					<el-switch v-model="form.status"></el-switch>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button size="mini" @click="cancelSubmit">{{'取 消'}}</el-button>
				<el-button size="mini" type="primary" @click="submit" :loading="loading">{{'保 存'}}</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	
	export default {
		name: "index_personSet",
		menuName:"员工管理",
		default: 'user',
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else {
					if (this.form.password !== '') {
						this.$refs.ruleForm.validateField('repassword');
					}
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.form.password) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			}
			return {
				list: [],
				page: 1,
				limit: 10,
				count: 1,
				dialogVisible: false,
				loading: false,
				form: {
					username: '',
					password: '',
					repassword: '',
					group_id: '',
					status: false
				},
				rules: {
					password: [{
						validator: validatePass,
						trigger: 'blur'
					}],
					repassword: [{
						validator: validatePass2,
						trigger: 'blur'
					}]
				},
				roleList: []
			}
		},
		filters:{
			formatTime(val){
				var date = new Date(val*1000);
				var year = date.getFullYear();
				var month = date.getMonth()+1;
				var day =date.getDate();
				return year + '-' + month + '-' + day;
			}
		},
		created() {
	
			//获取角色列表
			this.getRoleList(() => {
				this.getAdministratorList();
			});
	
		},
		methods: {
			getRoleList(callback) {
				this.loading = true
				this.$api.getRoleSet({}, res => {
					if (res.code == 0) {
						this.roleList = res.data.list;
						// console.log(this.roleList);
						this.loading = false
						if (typeof callback == "function") {
							callback();
						}
					}
	
				})
	
			},
			dealSetStatus(str) {},
			//获取管理员列表
			getAdministratorList() {
	
				this.$api.getAdminList({
					page: this.page,
					limit: this.limit
				}, res => {
					// console.log("------", res);
					if (res.code == 0) {
						var list = res.data.list;
	
						this.list = list.map(item => {
	
							this.roleList.forEach(childItem => {
	
								if (item.group_id == childItem.id) {
									item.roleName = childItem.name;
								}
	
							})
	
							return item;
	
						})
	
					}
	
				})
	
			},
			//添加管理员
			addAdmin() {
				this.dialogVisible = true;
			},
			//编辑按钮
			handleEdit(row) {
				console.log(row);
	
				this.dialogVisible = true;
				this.form = row;
				this.form.status = row.status == 1 ? true : false;
			},
			//删除按钮
			handleDelete(row) {
				this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确 定',
					cancelButtonText: '取 消',
					type: 'warning'
				}).then(() => {
					//需要调用接口
					this.$api.delAdminUser({
						id: row.id
					}, (res) => {
						console.log(res);
						if (res && res.code === 0) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
	                        this.getAdministratorList();
							this.getRoleList();
						}
					})
	
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			//保存按钮
			async submit() {
	
				this.loading = true;
	
				this.$api.setAdminList({
					username: this.form.username,
					password: this.form.password,
					group_id: this.form.group_id,
					status: this.form.status ? 1 : 0,
					id: this.form.id || ""
				}, res => {
	
					if (res.code == 0) {
	
						this.dialogVisible = false;
	                     
						this.$message({
							showClose: true,
							message: '操作成功',
							type: 'success'
						});
						
						this.getAdministratorList();
	
					}
	
					this.loading = false;
	
				})
	
			},
			//取消按钮
			cancelSubmit() {
				this.dialogVisible = false
				this.form = {}
			},
			handleClose(done) {
				this.form = {}
				done();
			},
			//分页操作
			handleSizeChange(pageSize) {
				this.limit = pageSize;
				this.getAdministratorList(this.page, this.limit)
			},
			handlePageChange(page) {
				this.page = page;
				this.getAdministratorList(this.page, this.limit)
			}
		}
	}
	
	
</script>

<style lang="less" scoped>
	.layui-btn {
		display: inline-block;
		height: 38px;
		line-height: 38px;
		padding: 0 18px;
		margin-bottom: 10px;
	}
</style>
