<template>
  <div id="app">
    	<layout v-if="token"></layout>
    	<login v-else></login>
  </div>
</template>

<script>
import login from '@/views/login/index.vue'
import layout from '@/views/layout.vue'

import {mapState,mapMutations} from 'vuex'

export default {
  components:{
  	login,	
  	layout
  },
  data() {
  	return {
  
  	}
  },
  name: 'app',
  computed:{
  	...mapState({
  		token:(state) => state.token.token
  	})
  },
  mounted() {
  	
  },
  methods:{
  	...mapMutations(["setToken"]),
  },
  created() {
  	
  	if(localStorage.getItem("token")){
  		let token = localStorage.getItem("token");
  		this.setToken(token);
  	}
	
  }
}
</script>

<style lang="less">
   /*统一公用样式*/
   @import url("./common/common.css");
   #app {
     background: #F5F6F9;
     height: 100vh;
   }
   
   
   
</style>
