<template>
	<div style="">
		<el-pagination style="text-align: center;margin-top: 20px;" :current-page="page" :total="count"
			:page-size="limit" :pager-count="5" :page-sizes="[3, 5,10, 20, 30,]"
			:layout="layout" @current-change="handlePageChange"
			@size-change="handleSizeChange" />
	</div>
</template>

<script>
	export default{
		data() {
			return{
				
			}
		},
		props:{
			limit:[Number,String],
			page:[Number,String],
			count:[Number,String],
			callback:Function,
			pageType:String
		},
		computed:{
			layout(){
				if(this.limit > 10){
					return `prev, pager, next, jumper, ->,sizes, total`;
				}else{
					return `prev, pager, next, jumper, ->, total`;
				}
			},
			pagesSize() {
				var pagesArr = [3, 5, 10, 20, 30]
				pagesArr.forEach((item, index) => {
					if (item == this.limit) {
						pagesArr.splice(index, 1, item)
					}
				})
				return pagesArr
			}
		},
		methods:{
			handlePageChange(e) {
				this.callback("handlePage",e,this.pageType);
			},
			handleSizeChange(e) {
				this.callback("handleSize",e,this.pageType);
			}
		}
	}
</script>

<style>
</style>
