<template>
	<div>
<!-- :style="'height:'+navH+'px;'" -->
		<div class="topNav" >

			<div class="navList" v-if="navOptions && navOptions.length>0">

				<div class="navItem" v-for="(item,index) in optionsList" :key="item.key" style="margin-bottom: 0px;">
					
						<label class="mr" v-if="item.type=='txt'">
							<span class="inputTil">{{item.lable}}:</span>
							<el-input style="width: 100%;" size="small" v-model="item.value"
								:placeholder="`请输入${item.lable}`"></el-input>
						</label>
						
						<label class="mr" v-else-if="item.type == 'status'">
							<span class="inputTil">{{item.lable}}:</span>
							<el-select style="width: 100%;" size="small" v-model="item.value" placeholder="请选择">
								<el-option v-for="(opItem,opIndex) in item.options" :key="opItem.key" :label="opItem.name"
									:value="opItem.key">
								</el-option>
							</el-select>
						</label>
                        
						
						<template v-if="index == optionsList.length-1">
							<el-button type="primary" size="small" icon="el-icon-search" @click="navHandle('search')" >搜索
							</el-button>
							<el-button type="primary" size="small" icon="el-icon-refresh-right" @click="navHandle('reset')">重置
							</el-button>
							<!-- <span v-if="navOptions.length>=3" class="el-icon-arrow-down openNav" style="cursor: pointer;"
								@click="openNav">{{openTxt}}</span> -->
						</template>
				</div>
				
				

			</div>

		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				navH: 35,
				openTxt: "展开",
				navListH: 0,
				isOpen: false,
				areaY:0,
				resetData:{},
				optionsList:[{}]
			}
		},
		props:{
			navOptions:Array,
			navCallBack:Function
		},
		created() {

		},
		computed:{
			controlStyle(){
				return this.openIndex == '' ? "position: absolute;right: 0;bottom:0;" : "display: flex;justify-content: flex-end;align-items: center;"
			}
		},
		watch:{
			navOptions:{
				handler(newVal,oldVal){
					// console.log("newVal",newVal);
					this.initDom();
				},
				deep:true
			}
		},
		mounted() {
			
			this.optionsList = [...this.navOptions,...this.optionsList];
			
			console.log("this.optionsList",this.optionsList);
			
			this.navOptions.forEach(item=>{
				this.resetData[item.key] = item.value;
			})
			
			this.initDom();
		},
		methods: {
			initDom(){

			},
			async openNav() {
				
				this.isOpen = !this.isOpen;

				this.navH = this.isOpen ? this.navListH : 35;

				this.openTxt = this.isOpen ? '收起' : '展开';
				
				if(this.isOpen){
					this.navOptions.push({type:'control'});
				}else{
					this.navOptions.splice(this.navOptions.length-1,1);
				}
				
				this.openIndex = this.isOpen ? this.navOptions.length-1 : 2;
				
				// this.areaY = this.isOpen ? 90 : 0;

			},
			navHandle(type) {
				
				if (type == 'reset') {
					this.navOptions.forEach(item => {
						item.value = this.resetData[item.key];
					})
				}

				this.navCallBack(this.navOptions);
			}
		}
	}
</script>

<style scoped lang="less">
	.topNav{
		// overflow: hidden;
		transition: all .3s;
	}
	
	.navList {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 10px;
		// grid-template-columns: 1fr 1fr 1fr;
		position: relative;
		
		.navItem{
			width: 400rem;
			margin-right: 40px;
		}

		label {
			display: flex;
			align-items: center;
			width: 100%;
			margin-bottom: 10px;
		}
	}

	.mr {
		margin-right: 20px;
	}

	.inputTil {
		display: block;
		// min-width: 32px;
		// max-width: 80px;
		width: auto;
		white-space: nowrap;
		text-align: right;
		color: #333;
		font-size: 14px;
		margin-right: 10px;
	}

	.controlArea {
		width: 300rem;
		// position: absolute;
		// right: 0;
		// top:0;
		margin-left: 900rem;
	}

	.openNav {
		font-size: 14px;
		color: #1890ff;
		margin-left: 10px;
		user-select: none;
	}
</style>
