<template>
	<div style="height: 100%;display: flex;flex-direction: column;">
		<div class="topNav">
			<div style="display: flex;align-items: center;" @click="listHandle('add')">
				<img class="topImg" src="../../static/addIcon.png" alt="">
				<span>添加会员卡</span>
			</div>
		</div>
		<el-card style="flex: 1; display: flex;flex-direction: column;height: 100%;">
			<div class="status">
				<span class="icon"></span>
				<span style="font-size: 18rem;color: #666666;">会员卡</span>
			</div>
			<!-- 列表 -->
			<div class="formList" style="height: 94%;overflow-y: auto;">
				<!-- 筛选区域 -->
				<div style="margin: 30rem 0;">
					<!-- <topNav :navOptions.sync="screenOptions" :navCallBack="navCallBack"></topNav> -->
				</div>

				<!-- 列表展示 -->
				<template v-if="isEdit">
					<baseList :callBack="callBack" :listData="listData.list" :colList="colList"></baseList>
					<pagination :limit="queryData.limit" :page="queryData.page" :count="listData.count"></pagination>
				</template>

				<!-- 表单 -->
				<template v-else>
					<div style="">
						<formPage :formCallBack='formCallBack' :formData="formData"></formPage>
					</div>

				</template>

			</div>

		</el-card>
	</div>
</template>

<script>
	import baseList from "@/components/baseList/index.vue"
	import pagination from "@/components/pagination/index.vue"
	import topNav from '@/components/topNav/index.vue'
	import formPage from '@/views/formPage/index.vue'

	export default {
		name: "index_card",
		menuName: "会员卡列表",
		data() {
			return {
				//----接口名称----
				apiName: "Card",
				//表头数据
				colList: [{
						lable: 'ID',
						key: 'id',
						type: 'text',
						width: 80
					},
					{
						lable: '商户id',
						key: 'store_id',
						type: 'text',
					},
					{
						lable: '会员卡名称',
						key: 'card_name',
						type: 'text',
						width: 120
					},
					{
						lable: '开卡费',
						key: 'create_price',
						type: 'text',
					},
					{
						lable: '开卡最低充值价格',
						key: 'create_min_price',
						type: 'text',
						width: 140
					},
					{
						lable: '最低续充价格',
						key: 'min_renew_price',
						type: 'text',
						width: 120
					},
					{
						lable: '1～10折扣比例',
						key: 'scale',
						type: 'text',
						width: 120,
						isShow: false
					},
					{
						lable: '类型',
						key: 'type',
						type: 'status',
						width: 130,
						options: [{
								title: "单场单人核销",
								key: '1'
							},
							{
								title: "单场多人核销",
								key: '2'
							}
						]
					},
					{
						lable: '创建时间',
						key: 'create_time',
						type: 'time',
						width: 120
					},
					{
						lable: '状态',
						key: 'status',
						type: 'status',
						options: [{
								title: "正常",
								key: '1'
							},
							{
								title: "下架",
								key: '0'
							}
						]
					},
					{
						lable: '修仙费使用状态',
						key: 'xiuxian_status',
						type: 'status',
						width: 140,
						options: [{
								title: "支持",
								key: '1'
							},
							{
								title: "不支持",
								key: '0'
							}
						]
					},
					{
						lable: '其他费用使用状态',
						key: 'othercost_status',
						type: 'status',
						width: 140,
						options: [{
								title: "支持",
								key: '1'
							},
							{
								title: "不支持",
								key: '0'
							}
						]
					},
					{
						lable: '操作',
						key: 'action',
						type: 'buttons',
						fixed: 'right',
						isShow: true,
						width: 170,
						options: [{
							icon: "el-icon-edit",
							title: "编辑",
							clickType: 'edit'
						}, {
							color: "red",
							icon: "el-icon-delete",
							title: "删除",
							clickType: 'del'
						}]
					}
				],
				//操作接口参数
				screenOptions: [],
				//后台接口数据
				listData: {
					count: 0,
					list: []
				},
				//表单参数
				formData: [],
				queryData: {
					page: 1,
					limit: 7
				},
				isEdit: true
			}
		},
		components: {
			baseList,
			pagination,
			topNav,
			formPage
		},
		created() {
			this.initData();
		},
		inject: ["layout"],
		methods: {
			//去重
			unique(arr) {
				const res = new Map()
				return arr.filter((a) => !res.has(a.key) && res.set(a.key, 1))
			},
			//生成表单参数
			getFormData(formVal) {
                
				this.formData = [{
						label: "赠送规则JSON",
						key: "send_rule",
						type: "rules",
						value: formVal['send_rule'] || []
					},
					{
						label: "是否开启自定义充值",
						key: "is_custom",
						type: "radio",
						value: formVal['is_custom'] || "1",
						options: [{
								title: "开启",
								key: '1'
							},
							{
								title: "关闭",
								key: '0'
							}
						]
					}
				]
				
				var formData = this.formData;

				for (var key in formVal) {

					var obj = {}

					this.colList.forEach((item, index) => {

						if (item.key == key) {

							obj = {
								label: item.lable,
								key,
								type: item.type,
								value: formVal[key] || item.value,
								options: item.options ? item.options : "",
								disabled: item.disabled ? item.disabled : false
							}

							this.$set(formData, formData.length, obj);

						}

					})

				}
				
				console.log("this.formData",this.formData);

				this.formData = this.unique(formData);

			},
			initData() {

				this.$api.getList(this.apiName, this.queryData, res => {
					console.log("------", res);
					if (res.code == 0) {
						this.layout.loading = false;
						this.listData.list = res.data.list;
						this.listData.count = Number(res.data.count);
					}
				})

			},
			listHandle(type, val) {

				switch (type) {
					case "add":
						this.isEdit = false;

						var queryData = {
							card_name: "",
							create_price: "",
							create_min_price: "",
						    min_renew_price: "",
							status: "1",
							scale: "",
							type: "1",
							xiuxian_status:"1",
							othercost_status:"1"
						}

						this.getFormData(queryData);
						break;
					case "edit":
					    
						console.log("val", val);

						var queryData = {
							id:val.id,
							card_name: val.card_name,
							create_price: val.create_price,
							create_min_price: val.create_min_price,
							min_renew_price: val.min_renew_price,
							status: val.status,
							scale: val.scale,
							type: val.type,
							send_rule:val.send_rule,
							is_custom:val.is_custom,
							xiuxian_status:val.xiuxian_status,
							othercost_status:val.othercost_status
						}
						
						this.getFormData(queryData);
						
						this.isEdit = false;

						break;
					case "del":
					    
						this.listDel(val);
					
					    break;
				}

			},
			//列表回调
			callBack(type, val) {
				this.listHandle(type, val);
			},
			//列表删除
			listDel(val){
				console.log("----",val);
				
				this.$confirm(`是否确认要删除该数据？`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api.listDel(this.apiName,{id:val.id},res=>{
						if (res.code == 0) {
							this.$message({
								type: 'success',
								message: '操作成功'
							});
							this.initData();
						
							return;
						}
						this.$message({
							type: 'warning',
							message: res.msg
						});
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
				
				
			},
			//列表编辑
			listEdit(queryData) {

				this.$api.listEdit(this.apiName, queryData, res => {
					console.log("res", res);
					if (res.code == 0) {
						this.$message({
							type: 'success',
							message: '操作成功'
						});
						this.initData();

						return;
					}
					this.$message({
						type: 'warning',
						message: res.msg
					});
				})

			},
			//表单回调
			formCallBack(type, formVal) {

				if (type == 'cancel') {
					this.isEdit = true;
				} else {
					this.isEdit = true;

					var formData = {};

					formVal.forEach(item => {

						if (item.type == "rules") {
							formData[item.key] = JSON.stringify(item.value);
						} else {
							formData[item.key] = item.value;
						}

					})

					this.listEdit(formData);

				}

			},
			//筛选区域回调
			navCallBack() {

			}
		}
	}
</script>

<style scoped lang="less">
	// .formList::-webkit-scrollbar {
	// 	display: none;
	// }

	/deep/ .is-leaf {
		background: rgba(141, 212, 97, .35) !important;
	}

	.topNav {
		display: flex;
		font-size: 16rem;
		color: #409EFF;
		align-items: center;
		margin-bottom: 20rem;
		margin-left: 25rem;
		cursor: pointer;
		user-select: none;

		img {
			width: 26rem;
			height: 26rem;
			margin-right: 12rem;
		}
	}

	.status {
		margin-bottom: 15rem;

		.icon {
			display: inline-block;
			width: 13rem;
			height: 13rem;
			background: #8DD461;
			border-radius: 50%;
			margin-right: 10rem;
		}
	}
</style>
